import React, { useState, useEffect } from 'react';
import { Grid, Box, Typography } from '@mui/material';
import PageContainer from 'src/components/container/PageContainer';
import usercheck from 'src/services/usercheck';
import { UserProvider } from '../../contexts/Contexts'; 
import BookingChat from './components/BookingChat';
import BookingRequestDetails from './components/BookingRequestDetails';
import BookingOfferAndDeals from './components/BookingOfferAndDeals';
import {getBookingById} from '../../services/message.service'
import { useParams } from 'react-router-dom';

const FreelancerBooking = () => {
  const { booking_request_id } = useParams(); 
  const [freelancerId, setFreelancerId] = React.useState(null);


  useEffect(() => {
    const fetchData = async () => {
      const bookingData = await getBookingById(booking_request_id);
      setFreelancerId(bookingData.data.freelancer_id);
    }

    fetchData();
  }, [booking_request_id]);

  
  return (
    <PageContainer title="Freelancer Booking requests" description="Manage your bookings">
      <Box>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={7}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <BookingRequestDetails />
              </Grid>
              <Grid item xs={12}>
                <BookingOfferAndDeals offerType={'booking'} id={booking_request_id}/>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={5}>
            <BookingChat chatType={'booking'} booking_request_id={booking_request_id} freelancer_id={freelancerId}/>
          </Grid>
        </Grid>
      </Box>
    </PageContainer>
  );
};

export default usercheck(FreelancerBooking);
