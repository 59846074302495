import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Grid, Typography, Button, Chip, Divider, Stack, Modal, Box } from '@mui/material';
import { getFreelancerById, getFreelancerSkills, getFreelancerExperiences, getFreelancerServicesByFreelancerId, getFreelancerEducations, getFreelancerLanguages } from "../../../services/message.service";
import DashboardCard from '../../../components/shared/DashboardCard';
import BookingRequest from './BookingRequest';
import PricingComponent from './PricingComponent';
import PageContainer from 'src/components/container/PageContainer';
import { format, parseISO, intervalToDuration } from 'date-fns';


const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};


const FreelancerProfile = () => {
  const { freelancer_id } = useParams();
  const navigate = useNavigate();
  const [profileData, setProfileData] = useState(null);
  const [services, setServices] = useState(null);
  const [experiences, setExperiences] = useState(null);
  const [educations, setEducations] = useState(null);
  const [languages, setLanguages] = useState(null);
  const [skills, setSkills] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [preSelectedServiceId, setPreSelectedServiceId] = useState(null);

  useEffect(() => {
    getFreelancerById(freelancer_id).then(data => {
      setProfileData(data.data);
    });
    getFreelancerSkills(freelancer_id).then(data => {
      setSkills(data.data);
    });
    getFreelancerServicesByFreelancerId(freelancer_id).then(data => {
      setServices(data.data);
    });
    getFreelancerExperiences(freelancer_id).then(data => {
      setExperiences(data.data);
    });
    getFreelancerEducations(freelancer_id).then(data => {
      setEducations(data.data);
    });
    getFreelancerLanguages(freelancer_id).then(data => {
      setLanguages(data.data);
    });

    
  }, [freelancer_id]);

  const formatDate = (date) => {
    return date ? format(parseISO(date), 'MMM yyyy') : 'Present';
  };
  
  const calculateDuration = (startDate, endDate) => {
    const start = parseISO(startDate);
    const end = endDate ? parseISO(endDate) : new Date(); // Use current date if endDate is null
    const duration = intervalToDuration({ start, end });
    return `${duration.years} yrs ${duration.months} mos`;
  };
  
  function formatTextWithLineBreaks(text) {
    return text.split('\n').map((line, index) => (
      <React.Fragment key={index}>
        {line}
        {index !== text.split('\n').length - 1 && <br />}
      </React.Fragment>
    ));
  }

  const handleOpenModal = (serviceId) => {
    setPreSelectedServiceId(serviceId);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  if (!profileData) {
    return (
      <Grid item xs={12}>
          <Typography variant="body1">Loading...</Typography>
      </Grid>
    );
  }

  const nameAndTitle = profileData.firstname + ": " + profileData.title_or_role;
  

  return (
    <>
    <PageContainer title={nameAndTitle} description={profileData.about_text} image={profileData.profile_image} >
      <Typography variant='h1' marginBottom={2}>
          {profileData.firstname}: {profileData.title_or_role}
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <DashboardCard>
            <img src={profileData.profile_image} alt={`${profileData.firstname}'s profile`} style={{ width: '100%' }} />
          </DashboardCard>
            <BookingRequest freelancer_id={freelancer_id}/>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Grid container spacing={1} marginTop={1}>
            <Grid item xs={12}>
              <DashboardCard title="About me">
                <Typography variant="body1" marginBottom={3}> 
                  {formatTextWithLineBreaks(profileData.about_text)}
                </Typography>
              </DashboardCard>
            </Grid>
          </Grid>
          {services && services.length > 0 && (
          <Grid container spacing={1} marginTop={1}>
            <Grid item xs={12}>
              <DashboardCard title="Services">
                {services?.map((service, index) => (
                  <Grid 
                    container 
                    key={index} 
                    spacing={2} 
                    sx={{ 
                      my: 2,
                      '&:hover': {
                          backgroundColor: 'rgba(0, 0, 0, 0.03)' // Light gray background color on hover
                      }
                    }}
                  >
                    <Grid item xs={9}>
                        <Typography variant="h5">{service.service_name}</Typography>
                        <Typography variant="body1" sx={{ mt: 0.5 }}>{service.service_description}</Typography>
                        <Typography variant="body2" sx={{ mt: 0.5 }}>
                            {service.service_details.slice(0, 150)}
                            {service.service_details.length > 150 && '...'}
                        </Typography>
                    </Grid>
                    <Grid item xs={3} container alignItems="flex-end" justifyContent="flex-end">
                      {service.service_price ?  (
                        <PricingComponent 
                          price={service.service_price} 
                          currency={service.currency} 
                          pricingType={service.pricing_type} 
                        />
                        ) : (
                          <></>
                        )}
                        <div style={{ display: 'flex', gap: '8px', margin: "12px 0" }}>
                        <Button 
                          component="a" 
                          onClick={() => { navigate(`/service/${service.id}`)}}
                          variant="outlined" 
                          color="primary" 
                          // style={{ whiteSpace: 'nowrap', padding: '0', margin: "12px 0" }}
                          >
                            Read more
                        </Button>
                          {/* <Button 
                            variant="contained" 
                            color="primary" 
                            style={{ whiteSpace: 'nowrap'}} 
                            onClick={() => handleOpenModal(service.id)}>
                            Book Service
                          </Button> */}
                        </div>
                    </Grid>
                    {index !== services.length - 1 && (
                      <Grid item xs={12}>
                          <Divider />
                      </Grid>
                    )}
                </Grid>
                ))}
              </DashboardCard>
            </Grid>
          </Grid>
          )}

          {(profileData.about_drivers || profileData.about_unique) && ( 
          <Grid container spacing={1} marginTop={1}>
            <Grid item xs={12}>
              <DashboardCard>
                {profileData.about_drivers && (
                  <>
                  <Typography variant='h5'>
                    What Drives Me
                  </Typography>
                  <Typography variant="body1" marginBottom={3}> 
                    {formatTextWithLineBreaks(profileData.about_drivers)}
                  </Typography>
                  </>
                )}
                {profileData.about_unique && (
                  <>
                  <Typography variant='h5'>
                    My Unique Approach
                  </Typography>
                  <Typography variant="body1" marginBottom={3}> 
                    {formatTextWithLineBreaks(profileData.about_unique)}
                  </Typography>
                  </>
                )}
                {/* <Typography variant="subtitle3">
                  Hourly Rate: 
                  <Typography variant="body1" component="span" marginLeft={1}>
                    ${profileData.standard_hourly_rate} /hr
                  </Typography>
                </Typography>
                <br/>
                <Typography variant="subtitle3">
                  Available from: 
                  <Typography variant="body1" component="span" marginLeft={1}>
                    {new Date(profileData.availability_date).toLocaleDateString()}
                  </Typography>
                </Typography> */}
              </DashboardCard>
            </Grid>
          </Grid>
          )}
          {experiences && experiences.length > 0 && (
          <Grid container spacing={1} marginTop={1}>
            <Grid item xs={12}>
              <DashboardCard title="Experiences">
                {experiences?.map((experience, index) => (
                  <>
                  <Typography variant='h5'>
                    {experience.job_title}
                  </Typography>
                  <Typography variant="subtitle2">
                    {experience.company_name}
                  </Typography>
                  <Typography variant='subtitle2' color='grey.400' marginBottom={1}>
                    {formatDate(experience.start_date)} - {formatDate(experience.end_date)}
                    <Typography component="span" marginLeft={1}>
                      | {calculateDuration(experience.start_date, experience.end_date)}
                    </Typography>
                  </Typography>
                  <Typography variant="body1" marginBottom={3}> 
                    {experience.description}
                  </Typography>            
                  {index !== experiences.length - 1 && (
                    <Divider style={{ marginBottom: '16px' }} />
                  )}
                  </>
                ))}
              </DashboardCard>
            </Grid>
          </Grid>
          )}
          {skills && skills.length > 0 && (
          <Grid container spacing={1} marginTop={1}>
            <Grid item xs={12}>
              <DashboardCard title="Skills">
                {skills?.map((skill, index) => (
                  <Chip label={skill?.skill_name ?? 'error'} style={{ margin: '4px' }} />
                ))}
              </DashboardCard>
            </Grid>
          </Grid>
          )}
          {educations && educations.length > 0 && (
          <Grid container spacing={1} marginTop={1}>
            <Grid item xs={12}>
              <DashboardCard title="Education">
                {educations?.map((education, index) => (
                  <>
                  <Typography variant='h5'>
                    {education.institution_name}
                  </Typography>
                  <Typography variant="subtitle2">
                    {education.field_of_study}
                  </Typography>
                  <Typography variant='subtitle2' color='grey.400' marginBottom={1}>
                    {formatDate(education.start_date)} - {formatDate(education.end_date)}
                    <Typography component="span" marginLeft={1}>
                      | {calculateDuration(education.start_date, education.end_date)}
                    </Typography>
                  </Typography>
                  <Typography variant="body1" marginBottom={3}> 
                    {education.description}
                  </Typography>            
                  {index !== educations.length - 1 && (
                    <Divider style={{ marginBottom: '16px' }} />
                  )}
                  </>
                ))}
              </DashboardCard>
            </Grid>
          </Grid>
          )}
          {languages && languages.length > 0 && (
          <Grid container spacing={1} marginTop={1}>
            <Grid item xs={12}>
              <DashboardCard title="Languages">
                {languages?.map((language, index) => (
                  <Chip 
                    label={<><b>{language.language_name}</b>: {language.proficiency_level}</>} 
                    style={{ margin: '4px' }} 
                  />
                ))}
              </DashboardCard>
            </Grid>
          </Grid>
          )}
        </Grid>
      </Grid> 
      <Modal
          open={isModalOpen}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
        <Box sx={modalStyle}>
          <BookingRequest freelancer_id={freelancer_id} preSelectedServiceId={preSelectedServiceId} />
        </Box>
      </Modal>
    </PageContainer>
    </>
  );
};

export default FreelancerProfile;