import React, { useState, useEffect } from 'react';
import { updateUserDetails } from '../../../services/message.service';
import { FormControl, Box, InputLabel, MenuItem, Select, Typography, Grid, Button, TextField, FormControlLabel, Checkbox, CircularProgress, Snackbar, Alert } from '@mui/material';
import { useUserContext } from 'src/contexts/Contexts';
import { isValidNumber } from 'libphonenumber-js';
import DashboardCard from '../../../components/shared/DashboardCard';


const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(String(email).toLowerCase());

const euCountries = [
  { label: 'Austria', value: 'Austria' },
  { label: 'Belgium', value: 'Belgium' },
  { label: 'Bulgaria', value: 'Bulgaria' },
  { label: 'Croatia', value: 'Croatia' },
  { label: 'Republic of Cyprus', value: 'Republic of Cyprus' },
  { label: 'Czech Republic', value: 'Czech Republic' },
  { label: 'Denmark', value: 'Denmark' },
  { label: 'Estonia', value: 'Estonia' },
  { label: 'Finland', value: 'Finland' },
  { label: 'France', value: 'France' },
  { label: 'Germany', value: 'Germany' },
  { label: 'Greece', value: 'Greece' },
  { label: 'Hungary', value: 'Hungary' },
  { label: 'Iceland', value: 'Iceland' }, 
  { label: 'Ireland', value: 'Ireland' },
  { label: 'Italy', value: 'Italy' },
  { label: 'Latvia', value: 'Latvia' },
  { label: 'Lithuania', value: 'Lithuania' },
  { label: 'Luxembourg', value: 'Luxembourg' },
  { label: 'Malta', value: 'Malta' },
  { label: 'Netherlands', value: 'Netherlands' },
  { label: 'Norway', value: 'Norway' },  
  { label: 'Poland', value: 'Poland' },
  { label: 'Portugal', value: 'Portugal' },
  { label: 'Romania', value: 'Romania' },
  { label: 'Slovakia', value: 'Slovakia' },
  { label: 'Slovenia', value: 'Slovenia' },
  { label: 'Spain', value: 'Spain' },
  { label: 'Sweden', value: 'Sweden' },
  { label: 'United Kingdom', value: 'United Kingdom' }, 
];

const euCountryCodes = [
  { label: 'Austria (+43)', value: '+43' },
  { label: 'Belgium (+32)', value: '+32' },
  { label: 'Bulgaria (+359)', value: '+359' },
  { label: 'Croatia (+385)', value: '+385' },
  { label: 'Republic of Cyprus (+357)', value: '+357' },
  { label: 'Czech Republic (+420)', value: '+420' },
  { label: 'Denmark (+45)', value: '+45' },
  { label: 'Estonia (+372)', value: '+372' },
  { label: 'Finland (+358)', value: '+358' },
  { label: 'France (+33)', value: '+33' },
  { label: 'Germany (+49)', value: '+49' },
  { label: 'Greece (+30)', value: '+30' },
  { label: 'Hungary (+36)', value: '+36' },
  { label: 'Iceland (+354)', value: '+354' }, 
  { label: 'Ireland (+353)', value: '+353' },
  { label: 'Italy (+39)', value: '+39' },
  { label: 'Latvia (+371)', value: '+371' },
  { label: 'Lithuania (+370)', value: '+370' },
  { label: 'Luxembourg (+352)', value: '+352' },
  { label: 'Malta (+356)', value: '+356' },
  { label: 'Netherlands (+31)', value: '+31' },
  { label: 'Norway (+47)', value: '+47' },  
  { label: 'Poland (+48)', value: '+48' },
  { label: 'Portugal (+351)', value: '+351' },
  { label: 'Romania (+40)', value: '+40' },
  { label: 'Slovakia (+421)', value: '+421' },
  { label: 'Slovenia (+386)', value: '+386' },
  { label: 'Spain (+34)', value: '+34' },
  { label: 'Sweden (+46)', value: '+46' },
  { label: 'United Kingdom (+44)', value: '+44' }, 
];

export const ProfileInfo = ({ onNext }) => { 
  const { userData, setUserData, token, userSubscriptionData, isFreelancer, isClient } = useUserContext();
  const [personalInfo, setPersonalInfo] = useState({
    firstname: '',
    lastname: '',
    email: '',
    phone_country_code: '+46',
    phone_number: '',
    acceptGDPR: false,
    social_security_nr: '',
    company_name: '',
    vat_nr: '',
    address_1: '',
    address_2: '',
    zipcode: '',
    city: '',
    country: 'Sweden',
    invoice_email: '',
    bankgiro: '',
    plusgiro: '',
    iban: '',
    bic: '',

  });
  const [formErrors, setFormErrors] = useState({
    email: '',
    invoice_email: '',
    phone_number: '',
    gdprConsent: '',
  });
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    if (userData) {
      const updatedPersonalInfo = {
        ...userData,
        acceptGDPR: Boolean(userData.accepted_gdpr_at)
      };
      setPersonalInfo(updatedPersonalInfo);
    }
  }, [userData]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setPersonalInfo({ ...personalInfo, [name]: value });
  
    if (name === 'invoice_email' && !validateEmail(value)) {
      setFormErrors({ ...formErrors, invoice_email: 'Invalid invoice email address' });
    } else {
      setFormErrors({ ...formErrors, invoice_email: '' });
    }

    // Check for phone number validation
    if (name === 'phone_number' || name === 'phone_country_code') {
      const fullPhoneNumber = `${personalInfo.phone_country_code}${value}`;
      if (!isValidNumber(fullPhoneNumber)) {
        setFormErrors({ ...formErrors, phone_number: 'Invalid phone number' });
      } else {
        setFormErrors({ ...formErrors, phone_number: '' });
      }
    }
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setPersonalInfo({ ...personalInfo, [name]: checked });
  };

  const validateForm = () => {
    let isValid = true;
    let newFormErrors = { ...formErrors };
  
    // Check if GDPR consent is accepted
    if (!personalInfo.acceptGDPR) {
      newFormErrors = { ...newFormErrors, gdprConsent: 'You must consent to the privacy policy to proceed.' };
      isValid = false;
    }

    setFormErrors(newFormErrors);
    return isValid;
  };

  const handleSubmit = async () => {
    if (!validateForm()) {
      setError('Please fill all required fields correctly.');
      return;
    }
    setLoading(true);

    // Update GDPR acceptance timestamp
    const updatedPersonalInfo = { ...personalInfo, accepted_gdpr_at: new Date().toISOString() };

    try {
      const updatedUserData = await updateUserDetails(userData.id, updatedPersonalInfo, token);
      setUserData(updatedUserData.data);
      onNext();
      setLoading(false);
      setSuccess('Successfully updated your profile.');
    } catch (error) {
      setLoading(false);
      setError('Error updating the profile.');
    }
  };


  if (!userSubscriptionData) {
    return <div>Loading...</div>; 
  }

  return (
    <>
      <Grid item xs={12}>
        <DashboardCard title="Contact information">
          <Grid container spacing={3} marginTop={3}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="First Name"
                name="firstname"
                value={personalInfo ? (personalInfo.firstname || '') : ''}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Last Name"
                name="lastname"
                value={personalInfo ? (personalInfo.lastname || '') : ''}
                onChange={handleInputChange}
              /> 
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Email"
                name="email"
                value={personalInfo ? (personalInfo.email || '') : ''}
                onChange={handleInputChange}
                error={Boolean(formErrors.email)}
                disabled={true}
              />
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" alignItems="center">
                {/* Country Code Select */}
                <FormControl>
                  <InputLabel id="phone_country_code">Country Code</InputLabel>
                  <Select
                    labelId="phone_country_code"
                    id="phone_country_code"
                    value={personalInfo.phone_country_code || ''}
                    name="phone_country_code"
                    onChange={handleInputChange}
                    style={{ minWidth: 140 }}
                  >
                    {euCountryCodes.map((code, index) => (
                      <MenuItem key={index} value={code.value}>
                        {code.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {/* Phone Number TextField */}
                <TextField
                  label="Phone Number"
                  name="phone_number"
                  value={personalInfo ? (personalInfo.phone_number || '') : ''}
                  onChange={handleInputChange}
                  error={Boolean(formErrors.phone_number)}
                  style={{ marginLeft: 16, minWidth: 250 }}
                />
              </Box>
            </Grid>
            {userSubscriptionData.subscription_id === 2 || userSubscriptionData.subscription_id === 3 && (
              <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Social Security Number (XXXXXX-XXXX)"
                    name="social_security_nr"
                    value={personalInfo ? (personalInfo.social_security_nr || '') : ''}
                    onChange={handleInputChange}
                    required
                  />
              </Grid>
            )}
          </Grid>
        </DashboardCard>
      </Grid>
      {(isClient || userSubscriptionData.subscription_id === 1 || userSubscriptionData.subscription_id === 3) && (
      <Grid item xs={12}>
        <DashboardCard title="Company Information">
          {userSubscriptionData.subscription_id === 3 && (
            <Typography variant='info'>
              This info can be filled in later, when your company has been set up.
            </Typography>
          )}
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Company Name"
                name="company_name"
                value={personalInfo ? (personalInfo.company_name || '') : ''}
                onChange={handleInputChange}
                required
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="VAT Number"
                name="vat_nr"
                value={personalInfo ? (personalInfo.vat_nr || '') : ''}
                onChange={handleInputChange}
                required
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Address 1"
                name="address_1"
                value={personalInfo ? (personalInfo.address_1 || '') : ''}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Address 2"
                name="address_2"
                value={personalInfo ? (personalInfo.address_2 || '') : ''}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                fullWidth
                label="Zip Code"
                name="zipcode"
                value={personalInfo ? (personalInfo.zipcode || '') : ''}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                label="City"
                name="city"
                value={personalInfo ? (personalInfo.city || '') : ''}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel id="country-label">Country</InputLabel>
                <Select
                  labelId="country-label"
                  id="country-select"
                  
                  value={personalInfo ? (personalInfo.country || '') : ''}
                  name="country"
                  onChange={handleInputChange}
                >
                  {euCountries.map((country, index) => (
                    <MenuItem key={index} value={country.value}>
                      {country.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Invoice Email"
                name="invoice_email"
                value={personalInfo ? (personalInfo.invoice_email || '') : ''}
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>
        </DashboardCard>
      </Grid>
      
      )}

      {isFreelancer && (userSubscriptionData.subscription_id === 1 || userSubscriptionData.subscription_id === 3) && (
        <Grid item xs={12}>
          <DashboardCard title="Payout Information">
            <Typography variant='info'>
              Add bank account information so we can make payouts to your account.
            </Typography>
            <Grid container spacing={3} marginTop={1}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Bankgiro (Sweden only)"
                  name="bankgiro"
                  value={personalInfo.bankgiro || ''}
                  onChange={handleInputChange}
                  placeholder="xxxx-yyyy"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Plusgiro (Sweden only)"
                  name="plusgiro"
                  value={personalInfo.plusgiro || ''}
                  onChange={handleInputChange}
                  placeholder="xxxx-yyyy"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="IBAN"
                  name="iban"
                  value={personalInfo.iban || ''}
                  onChange={handleInputChange}
                  placeholder="Enter IBAN"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="BIC"
                  name="bic"
                  value={personalInfo.bic || ''}
                  onChange={handleInputChange}
                  placeholder="Enter BIC"
                />
              </Grid>
            </Grid>
          </DashboardCard>
        </Grid>
      )}


      <Grid item xs={12}>
        <DashboardCard title="Privacy & Save">
          <Grid item xs={12}>
          <FormControlLabel
            control={<Checkbox checked={personalInfo.acceptGDPR} onChange={handleCheckboxChange} name="acceptGDPR" />}
            label={
              <React.Fragment>
                By checking this box, I acknowledge that I have read and understood the <a href="/terms-of-use" target="_blank" rel="noopener noreferrer">Terms of Use</a> and <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>. 
                I consent to the collection, storage, and use of my personal data as described therein, including the transfer of this data within and outside the European Union in accordance with the GDPR regulations.
              </React.Fragment>
            }
          />
          {formErrors.gdprConsent && <Typography color="error">{formErrors.gdprConsent}</Typography>}
          </Grid>
          <Grid container alignItems="flex-end" justifyContent="flex-end">
            <Button 
              variant="contained" 
              color="primary" 
              onClick={handleSubmit}
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : 'Save all information'}
            </Button>

          </Grid>
          <Snackbar
            open={!!error || !!success}
            autoHideDuration={6000}
            onClose={() => { setError(''); setSuccess(''); }}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          >
            <Alert 
              onClose={() => { setError(''); setSuccess(''); }} 
              severity={error ? "error" : "success"}
              sx={{ width: '100%' }}
            >
              {error || success}
            </Alert>
          </Snackbar>

        </DashboardCard>
      </Grid>
    </>
  );
};
