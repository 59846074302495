import { Typography, Grid, Box } from '@mui/material';
import React from 'react';
import ListInvoices from './components/ListInvoices';
import CreateInvoice from './components/CreateInvoice';
import { useParams } from 'react-router-dom';

const ShowInvoices = () => {
  const { offerId, invoiceId } = useParams();


  return (
      <Box>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h1" align='center'>All your invoices</Typography>
            <Typography variant="subtitle1" align='center' marginBottom={3}>Here is a complete list of all your invoices.</Typography>
          </Grid>
          <Grid item xs={12} lg={8}>
            <ListInvoices/>
          </Grid>
          <Grid item xs={12} lg={4}>
            <CreateInvoice />
          </Grid>
        </Grid>
      </Box>
  );
};
export default ShowInvoices;