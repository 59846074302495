import React, { lazy } from 'react';
import Service from './components/Service';
import { Container } from '@mui/material';



const Freelancer = () => {
  return (
    <>
    <div style={{
          backgroundColor: 'white',
          padding: '50px',
      }} >
        <Service />
    </div>
  </>
  );
};

export default Freelancer;
