import React, { useState, useEffect } from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
  MenuItem
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useUserContext } from 'src/contexts/Contexts';
import {
  getFreelancerLanguages,
  createFreelancerLanguage,
  updateFreelancerLanguage,
  deleteFreelancerLanguage
} from 'src/services/message.service';

const Languages = ({ cvData }) => {
  const { freelancerData, token } = useUserContext();
  const [languages, setLanguages] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [languageToDelete, setLanguageToDelete] = useState(null);
  const [isSaved, setIsSaved] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openFormDialog, setOpenFormDialog] = useState(false);
  const [languageData, setLanguageData] = useState({
    language_name: '',
    proficiency_level: '',
    freelancer_id: freelancerData ? freelancerData.id : null,
  });

  useEffect(() => {
    if (freelancerData) {
      setLanguageData({
        language_name: '',
        proficiency_level: '',
        freelancer_id: freelancerData.id,
      });
    }
  }, []);

  useEffect(() => {
    const fetchLanguages = async () => {
      if (freelancerData) {
        const allLanguages = await getFreelancerLanguages(freelancerData.id, token);
        setLanguages(allLanguages.data);
      }
    };
    fetchLanguages();
    if (isSaved) {
      setTimeout(() => {
        setIsSaved(false);
      }, 2000);
    }
  }, [isSaved, freelancerData, token]);

  useEffect(() => {
    const handleCvDataChange = async () => {
        if (cvData && cvData.languages) {
          const tempLanguages = await getFreelancerLanguages(freelancerData.id, token);
          if (tempLanguages.data) {
            for (const language of tempLanguages.data) {
                await deleteFreelancerLanguage(language.id, token);
            }
          }

          // Create new experiences from cvData.workExperience
          for (const newLanguage of cvData.languages) {
            await createFreelancerLanguage({
                ...newLanguage,
                freelancer_id: freelancerData.id,
            }, token);
          }

          // Fetch and set the updated experiences list
          const allLanguages = await getFreelancerLanguages(freelancerData.id, token);
          setLanguages(allLanguages.data);
        }
    };

      handleCvDataChange();
  }, [cvData]); 

  const handleLanguageChange = (e) => {
    const { name, value } = e.target;
    setLanguageData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      if (selectedLanguage) {
        await updateFreelancerLanguage(selectedLanguage.id, languageData, token);
      } else {
        await createFreelancerLanguage(languageData, token);
      }
      const allLanguages = await getFreelancerLanguages(freelancerData.id, token);
      setLanguages(allLanguages.data);
      setIsSaved(true);
      setSelectedLanguage(null);
    } catch (error) {
      console.error('Error updating or creating language:', error);
    }
    setIsLoading(false);
    setLanguageData({
      language_name: '',
      proficiency_level: '',
      freelancer_id: freelancerData.id,
    });
    setOpenFormDialog(false);
  };

  const handleEditLanguage = (language) => {
    setSelectedLanguage(language);
    setLanguageData(language);
    setOpenFormDialog(true);
  };

  const handleCancel = () => {
    setLanguageData({
      language_name: '',
      proficiency_level: '',
      freelancer_id: freelancerData.id,
    });
    setSelectedLanguage(null);
    setOpenFormDialog(false);
  };

  const openDeleteModal = (languageId) => {
    setLanguageToDelete(languageId);
    setOpenDeleteDialog(true);
  };

  const closeDeleteModal = () => {
    setLanguageToDelete(null);
    setOpenDeleteDialog(false);
  };

  const confirmDelete = async () => {
    if (languageToDelete) {
      await handleDeleteLanguage(languageToDelete);
      closeDeleteModal();
    }
  };

  const handleDeleteLanguage = async (languageId) => {
    try {
      await deleteFreelancerLanguage(languageId, token);
      const updatedLanguages = languages.filter(language => language.id !== languageId);
      setLanguages(updatedLanguages);
    } catch (error) {
      console.error('Error deleting language:', error);
    }
    setSelectedLanguage(null);
    setLanguageData({
      language_name: '',
      proficiency_level: '',
      freelancer_id: freelancerData.id,
    });
  };

  // Render UI for languages management
  return (
    <>
      <Typography variant="h4">
        My Languages
      </Typography>
      <Typography variant="body2" marginTop={1}>
        Add languages you speak and your proficiency level.
      </Typography>

      {/* Language list */}
      <Grid container spacing={3} marginTop={0}>
        <Grid item xs={12}>
          <List>
            {languages && languages.length > 0 ? (
              languages.map((language, index) => (
                <React.Fragment key={language.id}>
                  <ListItem
                    secondaryAction={
                      <>
                        <IconButton edge="end" onClick={() => handleEditLanguage(language)}>
                          <EditIcon />
                        </IconButton>
                        <IconButton edge="end" onClick={() => openDeleteModal(language.id)}>
                          <DeleteIcon />
                        </IconButton>
                      </>
                    }
                  >
                    <ListItemText
                      primary={language.language_name}
                      secondary={`Proficiency: ${language.proficiency_level}`}
                    />
                  </ListItem>
                  {index < languages.length - 1 && <Divider />}
                </React.Fragment>
              ))
            ) : (
              <Typography variant="body1">No languages found.</Typography>
            )}
          </List>
        </Grid>
      </Grid>

      {/* Add/Edit Language Form */}
      <Dialog open={openFormDialog} onClose={() => setOpenFormDialog(false)}>
        <DialogTitle>{selectedLanguage ? 'Edit Language' : 'Add New Language'}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} marginTop={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Language Name"
                name="language_name"
                value={languageData.language_name}
                onChange={handleLanguageChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                select
                fullWidth
                label="Proficiency Level"
                name="proficiency_level"
                value={languageData.proficiency_level}
                onChange={handleLanguageChange}
              >
                <MenuItem value="Basic">Basic</MenuItem>
                <MenuItem value="Conversational">Conversational</MenuItem>
                <MenuItem value="Proficient">Proficient</MenuItem>
                <MenuItem value="Fluent">Fluent</MenuItem>
              </TextField>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            {isLoading ? <CircularProgress size={24} /> : 'Save'}
          </Button>
        </DialogActions>
      </Dialog>
      
      {/* Add New Language Button */}
      <Button
        variant="contained"
        onClick={() => setOpenFormDialog(true)}
        sx={{ mt: 2 }}
      >
        Add New Language
      </Button>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={openDeleteDialog}
        onClose={closeDeleteModal}
      >
        <DialogTitle>{"Delete Language"}</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete this language?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteModal} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmDelete} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Languages;