import React, { useState, useEffect } from 'react';
import { Grid, Card, Chip, CardHeader, Button, Typography, Link, Divider, Collapse, IconButton } from '@mui/material';
import DashboardCard from '../../../components/shared/DashboardCard';
import { useUserContext } from '../../../contexts/Contexts';
import { getClientNeedById, getClientNeedSkills, getOfferByClientNeedId } from '../../../services/message.service'; 
import { useNavigate, useParams } from 'react-router-dom';
import Avatar from '@mui/material/Avatar'; 
import MakeDealModal from './MakeDealModal';


const OffersView = () => {
  const { clientData, token } = useUserContext();
  const { needId } = useParams();
  const [needs, setNeeds] = useState([]);
  const navigate = useNavigate();
  const [skillsData, setSkillsData] = useState({});
  const [offerData, setOfferData] = useState({});
  const [expandedOffer, setExpandedOffer] = useState(null);
  const [isDealModalOpen, setIsDealModalOpen] = useState(false);
  const [currentOfferData, setCurrentOfferData] = useState(null);
  const [isProjectExpanded, setIsProjectExpanded] = useState(false);



  const fetchSkillsForNeed = async (needId) => {
    if (!skillsData[needId]) {
      try {
        const fetchedSkills = await getClientNeedSkills(needId, token);
        setSkillsData(prevSkillsData => ({
          ...prevSkillsData,
          [needId]: fetchedSkills.data,
        }));
      } catch (error) {
        console.error(`Error fetching skills for need ${needId}:`, error);
      }
    }
  };


  const fetchOffersForNeed = async (needId) => {
    if (!offerData[needId]) {
      try {
        const fetchedOffers = await getOfferByClientNeedId(needId, token);
        setOfferData(prevOfferData => ({
          ...prevOfferData,
          [needId]: fetchedOffers.data
        }));
        
      } catch (error) {
        console.error(`Error fetching offers for need ${needId}:`, error);
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchedNeeds = await getClientNeedById(needId, token);
        setNeeds(fetchedNeeds.data);
      } catch (error) {
        console.error('Error fetching client needs:', error);
      }
    };

    fetchData();
  }, [needId, token]);


  useEffect(() => {
        fetchOffersForNeed(needId);
  }, [needId, needs]);
  
  const handleExpandClick = (index) => {
    if (expandedOffer === index) {
      setExpandedOffer(null);
    } else {
      setExpandedOffer(index);
    }
  };

  const handleMakeDealClick = (offerData) => {
    setCurrentOfferData(offerData);
    setIsDealModalOpen(true);
  };

if (!needs) {
  return <div>Loading...</div>
}


  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={8} lg={8}>
        <DashboardCard title={needs.title}>
          {/* <Card variant="outlined" style={{ marginBottom: '16px', padding: '16px' }}> */}
            <Typography variant="body1" paragraph>
              {needs.description && needs.description.length > 150 
                ? isProjectExpanded 
                  ? needs.description 
                  : `${needs.description.substring(0,150)}...`
                : needs.description}
              {needs.description && needs.description.length > 150 && (
                <Button
                  color="primary"
                  onClick={() => setIsProjectExpanded(!isProjectExpanded)}
                  style={{marginLeft: 'auto', display: 'block', textTransform: 'none'}}
                >
                  {isProjectExpanded ? 'Read less' : 'Read more...'}
                </Button>
              )}
            </Typography>

            <Divider style={{margin: '16px 0'}} />
            <Typography variant="h6" gutterBottom>
                Offers:
            </Typography>
            {offerData[needs.id] && offerData[needs.id].length > 0 ? (
              offerData[needs.id].map((offer, offerIndex) => (
                <div key={offerIndex} style={{ marginBottom: '24px' }}>
                  <CardHeader
                    avatar={
                      <Avatar src={offer ? offer.profile_image || ' ' : ' '} alt={offer ? offer.freelancer_id : 'Unknown'} />
                    }
                    title={offer.firstname}
                    subheader={
                      expandedOffer === offerIndex
                        ? offer.message
                        : offer.message.length > 100
                          ? offer.message.substring(0, 100) + '...'
                          : offer.message
                    }
                  />
                  {offer.message.length > 100 && (
                    <Button
                      size="small"
                      color="primary"
                      onClick={() => setExpandedOffer(expandedOffer === offerIndex ? null : offerIndex)}
                      style={{marginLeft: 'auto', display: 'block', textTransform: 'none'}}
                    >
                      {expandedOffer === offerIndex ? 'Read less' : 'Read more...'}
                    </Button>
                  )}
                   {/* <Chip label={`Price: ${Math.floor(offer.amount)} SEK`} style={{ margin: '8px 16px' }} /> */}
                    <div style={{ display: 'flex', gap: '8px', alignItems: 'center', justifyContent: 'flex-end', paddingRight: '16px' }}>
                      <Button onClick={() => navigate(`/freelancer/${offer.freelancer_id}`)} target="_blank">View Profile</Button>
                      {/* <Button size="small" color="primary">Message</Button> */}
                      <Button size="small" variant="contained" color="primary" onClick={() => navigate(`/plaza/offer_detail/${offer.id}/${needs.id}/${offer.freelancer_id}`)}>See offer details</Button>
                      {/* {
                        offer.deal_id === null ? (
                          <Button size="small" variant="contained" color="primary" >
                            Make Deal
                          </Button>
                        ) : (
                          <Button size="small" variant="contained" color="secondary" startIcon={<CheckCircleIcon />}>
                            Deal Created
                          </Button>
                        )
                      } */}
                    </div>
                    <Divider style={{margin: '16px 0'}} />
                </div>
              ))
            ) : (
              <Typography variant="body2" color="textSecondary">
                No offers available yet.
              </Typography>
            )}

          {/* </Card> */}
        </DashboardCard>
      </Grid>
      <Grid item xs={4}>
        <DashboardCard title="How it works">
          <Typography variant='body2'>
            Here you see all the offers created by freelancers.
            Click on the offer to see more details. 
            Once you are happy with an offer, move forward to make a deal.
            <br /><br />
            <strong>The process:</strong>
            <ul>
              <li>You publish your project or need</li>
              <li>Freelancers who qualify will get noticed</li>
              <li>Freelancers make an offer or sends you questions - You will be notified by email</li>
              <li>As soon as you have an offer you are happy with, you can make a Deal with the freelancer</li>
              <li>Contracts are sent out to both freelancer and client to sign. Check your email within 24 hours</li>
            </ul>
          </Typography>
        </DashboardCard>
      </Grid>
      <MakeDealModal 
          isOpen={isDealModalOpen} 
          onClose={() => setIsDealModalOpen(false)} 
          offerData={currentOfferData} 
      />
    </Grid>
    
  );
};


export default OffersView;
