import React, { useState, useEffect } from 'react';
import { getOfferById, getUserByClientId } from 'src/services/message.service';
import { useUserContext } from 'src/contexts/Contexts';
import DashboardCard from 'src/components/shared/DashboardCard';
import Grid from '@mui/material/Grid'; // Import Grid from Material UI


const CompanyDetails = ({ offerId }) => {
  const [companyDetails, setCompanyDetails] = useState(null);
  const [offerData, setOfferData] = useState(null);
  const [userData, setUserData] = useState(null);

  const { token } = useUserContext();

  useEffect(() => {
    const fetchData = async () => {
      if (offerId) {
        const offerResponse = await getOfferById(offerId, token);
        setOfferData(offerResponse.data);
        if (offerResponse) {
          const userResponse = await getUserByClientId(offerResponse.data.client_id, token);
          setUserData(userResponse.data);
        }
      }
    }
    fetchData();
  }, [offerId, token]);

  if (!userData) {
    return <div>Loading company details...</div>;
  }

  return (
    <DashboardCard title="Client">
      <Grid container spacing={2}> {/* Use Grid container to create a flex container */}
        <Grid item xs={12} md={6}> {/* First column */}
          <div>
            <p>{userData.company_name} <br/>
              {userData.address_1} <br/>
              {userData.zipcode} <br/>
              {userData.city} <br/>
              {userData.country}</p>
            <p><strong>Contact Person:</strong> {userData.firstname} {userData.lastname} <br/>
            <strong>Invoice Email:</strong> {userData.invoice_email}</p>
          </div>
        </Grid>
      </Grid>    
    </DashboardCard>
  );
};

export default CompanyDetails;