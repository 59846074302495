import React, { useState, useEffect, useRef } from 'react';
import { Typography, Grid, TextField, Button } from '@mui/material';
import { createFreelancerSkill, deleteFreelancerSkill, getUserByAuth0Id, getFreelancerByUserId, getSkills, getFreelancerSkills } from "../../../services/message.service";
import { useAuth0 } from "@auth0/auth0-react";
import { useUserContext } from '../../../contexts/Contexts';

import DashboardCard from '../../../components/shared/DashboardCard';

const Skills = ({ cvData }) => {
  const { userData, freelancerData, clientData, isFreelancer, isClient, token } = useUserContext();
  const [skillsData, setSkillsData] = useState(null);
  const [freelancerSkillsData, setFreelancerSkillsData] = useState(null);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [savingSkillId, setSavingSkillId] = useState(null);


  useEffect(() => {
    // Fetch skills from the server and update state
    const fetchSkills = async () => {
      try {
        const skillsDataResponse = await getSkills();
        setSkillsData(skillsDataResponse.data);
  
        if (freelancerData) {
          const freelancerSkillsDataResponse = await getFreelancerSkills(freelancerData.id);
          setFreelancerSkillsData(freelancerSkillsDataResponse.data);
          setSelectedSkills(freelancerSkillsDataResponse.data.map(skill => skill.skill_id));
        }
      } catch (error) {
        console.error('Error fetching skills:', error);
      }
    };
  
    fetchSkills();
  }, []); // Only re-run this effect if freelancerData changes

  
  useEffect(() => {
    // Function to determine if a skill from cvData matches a skill in skillsData
    const matchSkills = (cvSkills, allSkills) => {
      const matchedSkillIds = allSkills.reduce((acc, skill) => {
        // Check if cvSkill is a substring of skill.skill_name, case-insensitive
        const match = cvSkills.find(cvSkill => 
          skill.skill_name.toLowerCase().includes(cvSkill.toLowerCase())
        );
        if (match) {
          acc.push(skill.id);
        }
        return acc;
      }, []);
      return matchedSkillIds;
    };
    
    const updateSkillsBasedOnCV = async () => {
      if (cvData && cvData.skills && skillsData) {
        const matchedSkillIds = matchSkills(cvData.skills, skillsData);
  
        // Filter out the skills that are already selected
        const newSkillsToAdd = matchedSkillIds.filter(id => !selectedSkills.includes(id));
        const skillsToRemove = selectedSkills.filter(id => !matchedSkillIds.includes(id));
  
        console.log('Adding skills:', newSkillsToAdd);
        console.log('Removing skills:', skillsToRemove);
    
        // Add new skills
        for (const skillId of newSkillsToAdd) {
          console.log('Attempting to add skill:', skillId);
          await handleSkillChange(skillId, true); // true to add
        }
        // Remove skills that are not in the CV data
        // for (const skillId of skillsToRemove) {
        //   await handleSkillChange(skillId, false); // false to remove
        // }
      }
    };
  
    updateSkillsBasedOnCV();
  }, [cvData, skillsData]); // Only re-run this effect if cvData or skillsData changes


  const handleSkillChange = async (skillId, shouldAdd) => {
    setSavingSkillId(skillId); // Show saving indication
  
    const skillData = {
      freelancer_id: freelancerData.id,
      skill_id: skillId,
    };
  
    try {
      if (shouldAdd) {
        await createFreelancerSkill(skillData, token);
      } else {
        await deleteFreelancerSkill(skillData.freelancer_id, skillData.skill_id, token);
      }
      // After the API call, set a timeout to remove the saving indication
      setTimeout(() => {
        setSavingSkillId(null);
        // Update the selectedSkills state to reflect the change
        setSelectedSkills(currentSelectedSkills => {
          if (shouldAdd) {
            return [...currentSelectedSkills, skillId];
          } else {
            return currentSelectedSkills.filter(id => id !== skillId);
          }
        });
      }, 500); // 500 milliseconds delay
    } catch (error) {
      console.error('Error updating skill:', error);
      setSavingSkillId(null); // Remove saving indication in case of error
    }
  };

    
  return (
    <>
      <Typography variant="h4">
        My Skills
      </Typography>
      <Typography variant="body2" marginTop={1}>
        Optionally add skills for better matching and searchability.
      </Typography>
      <Grid container spacing={3} marginTop={0}>
      <Grid item xs={12} sx={{
            display: 'grid',
            gridTemplateColumns: {
                xs: '1fr',
                sm: '1fr 1fr',
                md: '1fr 1fr 1fr'
            },
            gap: '10px'
        }}>

          {skillsData && skillsData.map(skill => (
            <div key={skill.id}>
              <input 
                type="checkbox" 
                checked={selectedSkills.includes(skill.id)} 
                onChange={() => handleSkillChange(skill.id, !selectedSkills.includes(skill.id))}
                />
              {skill.skill_name}
              {savingSkillId === skill.id && <span style={{ marginLeft: '10px' }}>Saving...</span>}
            </div>
          ))}
        </Grid>
      </Grid>
    </>
  );
};

export default Skills;
