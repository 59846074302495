import React, { useState, useEffect } from 'react';
import { Typography, Grid, TextField, Button, Checkbox, CircularProgress, Snackbar, Box } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Alert from '@mui/material/Alert';
import DashboardCard from '../../../components/shared/DashboardCard';
import { useUserContext } from '../../../contexts/Contexts';
import { createClientNeed, createClientNeedSkill, getSkills } from '../../../services/message.service';

const CreateNeed = () => {
  const { clientData, token } = useUserContext();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [skillsData, setSkillsData] = useState(null);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    const fetchSkills = async () => {
      try {
        const skillsDataResponse = await getSkills(token);
        setSkillsData(skillsDataResponse.data);
      } catch (error) {
      }
    };
    fetchSkills();
  }, []);

  useEffect(() => {
    validateForm();
  }, [title, description, selectedSkills]);


  const validateForm = () => {
    const isTitleValid = title.trim() !== '';
    const isDescriptionValid = description.trim() !== '';
    const isAtLeastOneSkillSelected = selectedSkills.length > 0;

    setIsValid(isTitleValid && isDescriptionValid && isAtLeastOneSkillSelected);
  };

  const handleSkillChange = (skillId) => {
    setSelectedSkills(prevState => {
      if (prevState.includes(skillId)) {
        return prevState.filter(id => id !== skillId);
      } else {
        return [...prevState, skillId];
      }
    });
  };

  const handleSubmit = async () => {
    if (!isValid) {
      setError('Please fill all fields and select at least one skill.');
      return;
    }

    setLoading(true);
    const needData = {
      title,
      description,
      client_id: clientData.id,
      skills: selectedSkills // Modify as per your API structure
    };

    try {
      await createClientNeed(needData, token);
      setLoading(false);
      setSuccess(true);
      
    } catch (error) {
      setLoading(false);
      setError('Error creating the need');
    }
  };


  if (success) {
    return (
      <DashboardCard title="Your project is created!">
        <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
          <CheckCircleIcon color="success" style={{ fontSize: 100 }} />
          <Typography variant="body1" marginTop={2}>
            Now you can just wait for freelancers to make you an offer. 
            We are right now contacting those who qualify.
            Go to your projects to see the status and offers. We will send you an email as soon as any 
            freelancer has questions or have created an offer.
          </Typography>
        </Box>
      </DashboardCard>
    );
  }


  return (
    <DashboardCard title="Publish a New Project">
      <Typography variant="body1" marginBottom={3}>
        Type in all the details about your needs for this project. Our AI will start to look for the 
        right candidates as soon as you hit publish. The more information you give, the better we will 
        be able to match with the right freelancer.    
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField 
            label="Project Title" 
            variant="outlined" 
            fullWidth
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField 
            label="Detailed description of what you need help with" 
            variant="outlined" 
            fullWidth 
            multiline 
            rows={4}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sx={{
            display: 'grid',
            gridTemplateColumns: {
                xs: '1fr',
                sm: '1fr 1fr',
                md: '1fr 1fr 1fr'
            },
            gap: '10px'
        }}>

          <Typography variant="h6">What skills are required?</Typography>
          {skillsData && skillsData.map(skill => (
            <div key={skill.id}>
              <Checkbox 
                checked={selectedSkills.includes(skill.id)} 
                onChange={() => handleSkillChange(skill.id)}
              />
              {skill.skill_name}
            </div>
          ))}
        </Grid>
        <Grid item xs={12}>
          <Button 
            variant="contained" 
            color="primary"
            onClick={handleSubmit}
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : 'Create Need'}
          </Button>
        </Grid>
      </Grid>
      <Snackbar
        open={!!error || !!success}
        autoHideDuration={6000}
        onClose={() => { setError(''); setSuccess(''); }}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert 
          onClose={() => { setError(''); setSuccess(''); }} 
          severity={error ? "error" : "success"}
          sx={{ width: '100%' }}
        >
          {error || success}
        </Alert>
      </Snackbar>

    </DashboardCard>
  );
};

export default CreateNeed;
