import React from "react";
import { Container, Box, Typography } from '@mui/material';
import SmileLetterLogo from '../shared/logo/SmileLetterLogo';

const Footer = () => {
  return (
<Container sx={{ background: '#f4f4f4', padding: '40px 0' }}>
  <Box sx={{ 
    display: 'flex', 
    flexDirection: { xs: 'column', sm: 'row' },
    justifyContent: 'space-between', 
    alignItems: 'left',
    ml:  { xs: 2, sm: 0 }
  }}>
    <Box sx={{ mb: { xs: 2, sm: 0 } }}>
      <SmileLetterLogo />
      <Typography variant="body1">&copy; 2023 Because Mondays AB</Typography>
      <Typography variant="body2">Address: Årevägen 75. 83752 ÅRE, SWEDEN</Typography>
      <Typography variant="body2">Organization Number: 559241-6126</Typography>
    </Box>
    <Box>
      <Typography variant="subtitle1">READ MORE</Typography>
      <Typography variant="body2" sx={{ 'a': { color: 'grey.600' } }} marginBottom={1}><a href="/about">About Because Mondays</a></Typography>
      <Typography variant="body2" sx={{ 'a': { color: 'grey.600' } }} marginBottom={1}><a href="/freelancer-subscription">How It Works - Freelancers</a></Typography>
      <Typography variant="body2" sx={{ 'a': { color: 'grey.600' } }} marginBottom={1}><a href="/client-subscription">How It Works - Client</a></Typography>
      <Typography variant="body2" sx={{ 'a': { color: 'grey.600' } }} marginBottom={1}><a href="/terms-of-use" target="_blank">Terms of use</a></Typography>
      <Typography variant="body2" sx={{ 'a': { color: 'grey.600' } }} marginBottom={1}><a href="/privacy-policy" target="_blank">Privacy policy</a></Typography>
      {/* Add other links as necessary */}
    </Box>
  </Box>
</Container>
  );
};

export default Footer;