import { Grid, Box, Typography } from '@mui/material';
import React from 'react';
import ListDeals from './components/ListDeals';


const ShowOffer = () => {

  return (
    <>
        <Typography variant="h1" align='center'>Your Signed Deals</Typography>
        <Typography variant="subtitle1" align='center' marginBottom={3}>Listing all your signed or agreed deals</Typography>
        <Grid container spacing={3}>
          <Grid item xs={8}>
            <ListDeals />
          </Grid>
        </Grid>
    </>
  );
};
export default ShowOffer;