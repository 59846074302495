import React, { useState, useEffect } from 'react';
import {Link, useParams } from 'react-router-dom';
import { Grid, Typography, Button, Chip, Divider, Stack, Modal, Box } from '@mui/material';
import { getFreelancerServiceById, getFreelancerById } from "../../../services/message.service";
import DashboardCard from '../../../components/shared/DashboardCard';
import BookingRequest from './BookingRequest';
import PricingComponent from './PricingComponent';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const Service = () => {
  const { service_id } = useParams();
  const [serviceData, setServiceData] = useState(null);
  const [freelancerData, setFreelancerData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);


  useEffect(() => {
    // Fetching service data using service ID
    getFreelancerServiceById(service_id).then(data => {
      setServiceData(data.data);
      // Fetching freelancer data using the freelancer_id from the service data
      getFreelancerById(data.data.freelancer_id).then(freelancerData => {
        setFreelancerData(freelancerData.data);
      });
    });
  }, [service_id]);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };


  function formatTextWithLineBreaks(text) {
    return text.split('\n').map((line, index) => (
      <React.Fragment key={index}>
        {line}
        {index !== text.split('\n').length - 1 && <br />}
      </React.Fragment>
    ));
  }

  return (
    <Grid container spacing={2}>
      {serviceData && freelancerData ? (
        <>
          {/* Freelancer Info */}
          <Grid item xs={12} sm={4}>
            <DashboardCard title={freelancerData.firstname}>
              <img src={freelancerData.profile_image} alt={`${freelancerData.firstname}'s profile`} style={{ width: '100%' }} />
              <Typography variant="body1" marginBottom={3} overflow="hidden" display="-webkit-box" style={{ WebkitBoxOrient: 'vertical', WebkitLineClamp: 3 }}>
                {formatTextWithLineBreaks(freelancerData.about_text)}
              </Typography>
              <Button 
                variant="text" 
                color="primary" 
                style={{ whiteSpace: 'nowrap', padding: '0', margin: "12px 0" }}
                component={Link} 
                to={`/freelancer/${freelancerData.id}`}>
                Read more about {freelancerData.firstname}
              </Button>
            </DashboardCard>
          </Grid>

          {/* Service Info */}
          <Grid item xs={12} sm={8}>
            <DashboardCard title={serviceData.service_name}>
              <Typography variant="subtitle1" sx={{ mt: 1 }}>{serviceData.service_description}</Typography>
              <Typography variant="body1" sx={{ mt: 0.5, mb: 3 }}>
              {formatTextWithLineBreaks(serviceData.service_details)}
              </Typography>
              {serviceData.service_price ?  (
              <PricingComponent 
                price={serviceData.service_price} 
                currency={serviceData.currency} 
                pricingType={serviceData.pricing_type} 
              />
              ) : (
                <></>
              )}
              <div style={{ display: 'flex', gap: '8px', margin: "25px 0" }}>
                <Button variant="contained" color="primary" style={{ whiteSpace: 'nowrap'}} onClick={handleOpenModal}>
                  Send a request
                </Button>
              </div>
            </DashboardCard>
            <Modal
              open={isModalOpen}
              onClose={handleCloseModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={modalStyle}>
                <BookingRequest freelancer_id={freelancerData.id} preSelectedServiceId={service_id} />
              </Box>
            </Modal>
          </Grid>

        </>
      ) : (
        <Grid item xs={12}>
          <Typography variant="body1">Loading...</Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default Service;
