import React, { useState, useEffect } from 'react';
import { Grid, Card, CardHeader, CardContent, Collapse, IconButton, Typography, Button, Badge } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DashboardCard from '../../../components/shared/DashboardCard';
import ChatIcon from '@mui/icons-material/Chat';
import { useUserContext } from '../../../contexts/Contexts';
import {getStandaloneOfferByFreelancerId} from '../../../services/message.service'
import Avatar from '@mui/material/Avatar';
import sanitizeHtml from 'sanitize-html';
import { useNavigate } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';


const OffersStandalone = ({ fetchBy }) => {
  const { freelancerData, clientData, isFreelancer, isClient, token } = useUserContext();
  const [expanded, setExpanded] = useState(null); // Store the ID of the expanded item
  const [offers, setOffers] = useState([]);
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const navigateToBookingDetails = (bookingId) => {
    navigate(`/plaza/freelancer-booking/${bookingId}`);
  };

  useEffect(() => {
    const fetchData = async () => {
      let fetchedOffers = null;
      try {
        if (fetchBy === 'freelancer' && freelancerData) {
          fetchedOffers = await getStandaloneOfferByFreelancerId(freelancerData.id, token);
          console.log("fetchedOffers: " + JSON.stringify(fetchedOffers));
        } else if (fetchBy === 'client' && clientData) {  // Assuming clientData is in your context
          // NOT IMPLEMENTED YET
        }

        if (fetchedOffers) {
          setOffers(fetchedOffers.data.rows);
        }
      } catch (error) {
        console.error('Error fetching bookings:', error);
      }
    };

    fetchData();
  }, [fetchBy, freelancerData, clientData]);  // Include both freelancerData and clientData as dependencies

  function NewlineText({ text }) {
    const newText = text.split('\n').map((str, index, array) =>
      index === array.length - 1 ? str : <>
        {str}
        <br />
      </>
    );
    return <>{newText}</>;
  }
  

  const handleExpandClick = (id) => {
    setExpanded(expanded === id ? null : id); // Toggle expanded item by ID
  };

  return (
    <DashboardCard title="Standalone Offers Sent to Clients">
      <Typography variant='body1' marginBottom={2} marginTop={3}>
        These are offers you have sent to clients
      </Typography>
      {!offers.length > 0 && isFreelancer && (
        <Typography variant='body1' marginBottom={2} marginTop={3}>
          Right now it is empty.
        </Typography>                                     
      )}
      {offers?.map((offer, index) => (
        <Card key={offer.id} variant="outlined" style={{ marginBottom: '16px' }}>
          <CardHeader
            avatar={
              <Avatar alt={`${offer.firstname} ${offer.lastname}`} src={offer.profile_picture} />
            }
            title={
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{ position: 'relative' }}>
                  {offer.offer_id === null && (
                    <Badge variant="dot" color="primary" style={{
                      position: 'absolute',
                      top: '10px',
                      left: '-5%',
                      transform: 'translate(0%, 0)'
                    }} />
                  )}

                  <Typography 
                    variant="h5" 
                    style={{ marginTop: '12px', cursor: 'pointer' }}
                    >
                    {offer.company_name}
                  </Typography>
                </div>
              </div>
            }

            titleTypographyProps={{ variant: 'h5' }} 
            subheader={`Customer: ${offer.company_name} - ${offer.firstname} ${offer.lastname}`}
            action={
              <>
                <IconButton
                  onClick={() => handleExpandClick(offer.id)}
                  aria-expanded={expanded === offer.id}
                  aria-label="show more"
                >
                  <ExpandMoreIcon />
                </IconButton>
              </>
            }
          />
          <Collapse in={expanded === offer.id} timeout="auto" unmountOnExit>
            <CardContent>
              <Typography variant='h6'>Description:</Typography>
                <NewlineText text={sanitizeHtml(offer.message)} />
            </CardContent>
          </Collapse>
        </Card>
      ))}
    </DashboardCard>
  );
};

export default OffersStandalone;
