import React, {useEffect} from 'react';
import { useParams, useRouteMatch } from 'react-router-dom';
import { Grid, Box } from '@mui/material';
import PageContainer from 'src/components/container/PageContainer';
import usercheck from 'src/services/usercheck';
import ClientNeedDetails from './components/ClientNeedDetails';  // Import your ClientNeedDetails component
import BookingChat from '../freelancer/components/BookingChat';
import BookingOfferAndDeals from '../freelancer/components/BookingOfferAndDeals';
import { useUserContext } from '../../contexts/Contexts';



const Needs = () => {
  const { freelancerData } = useUserContext();
  const { needId } = useParams();
  const { freelancer_id } = useParams();
  
  useEffect(() => {

  }, [freelancerData]);

  if (!freelancerData) {
    return (
      <div>Loading...</div>
    )
  }
  return (
    <PageContainer title="Freelancer Booking requests" description="Manage your bookings">
      <Box>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={7}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <ClientNeedDetails />
              </Grid>
              <Grid item xs={12}>
                <BookingOfferAndDeals offerType={'need'} id={needId}/>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={5}>
            <BookingChat chatType="need" client_need_id={needId} freelancer_id={freelancerData.id}/>
          </Grid>
        </Grid>
      </Box>
    </PageContainer> 

  );
};

export default usercheck(Needs); // Assuming usercheck is some form of user authentication or role validation.
