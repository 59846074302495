import React, { createContext, useState, useContext, useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { getUserByAuth0Id, getFreelancerByUserId, getClientByUserId, addUser, getUserSubscriptions } from "../services/message.service";
import { useNavigate } from 'react-router-dom';

export const UserContext = createContext();
export const FreelancerContext = createContext();
export const SkillsContext = createContext();
export const ClientContext = createContext();
export const Subscription = createContext();

export const useUserContext = () => {
  return useContext(UserContext);
};


/******************************************
 * USER Context, use only this
 * TO USE THIS ADD THIS TO THE COMPONENT THAT NEEDS IT
 * ------ TOP LEVEL COMPONENT ----
 * import { UserProvider } from '/src/contexts/Contexts';
 *     <UserProvider>
 *      <YUR COMPONENT />
 *    </UserProvider>
 * 
 * ---- IN EVERY COMPONENT ----
 * import { useUserContext } from '/src/contexts/UserContext';
 *  IN YOUR FUNCTION
 * const { userData, freelancerData, clientData, isFreelancer, isClient } = useUserContext();
 ******************************************/
export const UserProvider = ({ children }) => {
  const { getAccessTokenSilently, user } = useAuth0();
  const [isFreelancer, setIsFreelancer] = useState(false);
  const [isClient, setIsClient] = useState(false);
  const [userData, setUserData] = useState(null);
  const [freelancerData, setFreelancerData] = useState(null);
  const [clientData, setClientData] = useState(null);
  const [userSubscriptionData, setUserSubscriptionData] = useState(null);
  const [token, setToken] = useState(null);
  const navigate = useNavigate();
  
  useEffect(() => {
    const fetchData = async () => {
      if (!user || !user.sub) return;
  
      try {
        const token = await getAccessTokenSilently();
        setToken(token);
  
        const userDataResponse = await getUserByAuth0Id(user.sub, token);
        if (!userDataResponse.data) {
          const newUserData = {
            firstname: user.given_name,
            lastname: user.family_name,
            email: user.email,
            auth0_id: user.sub,
            profile_picture: user.picture,
            phone_number: user.phone_number,
          };
          const addUserResponse = await addUser(newUserData, token);
          setUserData(addUserResponse.data);
          navigate(`/plaza/profile-setup`);
          return;
        }
  
        setUserData(userDataResponse.data);
        const subscriptionDataResponse = await getUserSubscriptions(userDataResponse.data.id, token);
        setUserSubscriptionData(subscriptionDataResponse.data || null);
  
        const freelancerDataResponse = await getFreelancerByUserId(userDataResponse.data.id, token);
        if (freelancerDataResponse.data) {
          setFreelancerData(freelancerDataResponse.data);
          setIsFreelancer(true);
        }
  
        const clientDataResponse = await getClientByUserId(userDataResponse.data.id, token);
        if (clientDataResponse.data) {
          setClientData(clientDataResponse.data);
          setIsClient(true);
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
        // Handle error state here
      }
    };
  
    fetchData();
  }, [user, getAccessTokenSilently]);
//

  return (
    <UserContext.Provider value={{ 
      isFreelancer, 
      isClient, 
      userData, 
      setUserData,
      freelancerData,
      setFreelancerData, 
      clientData, 
      setClientData,
      setIsFreelancer,
      setIsClient,
      userSubscriptionData,
      setUserSubscriptionData,
      token }}>
      {children}
    </UserContext.Provider>
  );
};