import { Link } from 'react-router-dom';
import Logo from "src/assets/images/logos/bm-logo-smile-letter.png";
import { styled, Box } from '@mui/material';

const LinkStyled = styled(Link)(() => ({
  // height: '100px',
  // width: '100px',
  // overflow: 'hidden',
  // display: 'block',
})); 

const SmileLetterLogo = () => {
  return (
    <LinkStyled to="/">
      <Box 
        marginBottom={2}
        component="img"
        src={Logo}
        alt="SmileLetterLogo"
        sx={{
          height: 50,
          width: 'auto'  // optional based on your requirements
        }}
      />
    </LinkStyled>
  )
};

export default SmileLetterLogo;
