import React from 'react';
import { Modal, Box, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  textAlign: 'center',
};

const SuccessModal = ({ open, handleClose, heading, text }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="success-modal-title"
      aria-describedby="success-modal-description"
    >
      <Box sx={style}>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
        <Typography id="emoji" variant="h1" component="h1" gutterBottom>
          🎉
        </Typography>
        <Typography id="success-modal-title" variant="h4" component="h2" gutterBottom>
          {heading}
        </Typography>
        <Typography id="success-modal-description" variant="body1">
          {text}
        </Typography>
      </Box>
    </Modal>
  );
};

export default SuccessModal;