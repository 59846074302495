import React from 'react';
import { useParams, useRouteMatch } from 'react-router-dom';
import { Grid, Box } from '@mui/material';
import PageContainer from 'src/components/container/PageContainer';
import usercheck from 'src/services/usercheck';
import AllNeeds from './components/AllNeeds';
import ClientNeedDetails from './components/ClientNeedDetails';  // Import your ClientNeedDetails component

const Needs = () => {
  const { needId } = useParams();

  return (
    <PageContainer title="Client Project" description="Manage client needs">
      <Box>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <AllNeeds />
          </Grid>
        </Grid>
      </Box>
    </PageContainer>
  );
};

export default usercheck(Needs); // Assuming usercheck is some form of user authentication or role validation.
