import { Grid, Box, Typography, Button, Stepper, Step, StepLabel, Divider } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import PageContainer from 'src/components/container/PageContainer';
import SupportPerson from 'src/assets/images/varios/SupportPerson.png';


import About from "../freelancer/components/About";
import Skills from "../freelancer/components/Skills";
import Services from '../freelancer/components/Services';
import Experiences from '../freelancer/components/Experiences';
import Educations from '../freelancer/components/Educations';
import Languages from '../freelancer/components/Languages';


import DashboardCard from '../../components/shared/DashboardCard';
import Subscriptions from './components/Subscriptions';
import { ProfileInfo } from './components/ProfileInfo';
import UploadCV from '../freelancer/components/UploadCV';
import Welcome from '../freelancer/components/Welcome';
import { Type } from './components/Type';
import { useUserContext } from 'src/contexts/Contexts';
import { useNavigate } from 'react-router-dom';
import ProfileImage from '../freelancer/components/ProfileImage';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

import confetti from 'canvas-confetti';


const triggerConfetti = () => {
  confetti({
    zIndex: 1000,
    particleCount: 1000,
    spread: 1000,
    origin: { y: 0.6 }
  });
};

const CustomStepIcon = ({ active, completed, icon, isLast }) => {
  return (
    <div
      style={{
        width: '28px',
        height: '28px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50%',
        backgroundColor: completed ? '#22F56E' : active ? '#3C42FF' : '#5A6A85',
        color: 'white',
      }}
    >
      {completed && isLast ? '✔' : completed ? '✔' : icon}
    </div>
  );
};

const ActionButtons = ({ onContinue, onBack }) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 5 }}>
      <Button variant="text" color="primary" onClick={onBack}>
        ← Go Back
      </Button>
      {onContinue && (
        <Button variant="outlined" color="primary" onClick={onContinue}>
          Move on to next step →
        </Button>
      )}
    </Box>
  );
};



const ProfileSetup = () => {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(1);
  const { userData, freelancerData, isFreelancer, isClient, token } = useUserContext();
  const [loading, setLoading] = useState(true);
  const [cvResponse, setCvResponse] = useState(null);
  const handleCvResponse = (response) => {
    setCvResponse(response);
  };

  const handleGoToDashboard = () => {
    navigate('/plaza/dashboard');
  };
  
  const stepsFreelancer = [
    "Choose Account Type",
    "Build your profile",
    "Choose Subscription",
    "Your Information",
    "Done"
  ];

  const stepsClient = [
    "Choose Account Type",
    "Understand the subscription",
    "Company Information",
    "Done"
  ];

  let steps = stepsFreelancer;
  if (isClient) {
    steps = stepsClient;
  }

  const goToNextStep = () => {
    let nextStep = currentStep + 1;
    // Ensure the last step is marked as completed
    if (nextStep > steps.length) {
      nextStep = steps.length;
    }
    setCurrentStep(nextStep);
    window.scrollTo(0, 0);
  
    // If it's the last step, trigger confetti
    if (nextStep === steps.length) {
      triggerConfetti();
    }
  };

  const goToPreviousStep = () => {
    let nextStep = currentStep - 1;
    setCurrentStep(nextStep);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    // addUserMessage();
  }, [userData]); 

  if (!userData) {
    return <div>Loading...</div>;
  }
  
/** 
 * STEP 1 CHOOSE FREELANCER OR CLIENT - Set isFreelancer and isClient + Rows in Database
 * STEP 2A FREELANCER - CHOOSE SUBSCRIPTION
 * STEP 2B CLIENT - N/A
 * STEP 3 COMPANY AND USER INFORMATION (IF FREELANCER WITH PREMIUM or PREMIUM+ ORGNR WILL BE PROVIDED)
 * STEP 4 IF FREELANCER - CREATE PROFILE - SKIP POSSIBILITY IF NO WANT TO PUBLISH YET
 * WHEN LOGGING IN AND ALL ISNT CREATED CONTINUE WHERE IT STOPPED
 */
  return (
    <div style={{
      backgroundColor: 'white',
      padding: '50px',
  }} >
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <Typography variant='h1' align='center' marginBottom={2} marginTop={2}>
              {currentStep === 1 && "What type of profile do you want to register?"}
              
              {currentStep === 2 && isFreelancer && "Lets build your Freelancer Profile 👩‍💻"}
              {currentStep === 2 && isClient && "Understand the subscription"}
              
              {currentStep === 3 && isFreelancer && "Choose your freelancer subscription."}
              {currentStep === 3 && isClient && "Give us some contact information so we know how to get hold of you."}
              
              {currentStep === 4 && isFreelancer && "Give us some contact information."}
              {currentStep === 4 && isClient && "Welcome to Because Mondays 🌟"}

              {currentStep === 5 && isFreelancer && "Welcome to Because Mondays 🌟"}
            </Typography>
          </Grid>
          {currentStep >= 2 && (
          <Grid item xs={12} marginBottom={2}>
            <Stepper activeStep={currentStep - 1} alternativeLabel>
              {steps.map((label, index) => {
                const stepNumber = index + 1;
                const completed = currentStep > stepNumber; // Change this line
                const isLast = index === steps.length - 1; // Check if it's the last step
                return (
                  <Step key={label} completed={completed}>
                    <StepLabel
                      StepIconComponent={(props) => <CustomStepIcon {...props} isLast={isLast} />}
                      icon={stepNumber}
                      onClick={() => completed && setCurrentStep(stepNumber)}
                      style={{ cursor: completed ? 'pointer' : 'default' }}
                    >
                      {label}
                    </StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            {currentStep === 2 && isFreelancer ? (
              <ActionButtons onContinue={goToNextStep} onBack={goToPreviousStep} />     
            ) : (
              <ActionButtons onBack={goToPreviousStep} />     
            )}
          </Grid>
          )}
          <Grid item xs={12}>
            <Grid container spacing={3}>
              {currentStep === 1 && <Type onNext={goToNextStep} />}

              {currentStep === 2 && isFreelancer && (
                <>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={4}>
                      <DashboardCard title="Add Profile Image">
                        <ProfileImage />
                      </DashboardCard>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <>
                        {cvResponse ? (
                          <>
                          <DashboardCard>
                            <About cvData={cvResponse} />
                            <Divider sx={{mt:5, mb:5}}/>
                            <Experiences cvData={cvResponse}/>
                            <Divider sx={{mt:5, mb:5}}/>
                            <Skills cvData={cvResponse}/>
                            <Divider sx={{mt:5, mb:5}}/>
                            <Services />
                            <Divider sx={{mt:5, mb:5}}/>
                            <Educations cvData={cvResponse}/>
                            <Divider sx={{mt:5, mb:5}}/>
                            <Languages cvData={cvResponse} />
                          </DashboardCard>
                          </>
                        ) : (
                          <>
                          <UploadCV onCvAnalyzed={handleCvResponse} />
                          <Box sx={{ my: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Box sx={{
                              bgcolor: 'info.main',
                              px: 2,
                              py: 1,
                              borderRadius: 50,
                              display: 'inline-flex',
                              alignItems: 'center',
                              boxShadow: 1,
                              typography: 'subtitle1',
                              mb: 2,
                              position: 'relative',
                              top: '39px', 
                              zIndex: 1
                            }}>
                             <Typography variant="subtitle2"> Or fill in the details below</Typography>
                            <Box component="span" sx={{ display: 'inline-flex', ml: 1, alignItems: 'center' }}>
                              <ArrowDownwardIcon />
                            </Box>
                          </Box>
                          <Divider sx={{ width: '100%', height: '4px', bgcolor: 'info.main' }} />
                          </Box>
                          <DashboardCard>
                            <About cvData={cvResponse} />
                            <Divider sx={{mt:5, mb:5}}/>
                            <Experiences cvData={cvResponse}/>
                            <Divider sx={{mt:5, mb:5}}/>
                            <Skills cvData={cvResponse}/>
                            <Divider sx={{mt:5, mb:5}}/>
                            <Services />
                            <Divider sx={{mt:5, mb:5}}/>
                            <Educations cvData={cvResponse}/>
                            <Divider sx={{mt:5, mb:5}}/>
                            <Languages cvData={cvResponse} />
                          </DashboardCard>
                          </>
                        )}
                      </>
                    </Grid>
                  </Grid>                  
                </>
              )}
              {currentStep === 2 && isClient && (
                <Subscriptions onNext={goToNextStep} />
              )}
              
              {currentStep === 3 && isFreelancer && (
                <Subscriptions onNext={goToNextStep} />
              )}
              {currentStep === 3 && isClient && (
                <ProfileInfo onNext={goToNextStep} />
              )}
              
              {currentStep === 4 && isFreelancer && (
                <ProfileInfo onNext={goToNextStep} />
              )}
              {currentStep === 4 && isClient && (
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                  <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 3 }}>
                    <Button variant="contained" color="secondary" onClick={handleGoToDashboard}>
                      Go to your dashboard  →
                    </Button>
                  </Box>
                </Grid>
              </Grid>
              )}
              {currentStep === 5 && isFreelancer && 
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Welcome />
                  </Grid>
                </Grid>
              }
            </Grid>
          </Grid>
        </Grid>
    </div>
  );
};
export default ProfileSetup;