import React from 'react';
import { TextField, IconButton, MenuItem, Box } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

const PriceRow = ({ row, index, handlePriceRowChange, removePriceRow, priceTypes }) => {
  return (
    <Box key={row.id || index} sx={{ display: 'flex', alignItems: 'center', gap: 2, mt: 2 }}>
      <TextField
        label="Description"
        value={row.price_description}
        onChange={(e) => handlePriceRowChange(index, 'price_description', e.target.value)}
      />
      <TextField
        select
        label="Type"
        value={row.price_type}
        onChange={(e) => handlePriceRowChange(index, 'price_type', e.target.value)}
      >
        {priceTypes.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        label="Quantity"
        value={row.quantity}
        onChange={(e) => handlePriceRowChange(index, 'quantity', e.target.value)}
        error={!row.quantity || isNaN(row.quantity)} // Added validation check
        sx={{ width: '80px' }} // Set width to make the field shorter
      />
      <TextField
        label="Price"
        value={row.price}
        onChange={(e) => handlePriceRowChange(index, 'price', e.target.value)}
        error={!row.price || isNaN(row.price)} // Added validation check
      />
      
      <IconButton onClick={() => removePriceRow(row.id, index)}>
        <DeleteIcon />
      </IconButton>
    </Box>
  );
};

export default PriceRow;
