import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Modal, Container, CircularProgress, Snackbar, Alert, FormControlLabel, FormControl, InputLabel, MenuItem, Select, Grid, Card, CardContent, Typography, TextField, Button, Checkbox, setRef } from '@mui/material';
import DashboardCard from 'src/components/shared/DashboardCard';
import { getOfferByUId, updateOfferUserDetails, createDeal } from 'src/services/message.service';
import LoadingModal from 'src/components/shared/LoadingModal';

const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(String(email).toLowerCase());

const euCountries = [
  { label: 'Austria', value: 'Austria' },
  { label: 'Belgium', value: 'Belgium' },
  { label: 'Bulgaria', value: 'Bulgaria' },
  { label: 'Croatia', value: 'Croatia' },
  { label: 'Republic of Cyprus', value: 'Republic of Cyprus' },
  { label: 'Czech Republic', value: 'Czech Republic' },
  { label: 'Denmark', value: 'Denmark' },
  { label: 'Estonia', value: 'Estonia' },
  { label: 'Finland', value: 'Finland' },
  { label: 'France', value: 'France' },
  { label: 'Germany', value: 'Germany' },
  { label: 'Greece', value: 'Greece' },
  { label: 'Hungary', value: 'Hungary' },
  { label: 'Iceland', value: 'Iceland' }, 
  { label: 'Ireland', value: 'Ireland' },
  { label: 'Italy', value: 'Italy' },
  { label: 'Latvia', value: 'Latvia' },
  { label: 'Lithuania', value: 'Lithuania' },
  { label: 'Luxembourg', value: 'Luxembourg' },
  { label: 'Malta', value: 'Malta' },
  { label: 'Netherlands', value: 'Netherlands' },
  { label: 'Norway', value: 'Norway' },  
  { label: 'Poland', value: 'Poland' },
  { label: 'Portugal', value: 'Portugal' },
  { label: 'Romania', value: 'Romania' },
  { label: 'Slovakia', value: 'Slovakia' },
  { label: 'Slovenia', value: 'Slovenia' },
  { label: 'Spain', value: 'Spain' },
  { label: 'Sweden', value: 'Sweden' },
  { label: 'United Kingdom', value: 'United Kingdom' }, 
];

const AcceptOffer = () => {

  const { unique_identifier } = useParams();
  const [freelancerData, setFreelancerData] = useState({});
  const [offerData, setOfferData] = useState({});
  const [priceData, setPriceData] = useState({});
  const [dealData, setDealData] = useState({});
  const [discountsData, setDiscountsData] = useState({});
  const [refreshTrigger, setRefreshTrigger] = useState(1);
  const [clientUserData, setClientUserData] = useState({
    firstname: '',
    lastname: '',
    email: '',
    phone_country_code: '+46',
    phone_number: '',
    acceptGDPR: false,
    social_security_nr: '',
    company_name: '',
    vat_nr: '',
    address_1: '',
    address_2: '',
    zipcode: '',
    city: '',
    country: 'Sweden',
    invoice_email: ''
  });

  const [formErrors, setFormErrors] = useState({
    email: '',
    invoice_email: '',
    phone_number: '',
    gdprConsent: '',
  });

  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');
  const [isSavingClientInformation, setIsSavingClientInformation] = useState(false);
  const [isSavingOffer, setIsSavingOffer] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('info');
  const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await getOfferByUId(unique_identifier);
        if (response && response.data) {
          setOfferData(response.data.offerData);
          setPriceData(response.data.priceData);
          setFreelancerData(response.data.freelancerData);
          setClientUserData(response.data.clientUserData);
          setDealData(response.data.dealData);
          setDiscountsData(response.data.discountsData);
          setSuccess('Data loaded successfully');
        }
      } catch (error) {
        setError('Failed to load data');
        console.error('Error fetching offer data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [unique_identifier, refreshTrigger]);

    // Function to calculate total budget
    const calculateTotalBudget = () => {
      return priceData.reduce((total, priceItem) => {
        const price = parseFloat(priceItem.price) || 0;
        const quantity = parseFloat(priceItem.quantity) || 0;
        return total + (price * quantity);
      }, 0);
    };
  

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setClientUserData({ ...clientUserData, [name]: value });
  
    if (name === 'invoice_email' && !validateEmail(value)) {
      setFormErrors({ ...formErrors, invoice_email: 'Invalid invoice email address' });
    } else {
      setFormErrors({ ...formErrors, invoice_email: '' });
    }

    // Check for phone number validation
    if (name === 'phone_number' || name === 'phone_country_code') {
      const fullPhoneNumber = `${clientUserData.phone_country_code}${value}`;
      if (!isValidNumber(fullPhoneNumber)) {
        setFormErrors({ ...formErrors, phone_number: 'Invalid phone number' });
      } else {
        setFormErrors({ ...formErrors, phone_number: '' });
      }
    }
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setClientUserData({ ...clientUserData, [name]: checked });
  };

  const validateForm = () => {
    let isValid = true;
    let newFormErrors = { ...formErrors };
  
    // Check if GDPR consent is accepted
    if (!clientUserData.acceptGDPR) {
      newFormErrors = { ...newFormErrors, gdprConsent: 'You must consent to the privacy policy to proceed.' };
      isValid = false;
    }

    setFormErrors(newFormErrors);
    return isValid;
  };

  function formatTextWithLineBreaks(text) {
    if (text) {
      return text.split('\n').map((line, index) => (
        <React.Fragment key={index}>
          {line}
          {index !== text.split('\n').length - 1 && <br />}
        </React.Fragment>
      ));
    } else {
      return "";
    }
  }

  const handleSaveClientInformation = async () => {
    if (!validateForm()) {
      return;
    }
    try {
      setIsSavingClientInformation(true);
      await updateOfferUserDetails(clientUserData.id, clientUserData);
      setRefreshTrigger(refreshTrigger+1);
      setSnackbarMessage('Information saved successfully');
      setSnackbarSeverity('success');
    } catch (error) {
      console.error('Error saving user details:', error);
      setSnackbarMessage('Failed to save information');
      setSnackbarSeverity('error');
    } finally {
      setIsSavingClientInformation(false);
      setSnackbarOpen(true);
      toggleForm();
    }
  };


  const handleAcceptOffer = async () => {
    try {
      setIsSavingOffer(true);
      const newDealData=({
        offerId: offerData.id,
        agreementText: '',
        status: 'new',
        start_date: offerData.start_date,
        end_date: offerData.end_date,
      });
      await createDeal(newDealData);
      setRefreshTrigger(refreshTrigger+1);
      setSnackbarMessage('You accepted this offer!');
      setSnackbarSeverity('success');
    } catch (error) {
      console.error('Error saving offer:', error);
      setSnackbarMessage('Failed to accept offer. Please try later');
      setSnackbarSeverity('error');
    } finally {
      setIsSavingOffer(false);
      setSnackbarOpen(true);
      toggleForm();
    }
  };


  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const toggleForm = () => {
    setShowForm(!showForm);
  };

  if (loading) {
    return <LoadingModal />
  }

  return (
    <div>
      
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <DashboardCard title="Client information">
              {/* Client Information Section */}
              <Typography variant="subtitle1" marginBottom={3}>View or update your company information</Typography>
              {showForm ? (
                <>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Typography variant='body2'>Your Email</Typography>
                      <Typography variant='h5'>{clientUserData ? (clientUserData.email || '') : ''}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        label="Your First Name"
                        name="firstname"
                        value={clientUserData ? (clientUserData.firstname || '') : ''}
                        onChange={handleInputChange}
                        required
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        label="Your Last Name"
                        name="lastname"
                        value={clientUserData ? (clientUserData.lastname || '') : ''}
                        onChange={handleInputChange}
                        required
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        label="Company Name"
                        name="company_name"
                        value={clientUserData ? (clientUserData.company_name || '') : ''}
                        onChange={handleInputChange}
                        required
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        label="VAT Number"
                        name="vat_nr"
                        value={clientUserData ? (clientUserData.vat_nr || '') : ''}
                        onChange={handleInputChange}
                        required
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        label="Address 1"
                        name="address_1"
                        value={clientUserData ? (clientUserData.address_1 || '') : ''}
                        onChange={handleInputChange}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        label="Address 2"
                        name="address_2"
                        value={clientUserData ? (clientUserData.address_2 || '') : ''}
                        onChange={handleInputChange}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        fullWidth
                        label="Zip Code"
                        name="zipcode"
                        value={clientUserData ? (clientUserData.zipcode || '') : ''}
                        onChange={handleInputChange}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        fullWidth
                        label="City"
                        name="city"
                        value={clientUserData ? (clientUserData.city || '') : ''}
                        onChange={handleInputChange}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <InputLabel id="country-label">Country</InputLabel>
                        <Select
                          labelId="country-label"
                          id="country-select"
                          
                          value={clientUserData ? (clientUserData.country || '') : ''}
                          name="country"
                          onChange={handleInputChange}
                        >
                          {euCountries.map((country, index) => (
                            <MenuItem key={index} value={country.value}>
                              {country.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        label="Invoice Email *"
                        name="invoice_email"
                        value={clientUserData ? (clientUserData.invoice_email || '') : ''}
                        onChange={handleInputChange}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container spacing={3} marginTop={1}>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={<Checkbox checked={clientUserData.acceptGDPR} onChange={handleCheckboxChange} name="acceptGDPR" />}
                    label={
                      <React.Fragment>
                        By checking this box, I acknowledge that I have read and understood the <a href="/terms-of-use" target="_blank" rel="noopener noreferrer">Terms of Use</a> and <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>. 
                        I consent to the collection, storage, and use of my personal data as described therein, including the transfer of this data within and outside the European Union in accordance with the GDPR regulations.
                      </React.Fragment>
                    }
                  />
                  {formErrors.gdprConsent && <Typography color="error">{formErrors.gdprConsent}</Typography>}
                  <Grid container alignItems="flex-end" justifyContent="flex-end" marginTop={3}>
                    <Button 
                      variant="contained" 
                      color="primary" 
                      onClick={handleSaveClientInformation}
                      disabled={isSavingClientInformation}
                    >
                      {isSavingClientInformation ? <CircularProgress size={24} /> : 'Save My Information'}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              </>
              ) : (
                <>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Typography variant='body2'>Your Email</Typography>
                      <Typography variant='h5'>{clientUserData ? (clientUserData.email || '') : ''}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant='body2'>Your First Name</Typography>
                      <Typography variant='h5'>{clientUserData ? (clientUserData.firstname || '') : ''}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant='body2'>Your Last Name</Typography>
                      <Typography variant='h5'>{clientUserData ? (clientUserData.lastname || '') : ''}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant='body2'>Company Name</Typography>
                      <Typography variant='h5'>{clientUserData ? (clientUserData.company_name || '') : ''}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant='body2'>VAT Number</Typography>
                      <Typography variant='h5'>{clientUserData ? (clientUserData.vat_nr || '') : ''}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant='body2'>Address 1</Typography>
                      <Typography variant='h5'>{clientUserData ? (clientUserData.address_1 || '') : ''}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant='body2'>Address 2</Typography>
                      <Typography variant='h5'>{clientUserData ? (clientUserData.address_2 || '') : ''}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography variant='body2'>Zip Code</Typography>
                      <Typography variant='h5'>{clientUserData ? (clientUserData.zipcode || '') : ''}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant='body2'>City</Typography>
                      <Typography variant='h5'>{clientUserData ? (clientUserData.city || '') : ''}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant='body2'>Country</Typography>
                      <Typography variant='h5'>{clientUserData ? (clientUserData.country || '') : ''}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant='body2'>Invoice Email</Typography>
                      <Typography variant='h5'>{clientUserData ? (clientUserData.invoice_email || '') : ''}</Typography>
                    </Grid>
                  </Grid>

                <Grid container alignItems="flex-end" justifyContent="flex-end" marginTop={3}>
                  <Button 
                    variant="outlined" 
                    color="primary" 
                    onClick={toggleForm}
                    style={{ marginBottom: '20px', }}
                  >
                    {showForm ? 'Hide Form' : 'Update My Information'}
                  </Button>
                </Grid>
                </>
              )}
            </DashboardCard>
          </Grid>
          <Grid item xs={12} md={6}>
            <DashboardCard title="Freelancer Information">
              <Typography variant='subtitle1' marginBottom={3}>
                Freelancer: {freelancerData.firstname}`s information
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <Typography variant="body1" marginBottom={3} overflow="hidden" display="-webkit-box" style={{ WebkitBoxOrient: 'vertical', WebkitLineClamp: 13 }}>
                    {formatTextWithLineBreaks(freelancerData.about_text)}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <img src={freelancerData.profile_image} alt={`${freelancerData.firstname}'s profile`} style={{ width: '100%' }} />
                </Grid>
              </Grid>
              <Button 
                variant="text" 
                color="primary" 
                style={{ whiteSpace: 'nowrap', padding: '0', margin: "12px 0" }}
                component={Link} 
                to={`/freelancer/${freelancerData.id}`}>
                Read more about {freelancerData.firstname}
              </Button>
            </DashboardCard>
          </Grid>
          <Grid item xs={12}>
            <Container maxWidth="md">
              <DashboardCard title="Offer Details">
                <Typography variant="body1" marginBottom={3}>{formatTextWithLineBreaks(offerData.message)}</Typography>
                <Typography variant="body1" marginBottom={0}><strong>Start Date: </strong> {offerData.start_date.split('T')[0]}</Typography>
                <Typography variant="body1" marginBottom={3}><strong>End Date: </strong> {offerData.end_date.split('T')[0]}</Typography>
                  <Grid container alignItems="flex-end" spacing={2} marginTop={1}>
                    <Grid item xs={6}>
                      <Typography variant="h5">Prices:</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography variant="h5">Type:</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography variant="h5" align="center">Quantity:</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography variant="h5" align="right">Price:</Typography>
                    </Grid>
                  </Grid>
                {priceData && priceData.map((price, index) => (
                  <Grid container alignItems="flex-end" spacing={2} key={index} marginTop={1}>
                    <Grid item xs={6}>
                      <Typography variant="body1">{`${price.price_description}`}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography variant="body1">{`${price.price_type}`}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography variant="body1" align="center">{`${price.quantity}`}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography variant="body1" align="right">{`${price.price} ${price.currency}`}</Typography>
                    </Grid>
                  </Grid>
                ))}
                {/* Accept Offer Button */}
                <Grid container direction="column" alignItems="flex-end" marginTop={2}>
                  <Grid item>
                    <Typography variant="h4" sx={{ mt: 3, mb: 2 }}>
                      Total Budget: {calculateTotalBudget().toFixed(0)} SEK
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body1" marginBottom={1}>Service Fee: 5%</Typography>
                  </Grid>
                  {discountsData && discountsData.map((discount, index) => (
                    discount.discount_type === 'CLIENT' && (
                      <Grid item key={index}>
                        <Typography variant="body1" marginBottom={1}>
                          {`Service Fee Discount: ${parseFloat(discount.discount_percentage).toFixed(0)}%`}
                        </Typography>
                        <Typography variant="body1" align="right">
                          <strong>Your Fee: {`${5 + parseFloat(discount.discount_percentage)}%`}</strong>
                        </Typography>
                      </Grid>
                    )
                  ))}
                  <Grid item marginTop={3}>
                  {!dealData ? (
                    <Button 
                      variant="contained" 
                      color="primary"
                      onClick={handleAcceptOffer}
                      disabled={isSavingOffer}
                      >
                        {isSavingOffer ? <CircularProgress size={24} /> : 'Accept Offer'}
                    </Button>
                  ) : (
                    <Typography variant='h4' color="green">
                      This offer is accepted
                    </Typography>
                  )}
                  </Grid>
                  <Grid item>
                    <Typography variant="body2" marginTop={2} align="right">
                      When accepting this offer, a contract is sent within 24 hours to your email for digital signing.
                      If you want to discuss the offer with the freelancer, please contact them.
                    </Typography>
                  </Grid>
                </Grid>
              </DashboardCard>
            </Container>
          </Grid>
      </Grid>
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default AcceptOffer;
