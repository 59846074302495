import { Modal, CircularProgress } from '@mui/material';

const LoadingModal = () => {
  return (
    <Modal
    open={true}
    aria-labelledby="loading-modal-title"
    aria-describedby="loading-modal-description"
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
    >
    <div style={{ outline: 'none' }}>
      <CircularProgress />
    </div>
    </Modal>
  );
};
export default LoadingModal;