const typography = {
  fontFamily: "'Josefin Sans', sans-serif;",
  caption: {
    fontWeight: 600,
    fontSize: '10rem',
  },
  h1: {
    fontWeight: 600,
    fontSize: '3.0rem',
    lineHeight: '3.5rem',
    fontFamily: "'Josefin Sans', sans-serif;",
  },
  h2: {
    fontWeight: 600,
    fontSize: '2.2rem',
    lineHeight: '3rem',
    fontFamily: "'Josefin Sans', sans-serif;",
  },
  h3: {
    fontWeight: 600,
    fontSize: '1.6rem',
    lineHeight: '1.75rem',
    fontFamily: "'Josefin Sans', sans-serif;",
  },
  h4: {
    fontWeight: 600,
    fontSize: '1.4rem',
    lineHeight: '1.6rem',
  },
  h5: {
    fontWeight: 600,
    fontSize: '1.2rem',
    lineHeight: '1.6rem',
  },
  h6: {
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: '1.2rem',
  },
  h7: {
    fontWeight: 500,
    fontSize: '0.8rem',
    lineHeight: '1.2rem',
  },

  button: {
    textTransform: 'capitalize',
    fontWeight: 400,
  },
  body1: {
    fontSize: '0.875rem',
    fontWeight: 400,
    lineHeight: '1.334rem',
  },
  body2: {
    fontSize: '0.75rem',
    letterSpacing: '0rem',
    fontWeight: 400,
    lineHeight: '1rem',
  },
  subtitle1: {
    fontSize: '1.2rem',
    fontWeight: 400,
  },
  subtitle2: {
    fontSize: '0.875rem',
    fontWeight: 400,
  },
  subtitle3: {
    fontSize: '0.875rem',
    fontWeight: 600,
  },

};

export default typography;
