import React, { useState, useEffect } from 'react';
import { Button, TextField, Grid, Typography, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import SendIcon from '@mui/icons-material/Send';

const colorSchemes = {
  default: {
    inputTextColor: '#000000',
    inputLabelColor: 'grey.600',
    backgroundColor: 'white',
    borderColor: 'white'
  },
  dark: {
    inputTextColor: 'grey.600',
    inputLabelColor: 'grey.300',
    backgroundColor: '#ffffff',
    borderColor: 'grey.600'
  },
};

const SearchBar = ({ colorScheme = 'default' }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();
  const colors = colorSchemes[colorScheme];

  const handleSearchSubmit = (e) => {
    e.preventDefault(); 
    navigate(`/freelancers/${searchTerm}`); 
  };

  return (
    <form onSubmit={handleSearchSubmit}>
      <TextField
        // autoFocus
        variant="outlined"
        fullWidth
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        placeholder="What do you need help with"
        InputProps={{
          endAdornment: (
            <Button type="submit" color="primary" variant='contained'>
              Search
            </Button>
          ),
          style: { color: colors.inputTextColor },
        }}
        InputLabelProps={{
          style: { color: colors.inputLabelColor },
        }}
        sx={{
          '& .MuiOutlinedInput-root': {
            backgroundColor: colors.backgroundColor,
            '& fieldset': {
              borderColor: colors.borderColor,
            },
            '&:hover fieldset': {
              borderColor: colors.borderColor,
            },
            '&.Mui-focused fieldset': {
              borderColor: colors.borderColor,
            },
            // width: { sm: '400px', md: '550px' }
          },
        }}
      />
    </form>
  );

}

export default SearchBar;