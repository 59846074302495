import { Grid, Box, Typography, Button, CardMedia } from '@mui/material';
import React, { useState, useEffect } from 'react';
import PageContainer from 'src/components/container/PageContainer';
import Subscriptions from './components/Subscriptions';
import PremiumPlusOnboarding from './components/PremiumPlusOnboarding';
import { useUserContext } from 'src/contexts/Contexts';


const Subscription = () => {
  const { userData, token, userSubscriptionData } = useUserContext();
  const [currentStep, setCurrentStep] = useState(1);
  const goToNextStep = () => {
    // TODO FIX THIS MAKES A QUICK FIX
    if (currentStep === 2) {
      setCurrentStep(1);
    }
    
    setCurrentStep(prevStep => prevStep + 1);
  };

  return (
    <PageContainer title="Dashboard" description="this is Dashboard">
        <Box>
          <Grid container spacing={3}>
            <Grid item xs={8} sm={12}>
              <Typography variant='h2' align='center' marginBottom={2} marginTop={2}>
                  Your subscription
              </Typography>
              <Typography variant='subtitle1' align='center' marginBottom={2}>
                {currentStep === 1 && "So cool that you are part of Because Mondays. If you want to change your subscription, you can do that here."}
                {currentStep === 2 && "Your subscription is changed!"}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                {currentStep === 1 && <Subscriptions onNext={goToNextStep} />} 
                {currentStep === 2 && <Subscriptions onNext={goToNextStep} />}
              </Grid>
            </Grid>
            {userSubscriptionData.subscription_id === 3 && (
            <Grid item xs={12}>
                <PremiumPlusOnboarding />
            </Grid>
            )}
          </Grid>
        </Box>
      </PageContainer>
  );  
};
export default Subscription;