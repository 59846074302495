import React from 'react';
import { Button, Typography, Grid } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import DashboardCard from 'src/components/shared/DashboardCard';


const LoginCreateAccount = () => {

  const { loginWithRedirect } = useAuth0();
  const { isAuthenticated } = useAuth0();

  const handleLogin = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: window.location.pathname,
      },
    });
  };
  const handleSignUp = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: "/plaza/profile-setup",
      },
      authorizationParams: {
        screen_hint: "signup",
      },
    });
  };

  return (
    <>
      {!isAuthenticated && (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <DashboardCard title="Log in">
              <Typography variant="body2">
                To use the full experience, and to make offers, please create an account and choose Freelancer. 
                If you want to publish a project, please create an account and choose Client.
              </Typography>
              <Typography> &nbsp; </Typography>
              <Button sx={{ whiteSpace: 'nowrap' }} variant="contained" color="secondary"  target="_blank" onClick={handleSignUp}>
                Create Account
              </Button>
              <Button sx={{ whiteSpace: 'nowrap' }} variant="text" color="primary"  target="_blank" onClick={handleLogin}>
                Sign in
              </Button>
            </DashboardCard>
          </Grid>
        </Grid>
      )}
  </>
  );
};

export default LoginCreateAccount;
