import React, { useState, useEffect } from 'react';
import { Divider, Select, MenuItem, Typography, Grid, TextField, Button, List, ListItem, ListItemText, ListItemSecondaryAction, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { createFreelancerService, updateFreelancerService, getFreelancerServicesByFreelancerId, deleteFreelancerService } from "../../../services/message.service";
import DashboardCard from '../../../components/shared/DashboardCard';
import { useUserContext } from '../../../contexts/Contexts';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CircularProgress from '@mui/material/CircularProgress';

const CreateService = () => {
    const { freelancerData, setFreelancerData, token } = useUserContext();
    const [services, setServices] = useState([]);
    const [selectedService, setSelectedService] = useState(null);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [serviceToDelete, setServiceToDelete] = useState(null);
    const [isSaved, setIsSaved] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [serviceData, setServiceData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const fetchServices = async () => {
            if (freelancerData) {
                const allServices = await getFreelancerServicesByFreelancerId(freelancerData.id, token);
                setServices(allServices.data);
                setServiceData({
                    service_name: '',
                    service_description: '',
                    service_details: '',
                    service_price: null,
                    freelancer_id: freelancerData.id,
                    currency: 'SEK',
                    pricing_type: 'HOURLY',
                });
            }
        };
        fetchServices();
        if (isSaved) {
          setTimeout(() => {
              setIsSaved(false);
          }, 2000);
      }
  }, [isSaved, token]);

    const handleServiceChange = (e) => {
        const { name, value } = e.target;
        setServiceData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async () => {
        setIsLoading(true);
        try {
            if (selectedService) {
                await updateFreelancerService(selectedService.id, serviceData, token);
            } else {
                await createFreelancerService(serviceData, token);
            }
            const allServices = await getFreelancerServicesByFreelancerId(freelancerData.id, token);
            setServices(allServices.data);
            setIsSaved(true);
            setShowForm(false); 
            setSelectedService(null); // Reset selected service
        } catch (error) {
            console.error('Error updating or creating service:', error);
        }        
        setIsLoading(false); // Start loading

        setServiceData({
            service_name: '',
            service_description: '',
            service_details: '',
            service_price: null,
            freelancer_id: freelancerData.id,
            currency: 'SEK',
            pricing_type: 'HOURLY',
        });
    };

    const handleEditService = (service) => {
        setSelectedService(service);
        setServiceData(service);
        setShowForm(true);
    };
    
    const handleCancel = () => {
        setServiceData({
            service_name: '',
            service_description: '',
            service_details: '',
            service_price: null,
            freelancer_id: freelancerData.id,
            currency: 'SEK',
            pricing_type: 'HOURLY',
        });
        setSelectedService(null);
        setShowForm(false);
    };

    const openDeleteModal = (serviceId) => {
      setServiceToDelete(serviceId);
      setOpenDeleteDialog(true);
    };

    const closeDeleteModal = () => {
        setServiceToDelete(null);
        setOpenDeleteDialog(false);
    };

    const confirmDelete = async () => {
        if (serviceToDelete) {
            handleDeleteService(serviceToDelete);
            closeDeleteModal();
        }
    };

    const handleDeleteService = async (serviceId) => {
        try {
            await deleteFreelancerService(serviceId, token);
            const updatedServices = services.filter(service => service.id !== serviceId);
            setServices(updatedServices);
        } catch (error) {
            console.error('Error deleting service:', error);
        }
        setSelectedService(null);
        setServiceData({
            service_name: '',
            service_description: '',
            service_details: '',
            service_price: null,
            freelancer_id: freelancerData.id,
            currency: 'SEK',
            pricing_type: 'HOURLY',
        });

    };

    return (
        <>
            <Typography variant="h4">
                My Services
            </Typography>
            <Typography variant="body2" marginTop={1}>
                Optionally add services for better matching and searchability.
            </Typography>

            <Grid container spacing={3} marginTop={0}>
              <Grid item xs={12}>
                <List sx={{ padding: 0, '& .MuiListItem-root': { padding: 0 } }}>
                    {
                        services && services.length > 0 ? (
                            services.map(service => (
                            <>
                              <ListItem 
                                key={service.id}
                                sx={{ 
                                    '&:hover': {
                                      backgroundColor: 'grey.100',
                                    },
                                  }}>
                                <ListItemText
                                    primary={service.service_name}
                                    secondary={service.service_description} 
                                    sx={{ mr: 30 }} // Margin right to push away from the actions
                                />
                                <ListItemSecondaryAction>
                                    {service.service_price} {service.currency} {service.pricing_type} 
                                    <IconButton edge="end" onClick={() => handleEditService(service)}>
                                        <EditIcon />
                                    </IconButton>
                                    <IconButton edge="end" onClick={() => openDeleteModal(service.id)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                            <Divider />
                            </>
                            
                          
                            ))
                        ) : (
                            <Typography variant="body1">No services found.</Typography>
                        )
                    }
                </List>
                {!showForm && (
                <Button onClick={() => setShowForm(!showForm)} variant="contained" sx={{mt:3}}>
                  Add New Service +
                </Button>
                )}
                </Grid>
                { showForm && (
                    <>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Name"
                            name="service_name"
                            value={serviceData.service_name}
                            onChange={handleServiceChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Short Description"
                            name="service_description"
                            value={serviceData.service_description}
                            onChange={handleServiceChange}
                            multiline
                            rows={4}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Details"
                            name="service_details"
                            value={serviceData.service_details}
                            onChange={handleServiceChange}
                            multiline
                            rows={10}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField 
                            label="Price" 
                            name="service_price" 
                            value={serviceData.service_price} 
                            onChange={handleServiceChange} 
                            fullWidth 
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Select
                            value={serviceData.currency}
                            onChange={handleServiceChange}
                            name="currency"
                            fullWidth
                        >
                            <MenuItem value="SEK">SEK</MenuItem>
                            <MenuItem value="EUR">EUR</MenuItem>
                        </Select>
                    </Grid>
                    <Grid item xs={3}>
                        <Select
                            value={serviceData.pricing_type}
                            onChange={handleServiceChange}
                            name="pricing_type"
                            fullWidth
                        >
                            <MenuItem value="HOURLY">Hourly</MenuItem>
                            <MenuItem value="FIXED">Fixed</MenuItem>
                        </Select>
                    </Grid>

                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            color={isSaved ? "secondary" : "primary"}
                            onClick={handleSubmit}
                            disabled={isLoading} // Disable the button when loading
                        >
                            {isLoading ? (
                                <CircularProgress size={24} color="inherit" />
                            ) : isSaved ? (
                                "Saved ✔"
                            ) : (
                                "Save"
                            )}
                        </Button>
                        &nbsp;
                        <Button
                            color="primary"
                            onClick={handleCancel}
                            disabled={isLoading} // Optionally disable cancel when loading
                        >
                            Cancel
                        </Button>
                    </Grid>

                </>
                )}
            </Grid>
            <Dialog
                open={openDeleteDialog}
                onClose={closeDeleteModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Delete Service"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete this service?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDeleteModal} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={confirmDelete} color="primary" autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default CreateService;
