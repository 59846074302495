import { Typography, Tooltip, Badge } from '@mui/material';

const PricingComponent = ({ price, currency, pricingType }) => {
  const isHourly = pricingType === 'HOURLY';

  return (
      <>
      <Badge
        // badgeContent={
        //   isHourly ? <HourglassEmptyIcon fontSize="small" /> : <LocalOfferIcon fontSize="small" />
        // }
        color="primary"
        overlap="circular"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        // variant="dot"
      >
        <Typography 
          variant="body1" 
          component="span"
          sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              fontWeight: 'bold',
              backgroundColor: 'primary.light',
              padding: '10px 10px',
              borderRadius: '15px',
              boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.1)'
          }}
      >
          {price} {currency}
          <Typography marginTop={1} variant='body2'>{isHourly ? 'Billed per hour' : 'Fixed price'}</Typography>
      </Typography>

      </Badge>
      
      </>
  );
}

export default PricingComponent;
