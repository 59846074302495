import React, { useState, useEffect } from 'react';
import { Grid, Typography, useMediaQuery } from '@mui/material';
import Person0 from 'src/assets/images/hero/person0.png';
import Person1 from 'src/assets/images/hero/person1.png';
import Person2 from 'src/assets/images/hero/person2.png';
import Person3 from 'src/assets/images/hero/person3.png';
import Person4 from 'src/assets/images/hero/person4.png';
import Person5 from 'src/assets/images/hero/person5.png';
import Person6 from 'src/assets/images/hero/person6.png';
import Person7 from 'src/assets/images/hero/person7.png';
import Person8 from 'src/assets/images/hero/person8.png';
import SearchBar from './SearchBar';

const Hero = () => {
  const [currentImage, setCurrentImage] = useState(0);
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('lg'));
  const [fadeOut, setFadeOut] = useState(false);
  // List of images, you can replace these with your image URLs
  const images = [
    // Person0,
    Person1,
    // Person2,
    Person3,
    // Person4,
    Person5,
    // Person6,
    Person7,
    Person8,
  ];

 


  useEffect(() => {
    const interval = setInterval(() => {
      setFadeOut(true);
      setTimeout(() => {
        let nextImage;
        do {
          nextImage = Math.floor(Math.random() * images.length);
        } while (nextImage === currentImage);
        setCurrentImage(nextImage);
        setFadeOut(false);
      }, 1000);  
    }, 8000);
    return () => clearInterval(interval);
  }, [currentImage]); // Added currentImage to the dependency array
    
  return (

    <Grid container spacing={3} alignItems="center" justifyContent="center">
      {/* Left Side: Text and Search Bar */}
      <Grid item xs={12} lg={6} container  alignItems="flex-start" spacing={3} marginBottom={15} marginTop={10}>
        <Grid item>
          <Typography variant="h1" component="div" color={'white'}>
            Match your challenge <br /> with a unique freelancer
          </Typography>
        </Grid>
        <Grid item xs={11} lg={12}>
          <SearchBar />
        </Grid>
      </Grid>
      {/* Right Side: Image */}
      {!isSmall && (
        <Grid item lg={6}>
          <img 
            src={images[currentImage]} 
            alt="Freelancer" 
            style={{ 
              width: '120%',
              borderRadius: '10px',
              opacity: fadeOut ? 0 : 1,
              transition: 'opacity 1s'
            }}
          />
        </Grid>
      )}
    </Grid>

  );
}

export default Hero;
