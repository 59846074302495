import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box,
  TextField,
  Button,
  Typography,
  Grid,
  Paper,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import DashboardCard from '../../../components/shared/DashboardCard';
import Avatar from '@mui/material/Avatar';
import {createBookingMessage, getBookingById, getBookingMessages, getClientNeedById, getBookingMessagesByClientNeedAndFreelancerId} from "../../../services/message.service";
import { useUserContext } from '../../../contexts/Contexts';
import sanitizeHtml from 'sanitize-html';
import { ConstructionOutlined } from '@mui/icons-material';


const BookingChat = ({ chatType, booking_request_id, client_need_id, freelancer_id }) => {
  const { userData, freelancerData, token } = useUserContext();
  const [input, setInput] = React.useState("");
  const [messages, setMessages] = React.useState([]);
  const [bookingRequest, setBookingRequest] = React.useState([]);
  const [clientNeed, setClientNeed] = React.useState([]);
  const chatRef = useRef(null);


  useEffect(() => {
    const fetchData = async () => {
      if (userData) {
        try {
          let fetchedMessages;
          let initialMessage;
          if (chatType && chatType === 'booking') {
            const bookingRequestData = await getBookingById(booking_request_id, token);
            setBookingRequest(bookingRequestData);

            initialMessage = {
              id: 0,
              text: `<b>Booking Title: ${bookingRequestData.data.title}</b><br/><br/> <b>Description:</b> ${bookingRequestData.data.project_description}`,
              sender_id: bookingRequestData.data.user_id,
            };

            fetchedMessages = await getBookingMessages(booking_request_id, token);
            if (!fetchedMessages) {
              setMessages([initialMessage]);
            } else {
              setMessages([initialMessage, ...fetchedMessages.data]);
            }

          } else if (chatType && chatType === 'need') {
            const clientNeedData = await getClientNeedById(client_need_id, token);
            setClientNeed(clientNeedData);
           
            initialMessage = {
              id: 0,
              text: ``, // FIRST MESSAGE IS AN EMPTY MESSAGE
              sender_id: freelancer_id, //ALWAYS FREELANCER WHO CREATES THE FIRST MESSAGE
            }
            fetchedMessages = await getBookingMessagesByClientNeedAndFreelancerId(client_need_id, freelancer_id, token);
            setMessages([...fetchedMessages.data]);
          }
        } catch (error) {
          console.error('Error fetching bookings or messages:', error);
        }
      }
    };

    fetchData();
  }, [userData]);

  useEffect(() => {
    if (chatRef.current) {
      chatRef.current.scrollTop = chatRef.current.scrollHeight;
    }
  }, [messages]);
  

  const handleSend = async () => {
    if (input.trim() !== "") {
      try {
        const messageData = {
          text: input,
          booking_request_id: booking_request_id,
          sender_id: userData.id,
          sender_type: freelancerData ? "freelancer" : "client", 
          client_need_id: client_need_id,
          freelancer_id: freelancerData ? freelancerData.id : freelancer_id,
          client_id: (chatType && chatType === 'booking' ? bookingRequest.data.client_id : clientNeed.data.client_id),
        };
  
        const newMessage = await createBookingMessage(messageData, token);
        
        // Assuming newMessage contains the newly created message
        // Update state to reflect the new message
        setMessages((prevMessages) => [...prevMessages, messageData]);
        
        // Clear the input
        setInput("");
      } catch (error) {
        console.error('Failed to send message:', error);
      }
    }
  };
  
  const handleInputChange = (event) => {
    setInput(event.target.value);
  };

  return (
    <DashboardCard title="Messages about this booking">
      <Box sx={{ height: "calc(100vh - 220px)", display: "flex", flexDirection: "column" }}>
        <Box ref={chatRef} sx={{ flexGrow: 1, overflow: "auto", p: 0 }}>
          {messages.map((message) => (
            <Message key={message.id} message={message} userData={userData} />
          ))}
        </Box>
        <Box sx={{ position: 'sticky', bottom: 0, p: 0, backgroundColor: "" }}>
          <Grid container spacing={0} alignItems="flex-end">
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Send a message"
                value={input}
                multiline
                rows={3}
                onChange={handleInputChange}
                InputProps={{ 
                  style: { paddingRight: '50px' },
                  endAdornment: (
                    <Button
                      size="small"
                      variant=""
                      style={{ position: 'absolute', right: 0}} // Position the button
                      onClick={handleSend}
                    >
                      <SendIcon color="primary"/>
                    </Button>
                  )
                }}
        
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </DashboardCard>
  );
};

const Message = ({ message, userData }) => {
  const isMe = message.sender_id === userData.id;
  const avatarSrc = isMe ?  userData.profile_picture: "path/to/user/avatar.jpg";

  const [isExpanded, setIsExpanded] = useState(false);
  const maxLength = 500; // The maximum length before showing "Read More"

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  // Sanitize the HTML content
  const cleanHtml = sanitizeHtml(message.text, {
    allowedTags: [ 'b', 'i', 'em', 'strong', 'a', 'br' ],  // Add any additional tags you want to allow
  });

  // Convert line breaks to HTML line breaks
  const formattedText = cleanHtml.replace(/\n/g, '<br />');

  const displayText = isExpanded ? formattedText : `${formattedText.substring(0, maxLength)}...`;


  return (
    <Box
      key={message.id} 
      sx={{
        display: "flex",
        justifyContent: isMe ? "flex-end" : "flex-start",
        mb: 2,
      }}
    >
      {!isMe && <Avatar alt="" src={avatarSrc} sx={{width: 24, height: 24,  mr: 2}} />}
      <Paper
        variant="outlined"
        sx={{
          p: 1,
          backgroundColor: isMe ? "primary.light" : "secondary.light",
        }}
      >
        <Typography variant="body1" dangerouslySetInnerHTML={{ __html: displayText }} />
        {formattedText.length > maxLength && (
          <Typography
            variant="body1"
            color="purple"
            component="span"
            onClick={() => setIsExpanded(!isExpanded)}
            sx={{ cursor: "pointer", float: 'right' }}
          >
            {isExpanded ? "...see less" : "...see more"}
          </Typography>
        )}
      </Paper>
      {isMe && <Avatar alt="" src={avatarSrc} sx={{width: 24, height: 24, ml: 2}} />}
    </Box>
  );
};

export default BookingChat;