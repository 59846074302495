import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const AuthCallbackHandler = () => {
  const { isAuthenticated, isLoading, error, getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoading) {
      // Maybe show a loading indicator
      return;
    }

    if (error) {
      // Handle the error, such as displaying a message to the user
      console.error(error);
      return;
    }

    if (isAuthenticated) {
      // Optionally get the access token if needed
      getAccessTokenSilently().then(() => {
        // Redirect to the home page or dashboard after successful login
        navigate('/plaza/dashboard');
      });
    }
    navigate('/plaza/dashboard');
  }, [isAuthenticated, isLoading, error, navigate, getAccessTokenSilently]);

  // Render nothing or a loading indicator
  return null;
};

export default AuthCallbackHandler;