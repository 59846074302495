import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { InputLabel, FormControl, Select, MenuItem, Box, Button, Typography } from '@mui/material';
import { getDealsByFreelancerId } from 'src/services/message.service';
import { useUserContext } from 'src/contexts/Contexts';
import DashboardCard from 'src/components/shared/DashboardCard';

const DealsDropdown = () => {
  const [offerDeals, setOfferDeals] = useState([]);
  const { token, freelancerData } = useUserContext();
  const [selectedDeal, setSelectedDeal] = useState('');
  const navigate = useNavigate();

  const handleChange = (event) => {
    setSelectedDeal(event.target.value);
    console.log(event.target.value); // Check if this logs the expected value
  };

  useEffect(() => {
    const fetchDeals = async () => {
      if (freelancerData) {
        const dealsResponse = await getDealsByFreelancerId(freelancerData.id, token);
        if (dealsResponse.data) {
          setOfferDeals(dealsResponse.data);
        }
      }
    };
    fetchDeals();
  }, [freelancerData, token]);

  const handleNavigate = (offerId) => {
    console.log(offerId); // Check if this logs the expected value
    navigate(`/plaza/freelancer-invoice/${offerId}`);
  };


  return (
    <DashboardCard title="Create New Invoice">
      <FormControl fullWidth>
        <InputLabel id="deal-select-label">Select Deal</InputLabel>
        <Select
          labelId="deal-select-label"
          id="deal-select"
          value={selectedDeal}
          onChange={handleChange}
          displayEmpty
          fullWidth
        >
          {offerDeals.length > 0 ? (
            offerDeals.map((offerDeal) => (
              <MenuItem key={offerDeal.id} value={offerDeal.id}>
                <Box>
                  <Typography variant="body1">#{offerDeal.id} - {offerDeal.company_name}</Typography>
                  <Typography variant="body2" color="text.secondary">
                    {offerDeal.start_date && new Date(offerDeal.start_date).toISOString().split('T')[0]} - 
                    {offerDeal.end_date && new Date(offerDeal.end_date).toISOString().split('T')[0]}
                  </Typography>                
                </Box>
              </MenuItem>
            ))
          ) : (
            <MenuItem disabled>No deals available.</MenuItem>
          )}
        </Select>
      </FormControl>
      <Button
        variant="contained"
        onClick={() => selectedDeal && handleNavigate(selectedDeal)}
        disabled={selectedDeal === ''} 
        sx={{ mt: 3 }}
      >
        Create Invoice
      </Button>
    </DashboardCard>
  );
};

export default DealsDropdown;