import React from 'react';
import PremiumPlusOnboarding from '../profile/components/PremiumPlusOnboarding';
import DashboardCard from 'src/components/shared/DashboardCard';
import { Grid, Typography} from '@mui/material';


const InfoPremiumPlus = () => {
  return (
    <>
    <DashboardCard title="PREMIUM+ Process to start your company">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="body1">
            slfjslkfdj
          </Typography>
        </Grid>
      </Grid>
    </DashboardCard>
    <PremiumPlusOnboarding />
  </>
  );
};

export default InfoPremiumPlus;
