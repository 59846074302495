import React, { useState } from 'react';
import { Grid, Box, Button, Typography } from '@mui/material';
import PageContainer from 'src/components/container/PageContainer';
import usercheck from 'src/services/usercheck';
import NeedsList from './components/NeedsList'; 
import { useNavigate } from 'react-router-dom';


const ClientNeeds = () => {
  const [showCreate, setShowCreate] = useState(false);
  const navigate = useNavigate();


  const toggleCreate = () => {
    setShowCreate(!showCreate);
  };

  return (
    <PageContainer title="Client Needs" description="Manage client needs">
      <Box>
        <Typography variant='h2' align='center' marginBottom={2}>
            List your projects and freelancer needs
        </Typography>
        <Typography variant='subtitle1' align='center' marginBottom={2}>
          Publish your needs so matching freelancers will be able to make an offer.
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <NeedsList />
          </Grid>
          <Grid item xs={12}>
            <Button 
              variant="contained" 
              color="primary"
              onClick={() => {
                navigate(`/plaza/create_need`); 
              }}
            >
              Create a project
            </Button>
          </Grid>

        </Grid>
      </Box>
    </PageContainer>
  );
};

export default usercheck(ClientNeeds); // Assuming usercheck is some form of user authentication or role validation.
