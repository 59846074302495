import React, { useState, useEffect } from 'react';
import { Grid, Card, Chip, CardHeader, Button, Typography, Collapse, IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DashboardCard from '../../../components/shared/DashboardCard';
import { useUserContext } from '../../../contexts/Contexts';
import { getClientNeedByClientId, getClientNeedSkills, getOfferByClientNeedId } from '../../../services/message.service';
import { useNavigate } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';


const NeedsList = () => {
  const { clientData, token } = useUserContext();
  const [needs, setNeeds] = useState([]);
  const navigate = useNavigate();
  const [skillsData, setSkillsData] = useState({});
  const [offerData, setOfferData] = useState({});

  const fetchSkillsForNeed = async (needId) => {
    if (!skillsData[needId]) {
      try {
        const fetchedSkills = await getClientNeedSkills(needId, token);
        setSkillsData(prevSkillsData => ({
          ...prevSkillsData,
          [needId]: fetchedSkills.data,
        }));
      } catch (error) {
        console.error(`Error fetching skills for need ${needId}:`, error);
      }
    }
  };


  const fetchOffersForNeed = async (needId) => {
    if (!offerData[needId]) {
      try {
        const fetchedOffers = await getOfferByClientNeedId(needId, token);
        setOfferData(prevOfferData => ({
          ...prevOfferData,
          [needId]: fetchedOffers.data ? fetchedOffers.data : "", 
        }));
      } catch (error) {
        console.error(`Error fetching offers for need ${needId}:`, error);
      }
    }
  };
  
  
  const navigateToNeedDetails = (needId) => {
    navigate(`/plaza/offers/${needId}`);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchedNeeds = await getClientNeedByClientId(clientData.id, token);
        setNeeds(fetchedNeeds.data); // Assume data structure is similar to bookings
      } catch (error) {
        console.error('Error fetching client needs:', error);
      }
    };

    fetchData();
  }, [clientData]);

  useEffect(() => {
    if (needs && needs.length > 0) {
      needs.forEach((need) => {
        fetchSkillsForNeed(need.id);
        fetchOffersForNeed(need.id);
      });
    }
  }, [needs]); 

  const [expandedId, setExpandedId] = useState(null);
  
  const handleExpandClick = (id) => {
    setExpandedId(prevId => prevId === id ? null : id);
  };

  
  return (
    <Grid container spacing={3}>
      <Grid item xs={8}>
        <DashboardCard title="Your Projects">
          {needs?.map((need, index) => (
            <Card key={need.id} variant="outlined" style={{ marginBottom: '16px' }}>
              <CardHeader
                title={
                  <Typography 
                    variant="h5" 
                    style={{ cursor: 'pointer' }}
                    onClick={() => navigateToNeedDetails(need.id)}>
                    {need.title}
                  </Typography>
                }
                subheader={
                  <Typography variant="body1">
                    {need.description.substring(0, 300)}...
                    {/* <IconButton
                      style={{ float: 'right' }} // Move to right
                      onClick={() => handleExpandClick(need.id)}
                      aria-expanded={expandedId === need.id}
                      aria-label="show more"
                    >
                      <ExpandMoreIcon />
                    </IconButton> */}
                  </Typography>
                }  
                action={
                  <Button variant="contained" color="primary" onClick={() => navigateToNeedDetails(need.id)}>
                    <Typography variant="body2">View all offers</Typography>
                  </Button>
                }
              />
              {/* <Collapse in={expandedId === need.id} timeout="auto" unmountOnExit>
                <Typography variant="body1" style={{ padding: '16px' }}>
                  {need.description}
                </Typography>
              </Collapse>
               */}
              <div style={{ padding: '0 0 16px 16px' }}>
              <Typography variant="body2"><strong>Skills required:</strong></Typography>
                {skillsData[need.id]?.map((skill, skillIndex) => (
                  <Chip label={skill?.skill_name ?? 'error'} style={{ margin: '4px' }} />
                ))}
              </div>
              {offerData[need.id] && offerData[need.id].length > 0 && (
                <div style={{ padding: '16px' }}>
                  <Typography variant="body2">Offers from:</Typography>
                  <div style={{ display: 'flex', flexWrap: 'wrap', gap: '8px', marginTop: '8px' }}>
                    {offerData[need.id].map((offer, offerIndex) => (
                      <Avatar 
                        src={offer ? offer.profile_image || ' ' : ' '}
                        alt={offer ? offer.freelancer_id : 'Unknown'}
                        sx={{ width: 25, height: 25 }}
                      />
                    ))}
                  </div>
                </div>
              )}
            </Card>
          ))}
        </DashboardCard>
      </Grid>
      <Grid item xs={4}>
        <DashboardCard title="How it works">
          <Typography variant='body2'>
            Here you see all your projects and needs you have created. 
            Click on them to see offers from freelancers. 
            Once you are happy with an offer, move forward to make a deal.
            <br /><br />
            <strong>The process:</strong>
            <ul>
              <li>You publish your project or need</li>
              <li>Freelancers who qualify will get noticed</li>
              <li>Freelancers make an offer or sends you questions - You will be notified by email</li>
              <li>As soon as you have an offer you are happy with, you can make a Deal with the freelancer</li>
              <li>Contracts are sent out to both freelancer and client to sign. Check your email within 24 hours</li>
            </ul>
          </Typography>
        </DashboardCard>
      </Grid>
    </Grid>
  );
};


export default NeedsList;
