import React, { useState, useEffect } from 'react';
import { Grid, Card, CardHeader, Button, Typography } from '@mui/material';
import DashboardCard from '../../../components/shared/DashboardCard';
import { useUserContext } from '../../../contexts/Contexts';
import { getClientNeeds } from '../../../services/message.service'; // Replace with your actual service
import { useNavigate } from 'react-router-dom';

const NeedsList = () => {
  const { token } = useUserContext();
  const [needs, setNeeds] = useState([]);
  const navigate = useNavigate();

  const navigateToNeedDetails = (needId) => {
    navigate(`/plaza/need/${needId}`);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchedNeeds = await getClientNeeds(token);
        setNeeds(fetchedNeeds.data); 
      } catch (error) {
      }
    };

    fetchData();
  }, [token]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant='h2' align='center' marginBottom={2}>
            All Available Leads
        </Typography>
        <Typography variant='subtitle1' align='center' marginBottom={2}>
          These are the needs and projects clients are looking for right now. Click in on the ones you
          find interesting to ask questions or to make an offer.
        </Typography>

        <DashboardCard title="">
          {needs?.map((need, index) => (
            <Card key={need.id} variant="outlined" style={{ marginBottom: '16px' }}>
              <CardHeader
                title={
                  <Typography 
                    variant="h5" 
                    style={{ cursor: 'pointer' }}
                    onClick={() => navigateToNeedDetails(need.id)}>
                    {need.title}
                  </Typography>
                }
                subheader={
                <Typography variant="body1">
                  {need.description.substring(0, 250)}...
                </Typography>
                }
                action={
                  <Button variant="outlined" color="primary" onClick={() => navigateToNeedDetails(need.id)}>
                    <Typography variant="body2">Details</Typography>
                  </Button>
                }
              />
            </Card>
          ))}
        </DashboardCard>
      </Grid>
    </Grid>
  );
};

export default NeedsList;
