import React from 'react';
import { Grid, Box } from '@mui/material';
import PageContainer from 'src/components/container/PageContainer';
import usercheck from 'src/services/usercheck';
import BookingOfferAndDeals from '../freelancer/components/BookingOfferAndDeals';
import { useParams } from 'react-router-dom';
import BookingChat from '../freelancer/components/BookingChat';


const Offers = () => {
  const { offer_id } = useParams(); 
  const { client_need_id } = useParams();
  const { freelancer_id } = useParams();
  return (
    <PageContainer title="Offer Details" description="Manage This Offer">
      <Box>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={7}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <BookingOfferAndDeals offerType={"offer"} id={offer_id}/>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={5}>
            <BookingChat chatType="need" client_need_id={client_need_id} freelancer_id={freelancer_id}/>
          </Grid>
        </Grid>
      </Box>
    </PageContainer>
  );
};

export default usercheck(Offers);