import React, { useState, useEffect } from 'react';
import { Card, CardContent,  Button, Typography, List, ListItem, ListItemText, Divider } from '@mui/material';
import { Link } from 'react-router-dom';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

import { getInvoicesByFreelancerId } from 'src/services/message.service';
import { useUserContext } from 'src/contexts/Contexts';
import DashboardCard from 'src/components/shared/DashboardCard';
import LoadingModal from 'src/components/shared/LoadingModal'; 


const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;


const InvoicesList = () => {
  const { userData, token, isFreelancer, freelancerData, isClient } = useUserContext();
  const [invoices, setInvoices] = useState([]);
  const [expandedInvoiceId, setExpandedInvoiceId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);


  const toggleInvoiceDetails = (invoiceId) => {
    setExpandedInvoiceId(expandedInvoiceId === invoiceId ? null : invoiceId);
  };

  useEffect(() => {
    const fetchInvoices = async () => {
      setIsLoading(true);
      try {
        if (freelancerData) {
          const response = await getInvoicesByFreelancerId(freelancerData.id, userData.id, 'FREELANCER',  token);
          if (response && response.data) {
            setInvoices(response.data);
          } else {
            setInvoices([]); 
          }
        }
      } catch (error) {
        console.error('Error fetching invoices:', error);
      } finally {
        setIsLoading(false); // Stop loading regardless of the outcome
      }
    };

    fetchInvoices();
  }, [freelancerData, userData, token]);

  return (
    <DashboardCard title="Invoices">
       {isLoading && <LoadingModal />} {}
      <List>
        {invoices?.map((invoice, index) => (
          <React.Fragment key={invoice.id}>
            <ListItem>
              <ListItemText
                primary={
                  <Typography variant="h4" component="h2">
                    {`Invoice #${invoice.id}`}
                  </Typography>
                }
                secondary={
                  <>
                    <Typography component="span">
                      {`Status: ${invoice.status} | Client Due: ${invoice.due_date.slice(0, 10)}`}
                    </Typography>
                    <br />
                    <a href={`${apiServerUrl}/api/downloads/invoice/${invoice.freelancerPdfUuid}`} >
                      <Button variant="text" sx={{ pl: 0 }} startIcon={<PictureAsPdfIcon />}>
                        Freelancer Self Invoice
                      </Button>
                    </a>
                    <br/>
                    <a href={`${apiServerUrl}/api/downloads/invoice/${invoice.clientPdfUuid}`}>
                      <Button variant="text" sx={{ pl: 0 }}  startIcon={<PictureAsPdfIcon />}>
                        Client Invoice
                      </Button>
                    </a>
                    <br/>
                    <Button variant="text" sx={{ pl: 0, pt: 0, pb: 0}} onClick={() => toggleInvoiceDetails(invoice.id)}>
                      {expandedInvoiceId === invoice.id ? 'Hide line items' : 'Show line items'}
                    </Button>
                  </>
                }
                
              />
            </ListItem>     
            {expandedInvoiceId === invoice.id && (
              <List component="div" disablePadding sx={{ py: 0 }}>
                {invoice?.invoiceItems.map((item, itemIndex) => (
                  <ListItem key={item.id} sx={{ pl: 4, py: 0 }}>
                    <ListItemText
                      primary={item.description}
                      secondary={`Quantity: ${item.quantity} | Unit Price: ${item.unit_price} | Row Sum: ${item.quantity * item.unit_price}` }
                    />
                  </ListItem>
                ))}
              </List>
            )}
            <ListItem sx={{ py: 0 }}>
              <ListItemText
                primary={
                  <strong>Sub Total: ${invoice.currency} ${invoice.subTotal} excl VAT</strong>
                }
                secondary={
                  <>
                    <Typography component="span">
                      {`Booking Fee: ${invoice.bookingFeeDetails.bookingFee} ${invoice.currency} 
                      (Subscription Fee: ${invoice.bookingFeeDetails.subscriptionFee}%${invoice.bookingFeeDetails.offerDiscount < 0 ? `, Fee Discount: ${invoice.bookingFeeDetails.offerDiscount}%` : ''})
                      `}
                    </Typography>
                    <br />
                    <Typography variant="body1">
                      {`Payout: ${invoice.currency} ${invoice.sumTotal} excl VAT`}
                    </Typography>
                    <Typography component="span">
                      {`VAT: ${invoice.vat} ${invoice.currency}`}
                    </Typography>
                  </>
                }
              />
            </ListItem>
            <Divider />
          </React.Fragment>
        ))}
      </List>
    </DashboardCard>
  );
};

export default InvoicesList;