import { useState, useEffect } from "react";
import { Typography, Grid, Button, Box, Paper, Link } from "@mui/material";
import { styled } from '@mui/system';
import DashboardCard from '../../../components/shared/DashboardCard';
import { useUserContext } from 'src/contexts/Contexts';
import { createClient, createFreelancer } from "../../../services/message.service";
import FreelancerIcon from '@mui/icons-material/Person'; // Replace with your own icons
import ClientIcon from '@mui/icons-material/Business'; // Replace with your own icons
import { useNavigate } from 'react-router-dom';


const HoverableDashboardCard = styled(DashboardCard)`
  transition: transform .2s;
  &:hover {
    transform: scale(1.05);
  }
`;


export const Type = ({ onNext }) => {
  const [selectedType, setSelectedType] = useState(null);
  const { userData, clientData, freelancerData, setFreelancerData, setClientData, setIsFreelancer, setIsClient, isFreelancer, isClient, token } = useUserContext();

  const navigate = useNavigate();

  const handleButtonClick = async (type) => {
    setSelectedType(type);
    const userId = userData.id;

    // Update context first
    if (type === "Freelancer" || type === "Both") {
      if (!freelancerData) {
        let newFreelancerData = { user_id: userId };
        // Call your function to add Freelancer data to the database
        const result = await createFreelancer(newFreelancerData, token);
        setFreelancerData(result.data);
        setIsFreelancer(true);
      }
    }

    if (type === "Client" || type === "Both") {
      if (!clientData) {
        // Call your function to add Client data to the database
        const result = await createClient(userId, token);
        setClientData(result.data);
        setIsClient(true);
      }
    }
    onNext();
  };

  useEffect(() => {
    if (isFreelancer) {
      setSelectedType("Freelancer");
    } else if (isClient) {
      setSelectedType("Client");
    }
  }, [isFreelancer, isClient]); 


  const typeInfo = {
    Freelancer: {
      description: "I offer specialized skills and services.",
      icon: <FreelancerIcon fontSize="large" />,
      readmoreLink: "/freelancer-subscription"
    },
    Client: {
      description: "I'm looking to hire freelancers.",
      icon: <ClientIcon fontSize="large" />,
      readmoreLink: "/client-subscription"
    }
  };

  return (
    <>
      {["Freelancer", "Client"].map((type, index) => (
        <Grid item xs={12} sm={6} md={6} key={index}>
          <HoverableDashboardCard title="">
            <Box align="center" padding={2}>
              {typeInfo[type].icon}
            </Box>
            <Typography variant="h2" align="center">{type}</Typography>
            <Typography variant="subtitle1" align="center" gutterBottom>
              {typeInfo[type].description}
            </Typography>
            <Box align="center" marginTop={2}>
              <Button 
                variant="contained"
                color={selectedType === type ? "secondary" : "primary"}
                align="center"
                onClick={() => handleButtonClick(type)}
                disabled={(type === "Freelancer" && isClient) || (type === "Client" && isFreelancer)}
              >
                {selectedType === type ? "Your Choice" : "Choose This"} 
              </Button>
              <br/>
              <Button 
                variant="text"
                align="center"
                onClick={() =>{navigate( typeInfo[type].readmoreLink)}}
              >
                Learn more
              </Button>
            </Box>
          </HoverableDashboardCard>
        </Grid>
      ))}
    </>
  );
};
