import React, { useState, useEffect, useCallback } from 'react';
import { TextField, Button, InputAdornment, CircularProgress, Grid, Typography, Autocomplete } from '@mui/material';
import { searchUserAndClientByEmail, createUserAndClient } from '../../../services/message.service';
import { useUserContext } from 'src/contexts/Contexts';
import CreateOffer from './CreateOffer';
import { Dashboard } from '@mui/icons-material';
import DashboardCard from 'src/components/shared/DashboardCard';


// Import other necessary components and services

const ClientManager = () => {
  const { token } = useUserContext();
  const [email, setEmail] = useState('');
  const [clientData, setClientData] = useState(null);
  const [userData, setUserData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showNewClientFields, setShowNewClientFields] = useState(false);
  const [emailSuggestions, setEmailSuggestions] = useState([]); // Initialized as an empty array
  const [newClientInfo, setNewClientInfo] = useState({
    auth0_id: null, firstname: '', lastname: '', company_name: '', vat_nr: '',
    address_1: '', address_2: '', zipcode: '', city: '', country: '',
    invoice_email: '', phone_country_code: '', phone_number: ''
  });

  const debounce = (func, delay) => {
    let inDebounce;
    return function() {
      const context = this;
      const args = arguments;
      clearTimeout(inDebounce);
      inDebounce = setTimeout(() => func.apply(context, args), delay);
    };
  };

  // Create the debounced function using useCallback to ensure it's not recreated on every render
  const debouncedSearch = useCallback(debounce(async (searchText) => {
    if (searchText.length >= 3) {
      setIsLoading(true);
      try {
        const response = await searchUserAndClientByEmail(searchText, token);
        // Check if the response has a data property and it's an array
        if (response.data && Array.isArray(response.data)) {
          setEmailSuggestions(response.data);
        } else {
          setEmailSuggestions([]); // Set to an empty array if no data
        }
        } catch (error) {
        console.error('Error fetching email suggestions:', error);
      } finally {
        setIsLoading(false);
      }
    } else {
      setEmailSuggestions([]); // Clear suggestions if searchText is less than 3 characters
    }
  }, 100), [token]); // Add token to the dependency array if it's a state or prop

  const handleSelection = (event, value) => {
    if (value) {
      setUserData(value.user);
      setClientData(value.client);
      setShowNewClientFields(false); // Hide new client fields
    }
  };

  const handleCreateNewClientClick = () => {
    setShowNewClientFields(true); // Show new client fields
    setClientData(null); // Reset client data
    setUserData(null); // Reset user data
  };

  const handleEmailChange = (event, newInputValue) => {
    setEmail(newInputValue);
    debouncedSearch(newInputValue);
  };

  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewClientInfo(prev => ({ ...prev, [name]: value }));
  };

  const handleEmailBlur = (e) => {
    setEmail(e.target.value);
  };

  const handleCreateUserAndClient = async () => {
    setIsLoading(true);
    try {
      const clientInfoWithEmail = { ...newClientInfo, email };
      const response = await createUserAndClient(clientInfoWithEmail, token);
      if (response.data) {
        console.log("DATA: " + JSON.stringify(response.data));
        setClientData(response.data.client);
        setUserData(response.data.user);
        setShowNewClientFields(false); 
      } else {
       
      }
    } catch (error) {
      console.error('Error creating user and client:', error);
    }
    setIsLoading(false);
  };

  const isValidEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  const showNoClientFoundMessage = !isLoading && !clientData && email.length > 0 && isValidEmail(email) && !showNewClientFields;

  // Form for new client creation
  return (
    <Grid container spacing={2}>
      {!clientData && (
        <>
        <Grid item xs={6}>
          <DashboardCard title="Create offer">
          <Grid item xs={12}>
            <Typography variant='body1' marginBottom={3}>
              Type in the details you know about the client. Start with email and we will see if the client already is registered.
              The client will have the option to add missing information in the next step. Email is mandatroy.
            </Typography>
            <Autocomplete
              freeSolo
              options={emailSuggestions}
              getOptionLabel={(option) => option.user ? option.user.email : ''}              onInputChange={handleEmailChange} // Use onInputChange for debounced search
              onChange={handleSelection}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Email"
                  name="email"
                  value={email} // Control the value of the TextField with the email state
                  onChange={(e) => setEmail(e.target.value)} // Update the email state as the user types
                  onBlur={handleEmailBlur}
                  fullWidth
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {isLoading && <CircularProgress size={20} />}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          {showNoClientFoundMessage && emailSuggestions.length === 0 && (
            <Grid item xs={12} style={{ marginTop: 10 }}>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleCreateNewClientClick}
              >
                Create a new client
              </Button>
            </Grid>
          )}
        </DashboardCard>
        </Grid>
        {!clientData && showNewClientFields && (
        <>
        <Grid item xs={6}>
          <DashboardCard title="Client Information">
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <TextField label="Company Name" name="company_name" onChange={handleInputChange} fullWidth />
              </Grid>
              <Grid item xs={6}>
                <TextField label="VAT Nr" name="vat_nr" onChange={handleInputChange} fullWidth />
              </Grid>
              <Grid item xs={6}>
                <TextField label="Contact First Name" name="firstname" onChange={handleInputChange} fullWidth />
              </Grid>
              <Grid item xs={6}>
                <TextField label="Contact Last Name" name="lastname" onChange={handleInputChange} fullWidth />
              </Grid>
              <Grid item xs={12}>
                <TextField label="Invoice Email" name="invoice_email" onChange={handleInputChange} fullWidth />
              </Grid>
              <Grid item xs={6}>
                <Button onClick={handleCreateUserAndClient} variant="contained" color="primary" disabled={isLoading}>
                  {isLoading ? <CircularProgress size={24} /> : 'Create Client'}
                </Button>
              </Grid>
            </Grid>
          </DashboardCard>
        </Grid>
        </>
          )}
        </>
      )}
      {clientData && (
        <>
          <Grid item xs={3}>
            <DashboardCard title="Client">
              <Typography><strong>Client Email: </strong><br/>
                {userData.email}</Typography>
              <Typography><strong>Company:  </strong><br/>
                {userData.company_name}</Typography>
              <Typography><strong>Contact:  </strong><br/>
                {userData.firstname} {userData.lastname}</Typography>
            </DashboardCard>
          </Grid>
          <Grid item xs={9}>
            <CreateOffer clientData={clientData} />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default ClientManager;