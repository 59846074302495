import React, { useEffect, useState } from "react";
import { getSubscriptions } from "../../../services/message.service";
import { Typography, Grid, Button } from '@mui/material';
import DashboardCard from '../../../components/shared/DashboardCard';
import { useUserContext } from 'src/contexts/Contexts';

const Subscriptions = () => {
  const [subscriptions, setSubscriptions] = useState([]);
  const { token } = useUserContext();
  
  useEffect(() => {
    const initialize = async () => {
      let type = "freelancer";
      const { data, error } = await getSubscriptions(type, token);
      if (data) {
        setSubscriptions(data);
      }  
    };
    initialize();

  }, [token]);

  return (
    <>
    <Grid container spacing={3}>
      {subscriptions.map((subscription, index) => (
      <Grid item xs={12} sm={4} md={4} key={index}>
        <DashboardCard title={subscription.name} subtitle={subscription.description}>
          {subscription.features.split('\n').map((line, index) => (
            <React.Fragment key={index}>
              {line}
              <br />
            </React.Fragment>
          ))}
          <Typography variant='h6' marginTop={4}>Pricing</Typography>
          ☻ {parseInt(subscription.monthly_fee)} SEK Monthly<br/>
          ☻ {parseInt(subscription.booking_fee)}% of your booking value

          
            {(subscription.cancellation_period === 0) ? (
              <Typography variant="body2" marginTop={3} align="center">
                No cancellation periods
              </Typography>
            ) : 
              <Typography variant="body2" marginTop={3} align="center">
                Cancellation period {parseInt(subscription.cancellation_period)} Months
              </Typography>
            }
        </DashboardCard>
      </Grid>
      ))}
    </Grid>
    </>
  );
};

export default Subscriptions;