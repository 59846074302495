import React from "react";
import DashboardCard from '../../../components/shared/DashboardCard';
import { Typography, Grid, Button, Box } from '@mui/material';

export const SlackInvitation = () => {
  
  return (
      <DashboardCard title="Join Our Slack Community!">
        <Box sx={{ textAlign: 'center', my: 2 }}>
          <img src="https://res.cloudinary.com/dldyopnvj/image/upload/v1700128505/Slack-logo-RGB_vlj8q8.png" alt="Slack" style={{ width: 250 }} />
        </Box>
        <Typography variant="body1" sx={{ mb: 2 }}>
          Join our Slack community to connect with other freelancers, get notifications from Daymon AI, our Slack bot, and get access to Because Mondays staff.
        </Typography>
        <Box sx={{ textAlign: 'center' }}>
          <Button 
            variant="contained" 
            color="primary" 
            href="https://join.slack.com/t/becausemondaysplaza/shared_invite/zt-266wqc301-JVJCCopkXqKncAzrqWUwRg">
            Join Slack
          </Button>
        </Box>
      </DashboardCard>
  );
};