import React, { useState, useEffect } from 'react';
import { Button, TextField, Typography, Grid, InputLabel, Select, MenuItem } from '@mui/material';
import { useParams } from 'react-router-dom';
import { createBooking } from '../../../services/message.service'; // Import the service function to create a booking
import DashboardCard from '../../../components/shared/DashboardCard';
import { useUserContext } from '../../../contexts/Contexts';
import { useAuth0 } from "@auth0/auth0-react";
import { IconMessage, IconLockAccess, IconCalendar} from '@tabler/icons-react';
import { useLocation } from 'react-router-dom';
import { getFreelancerServicesByFreelancerId } from "../../../services/message.service";




/**** TO DOS
 * Only show booking if logged in -> Create account for assignment client
 *  
 * 
 */

const BookingRequest = ({ freelancer_id, preSelectedServiceId }) => { 

  const { userData, freelancerData, clientData, token } = useUserContext();
  const { loginWithRedirect } = useAuth0();
  const [submitButtonState, setSubmitButtonState] = useState({ text: 'Send request', color: 'primary' });
  const [isFormVisible, setIsFormVisible] = useState(false);
  const location = useLocation();
  const [serviceData, setServiceData] = useState(null);
  const [selectedServiceId, setSelectedServiceId] = useState(null);
  const [formData, setFormData] = useState({
    title: '',
    project_description: '',
    deadline: '',
    budget: '0',
    freelancer_id: freelancer_id,
    freelancer_services_id: null,
  });

  const handleBooking = async () => {
    if (userData) {
      setIsFormVisible(true);
    } else {
      handleLogin();
    }
  }

  const handleLogin = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: location.pathname,
      },
    });
  };
  const handleSignUp = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: "/plaza/profile-setup",
      },
      authorizationParams: {
        screen_hint: "signup",
      },
    });
  };



  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'freelancer_services_id') {
      setSelectedServiceId(value);
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    const finalFormData = {
      ...formData,
      client_id: clientData.id,
      freelancer_id: freelancer_id,
      freelancer_services_id: selectedServiceId,
    };
  
    try {
      
      const response = await createBooking(finalFormData, token);

      setSubmitButtonState({ text: 'Booking request sent ✔', color: 'secondary' });
      
      setTimeout(() => {
        setSubmitButtonState({ text: 'Send booking request', color: 'primary' });
        setFormData({
          title: '',
          project_description: '',
          deadline: '',
          budget: '0',
        });
      }, 2000); // Reset after 2 seconds  
      // Here, you might want to add any further handling, like redirecting to another page or showing a success message
    } catch (error) {
      setSubmitButtonState({ text: 'Error: '+ error, color: 'error' });
      console.error('Error creating booking request:', error);
      // Here, you can add error handling, like showing an error message to the user
    }
  };

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const data = await getFreelancerServicesByFreelancerId(freelancer_id);
        setServiceData(data.data);

        if (preSelectedServiceId) {
          setSelectedServiceId(preSelectedServiceId);
          setFormData(prevFormData => ({
            ...prevFormData,
            freelancer_services_id: preSelectedServiceId,
          }));
        }
      } catch (error) {
        console.error('Error fetching services:', error);
      }
    };

    if (clientData) {
      setIsFormVisible(true);
    } 
  
    fetchServices();
  }, [freelancer_id, preSelectedServiceId, clientData]); 

  return (

      <Grid container spacing={1} marginTop={1}>
        <Grid item xs={12}>
        <DashboardCard title={`Send a request `}>
        {!isFormVisible && (
          <>
          {freelancerData ? (
            <Typography variant="subtitle2" marginBottom={3}>
              You are on a Freelancer Account. If you want to send a request to this freelancer you need a Client Account. Log out and create a Client Account to send a request.
            </Typography> 
            ):(
              <>
              <Typography variant="body1" marginBottom={3}>
                Please Sign in or Create a Client Account to send a request
              </Typography>
              <Button 
                variant="text" 
                color="primary" 
                onClick={handleLogin}>
                {/* {userData && 'Book Freelancer'} */} 
                {!userData && 'Sign In'}
              </Button>
              &nbsp;
              <Button 
                variant="contained" 
                color="primary" 
                onClick={handleSignUp}>
                {/* {userData && 'Book Freelancer'} */} 
                {!userData && 'Create Account'}
              </Button>
              </>
          )}
          </>
        )}
        {isFormVisible && (
          <>
        <Typography variant="body1" marginBottom={3}>
          Send a request to start a conversation about your project.  
        </Typography>
          <form onSubmit={handleSubmit}>
              {/* <InputLabel id="freelancer_services_id">Choose Freelancer Service</InputLabel>
              <Select
                labelId="freelancer_services_id"
                id="freelancer_services_id-select"
                name="freelancer_services_id"
                value={selectedServiceId}
                label="Choose Freelancer Service"
                onChange={handleChange}
                fullWidth
              >
                {serviceData && serviceData.length > 0 ? (
                  serviceData.map((service) => (
                    <MenuItem key={service.id} value={service.id}>
                      {service.service_name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>No services found.</MenuItem>
                )}
              </Select> */}

            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="title"
              label="Title of your project"
              name="title"
              autoComplete="title"
              value={formData.title}
              onChange={handleChange}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="project_description"
              label="Project Description"
              name="project_description"
              autoComplete="project_descriptio"
              multiline
                rows={8}
              value={formData.project_description}
              onChange={handleChange}
            />
            {/* <TextField
              variant="outlined"
              margin="normal"
              required
              id="deadline"
              label="Eventual Deadline"
              name="deadline"
              type="date"
              autoComplete="deadline"
              InputLabelProps={{
                shrink: true,
              }}
              value={formData.deadline}
              onChange={handleChange}
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="budget"
              label="Eventual budget"
              name="budget"
              type="number"
              autoComplete="budget"
              InputLabelProps={{
                shrink: true,
              }}
              value={formData.budget}
              onChange={handleChange}
            />  */}
            <Button 
              variant="contained" 
              color={submitButtonState.color} 
              onClick={handleSubmit}>
                {submitButtonState.text}
            </Button>
            {/* &nbsp;
            <Button 
              variant="contained" 
              color="secondary" 
              startIcon={<IconMessage />}
            >
              Chat with 
            </Button> */}
          </form>
          </>
        )}
          </DashboardCard>
        </Grid>
      </Grid>

  );
};

export default BookingRequest;