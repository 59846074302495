import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";
import { useUserContext } from '../../../contexts/Contexts';


import {
  Avatar,
  Box,
  Menu,
  Button,
  IconButton,
  MenuItem,
  ListItemIcon,
  ListItemText
} from '@mui/material';

import { IconDashboard, IconListCheck, IconMail, IconUser } from '@tabler/icons-react';

const Profile = () => {
  
  const { userData, isClient, isFreelancer, token } = useUserContext();
  const { user } = useAuth0();
  const { logout } = useAuth0();

  const handleLogout = () => {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  const [anchorEl2, setAnchorEl2] = useState(null);
  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  return (
    <Box>
      <IconButton
        size="large"
        aria-label="show 11 new notifications"
        color="inherit"
        aria-controls="msgs-menu"
        aria-haspopup="true"
        sx={{
          ...(typeof anchorEl2 === 'object' && {
            color: 'primary.main',
          }),
        }}
        onClick={handleClick2}
      >
        <Avatar
          src={user.picture}
          alt={user.name}
          sx={{
            width: 35,
            height: 35,
          }}
        />
      </IconButton>
      {/* ------------------------------------------- */}
      {/* Message Dropdown */}
      {/* ------------------------------------------- */}
        {userData?.accepted_gdpr_at ? (
          <Menu
            id="msgs-menu"
            anchorEl={anchorEl2}
            keepMounted
            open={Boolean(anchorEl2)}
            onClose={handleClose2}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            sx={{
              '& .MuiMenu-paper': {
                width: '200px',
              },
            }}
          >
            <MenuItem component={Link} to="/plaza/dashboard">
              <ListItemText><b>{user.name}</b></ListItemText>
            </MenuItem>
            <MenuItem component={Link} to="/plaza/dashboard">
              <ListItemIcon>
                <IconDashboard width={20} />
              </ListItemIcon>
              <ListItemText>My Dashboard</ListItemText>
            </MenuItem>
            <MenuItem component={Link} to="/plaza/profile">
              <ListItemIcon>
                <IconUser width={20} />
              </ListItemIcon>
              <ListItemText>My Account</ListItemText>
            </MenuItem>
            <Box mt={1} py={1} px={2}>
              <Button variant="outlined" color="primary" onClick={handleLogout} fullWidth>Log Out</Button>
            </Box>
          </Menu>
        ) : (
          <Menu
            id="msgs-menu"
            anchorEl={anchorEl2}
            keepMounted
            open={Boolean(anchorEl2)}
            onClose={handleClose2}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            sx={{
              '& .MuiMenu-paper': {
                width: '200px',
              },
            }}
          >
            <MenuItem component={Link} to="/plaza/profile-setup">
              <ListItemIcon>
                <IconUser width={20} />
              </ListItemIcon>
              <ListItemText>Complete onboarding</ListItemText>
            </MenuItem>
            <Box mt={1} py={1} px={2}>
              <Button variant="outlined" color="primary" onClick={handleLogout} fullWidth>Log Out</Button>
            </Box>
          </Menu>
        )}
        {/* <Link to="/plaza/freelancer-bookings" style={{ textDecoration: 'none', color: '#5A6A85'}}>
        <MenuItem>
          <ListItemIcon>
            <IconListCheck width={20} />
          </ListItemIcon>
          <ListItemText>My Contracts</ListItemText>
        </MenuItem>
        </Link> */}
        
    </Box>
  );
};

export default Profile;
