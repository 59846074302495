import React, { useState, useEffect } from 'react';
import { Grid, Card, CardHeader, CardContent, Collapse, IconButton, Typography, Button, Badge } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DashboardCard from '../../../components/shared/DashboardCard';
import ChatIcon from '@mui/icons-material/Chat';
import { useUserContext } from '../../../contexts/Contexts';
import {getBookingByFreelancerId, getBookingByClientId, getClientNeedsByFreelancerId, getClientNeedsAndSkillsByClientId} from '../../../services/message.service'
import Avatar from '@mui/material/Avatar';
import sanitizeHtml from 'sanitize-html';
import { useNavigate } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';


const ClientNeedOffers = ({ fetchBy }) => {
  const { freelancerData, clientData, isFreelancer, isClient, token } = useUserContext();
  const [expanded, setExpanded] = useState(null); // Store the ID of the expanded item
  const [needs, setNeeds] = useState([]);
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const navigateToBookingDetails = (clientNeedId) => {
    navigate(`/plaza/need/${clientNeedId}`);
  };

  useEffect(() => {
    const fetchData = async () => {
      let fetchedNeeds = null;
      try {
        if (fetchBy === 'freelancer' && freelancerData) {
          fetchedNeeds = await getClientNeedsByFreelancerId(freelancerData.id, token);
        }
        else if (fetchBy === 'client' && clientData) {  // Assuming clientData is in your context
          fetchedNeeds = await getClientNeedsAndSkillsByClientId(clientData.id, token);  // Assuming you have a function getBookingByClientId
        }
        if (fetchedNeeds) {
          setNeeds(fetchedNeeds.data.rows);
        }
      } catch (error) {
        console.error('Error fetching bookings:', error);
      }
    };

    fetchData();
  }, [fetchBy, freelancerData, clientData]);  // Include both freelancerData and clientData as dependencies

  function NewlineText({ text }) {
    const newText = text.split('\n').map((str, index, array) =>
      index === array.length - 1 ? str : <>
        {str}
        <br />
      </>
    );
    return <>{newText}</>;
  }
  

  const handleExpandClick = (id) => {
    setExpanded(expanded === id ? null : id); // Toggle expanded item by ID
  };

  return (
    <DashboardCard title="Your Offers on Client Projects">
      <Typography variant='body1' marginBottom={2} marginTop={3}>
        These are offers you have created on client projects.
      </Typography>
      {!needs.length > 0 && isFreelancer && (
        <Typography variant='body1' marginBottom={2} marginTop={3}>
          But right now it is empty. Check out All Leads to see if there are any projects of interest.
        </Typography>
                                            
      )}
      {needs?.map((need, index) => (
        <Card key={need.id} variant="outlined" style={{ marginBottom: '16px' }}>
          <CardHeader
            avatar={
              <Avatar alt={`${need.firstname} ${need.lastname}`} src={need.profile_picture} />
            }
            title={
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{ position: 'relative' }}>
                  {need.offer_id === null && (
                    <Badge variant="dot" color="primary" style={{
                      position: 'absolute',
                      top: '10px',
                      left: '-5%',
                      transform: 'translate(0%, 0)'
                    }} />
                  )}

                  <Typography 
                    variant="h5" 
                    style={{ marginTop: '12px', cursor: 'pointer' }}
                    onClick={() => navigateToBookingDetails(need.id)}>
                    {need.title}
                  </Typography>
                </div>
              </div>
            }

            titleTypographyProps={{ variant: 'h5' }} 
            subheader={`Customer: ${need.firstname} ${need.lastname}`}
            action={
              <>
                <Button variant="outlined" color="primary" onClick={() => navigateToBookingDetails(need.id)} startIcon={<ChatIcon />}>
                  {!isSmallScreen && <Typography variant="body2">Details</Typography> }
                </Button>
                <IconButton
                  onClick={() => handleExpandClick(need.id)}
                  aria-expanded={expanded === need.id}
                  aria-label="show more"
                >
                  <ExpandMoreIcon />
                </IconButton>
              </>
            }
          />
          <Collapse in={expanded === need.id} timeout="auto" unmountOnExit>
            <CardContent>
              <Typography variant='h6'>Description:</Typography>
                <NewlineText text={sanitizeHtml(need.description)} />
            </CardContent>
          </Collapse>
        </Card>
      ))}
    </DashboardCard>
  );
};

export default ClientNeedOffers;
