import React, { useState } from 'react';
import { Grid, Box, Typography } from '@mui/material';
import PageContainer from 'src/components/container/PageContainer';
import usercheck from 'src/services/usercheck';

import Bookings from './components/Bookings';
import ClientNeedOffers from './components/ClientNeedOffers';
import OffersStandalone from './components/OffersStandalone';

const FreelancerBookings = () => {
  return (
    <PageContainer title="Freelancer Booking requests" description="Manage your bookings">
      <Box>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant='h2' align='center' marginBottom={2}>
              My Leads And Offers
            </Typography>
            <Typography variant='subtitle1' align='center' marginBottom={2}>
              Freelancer and Client booking discussions and offers.
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={6}>
                <Bookings fetchBy="freelancer" />
              </Grid>
              <Grid item xs={12} lg={6}>
                <ClientNeedOffers fetchBy="freelancer" />
              </Grid>
              <Grid item xs={12} lg={6}>
                <OffersStandalone fetchBy="freelancer" />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </PageContainer>
  );
};

export default usercheck(FreelancerBookings);
