import React, { useState, useEffect } from 'react';
import { Box, Tooltip, IconButton, Dialog, TextField, Table, TableBody, TableRow, TableCell, TableHead, Button, DialogTitle, DialogContent, Grid, Typography } from '@mui/material';
import { useUserContext } from '../../../contexts/Contexts';
import { createDeal, createBookingMessage } from '../../../services/message.service'; 
import { isAfter, format, parseISO } from 'date-fns';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';



const MakeDealModal = ({ isOpen, onClose, offerData, priceRows }) => {
  const { clientData, userData, token } = useUserContext();
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [agreementText, setAgreementText] = useState('');
  const [loading, setLoading] = useState(false);
  const [isSaved, setIsSaved] = useState(false);

  const renderTooltip = (texts) => (
    <Tooltip
      title={
        <React.Fragment>
          {texts.map((text, index) => (
            <Typography key={index} variant="body2">{text}</Typography>
          ))}
        </React.Fragment>
      }
    >
      <IconButton>
        <HelpOutlineIcon />
      </IconButton>
    </Tooltip>
  );


  const handleSubmit = async () => {
    setLoading(true);
    const dealData = {
        start_date: format(parseISO(offerData.start_date), 'yyyy-MM-dd'),
        end_date: format(parseISO(offerData.end_date), 'yyyy-MM-dd'),
        agreementText,
        offerId: offerData.id,
        status: 'new',
    };
    const deal = await createDeal(dealData, token);
    if (deal.data) {
      setIsSaved(true);
      setLoading(false);
    } else {
      //ERROR MESSAGE
    }

    if (offerData !== null) {
      const messageText = `I have created a deal. Please check it out. Agreement will be sent by Because Mondays for signing. `;
      const newMessageData = {
        text: messageText,
        ...(offerData.booking_request_id ? { booking_request_id: offerData.booking_request_id } : { client_need_id: offerData.client_need_id }),
        sender_id: userData.id,
        sender_type: clientData ? "client" : "freelancer",
        freelancer_id: offerData.freelancer_id,
        client_id: clientData.id,
      };
      await createBookingMessage(newMessageData, token);
    }
    
};

  
  if (!offerData) {
    return <div>Loading...</div>
  }

  if (isSaved) {
    return (
        <Dialog open={isOpen} onClose={onClose} onBackdropClick={onClose}>
            <DialogTitle>Your deal is created</DialogTitle>
            <DialogContent>
                <Typography variant="h6" marginBottom={3}>The deal has been successfully saved. 
                You will be contacted by Because Mondays and a contract will be sent out to sign via Scrive / StartupTools.
                Check your email within 24 hours to sign the deal.</Typography>
                <Button variant="contained" color="primary" onClick={onClose}>
                    Close
                </Button>
            </DialogContent>
        </Dialog>
    );
}


  return (
      <Dialog open={isOpen} onClose={onClose} onBackdropClick={onClose}>
          <DialogTitle>Make a Deal based on this offer
          </DialogTitle>
          <DialogContent>
            <Typography variant="body2">
              <strong>Freelancer: </strong> {offerData.firstname} <br/><br/>
              <strong>Freelancer offer: </strong>{offerData.message}
            </Typography>
            <Typography variant='h6' marginTop={3}>Prices:</Typography>
            <Table size="small"  style={{ marginBottom: 10 }}>
              <TableHead>
                <TableRow>
                  <TableCell align="left" style={{ paddingLeft: 0 }}><strong>Description</strong></TableCell>
                  <TableCell align="right"><strong>Price</strong></TableCell>
                  <TableCell><strong>Type</strong></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {priceRows.map((price, index) => (
                  <TableRow key={index} style={{ backgroundColor: index % 2 === 0 ? '#f5f5f5' : 'transparent' }}>
                    <TableCell component="th" scope="row"  style={{ paddingLeft: 0 }}>
                      {price.price_description}
                    </TableCell>
                    <TableCell align="right">
                      {`${price.price} ${price.currency}`}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {price.price_type}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
              <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box display="flex" justifyContent="flex-end" alignItems="center" margin={0}>
                      {renderTooltip([
                        "Dates are pre-populated from the freelancers offer. If you need to change these, please contact the freelancer to update the offer."
                      ])}
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                      <TextField 
                          fullWidth
                          label="Start Date" 
                          value={offerData.start_date ? format(parseISO(offerData.start_date), 'yyyy-MM-dd') : ''}                          type="date"
                          onChange={(e) => setStartDate(e.target.value)} 
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            pattern: "\\d{4}-\\d{2}-\\d{2}"
                          }}          
                          disabled   
                      />
                  </Grid>
                  <Grid item xs={6}>
                      <TextField 
                          fullWidth
                          label="End Date" 
                          value={offerData.end_date ? format(parseISO(offerData.end_date), 'yyyy-MM-dd') : ''}
                          type="date"
                          onChange={(e) => setEndDate(e.target.value)} 
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            pattern: "\\d{4}-\\d{2}-\\d{2}"
                          }}
                          disabled
                      />
                  </Grid>
                  <Grid item xs={12}>
                      <TextField 
                          fullWidth
                          multiline
                          rows={4}
                          label="Agreement Text" 
                          value={agreementText} 
                          onChange={(e) => setAgreementText(e.target.value)} 
                          disabled={loading}
                      />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body2" marginBottom={2}>
                      By submitting this form, a contract will be sent out to both parties. When the contract is signed
                      this deal will change status to signed and your work can begin.
                    </Typography>
                      <Button variant="contained" color="primary" onClick={handleSubmit}>
                          Make Deal
                      </Button>
                  </Grid>
              </Grid>
          </DialogContent>
      </Dialog>
  );
}

export default MakeDealModal;

