import { callExternalApi } from "./external-api.service";

const daymonApiServerUrl = process.env.REACT_APP_DAYMON_ORIGIN_URL;
const daymonApiServerWebSocket = process.env.REACT_APP_DAYMON_ORIGIN_SOCKET;

export const analyseCV = async (pdfFile) => {
  const formData = new FormData();
  formData.append('pdf', pdfFile);

  const config = {
    url: `${daymonApiServerUrl}/api/messages/upload-cv-pdf`,
    method: "POST",
    data: formData,  
  };

  try {
    const response = await callExternalApi({ config });
    const taskId = response.data.taskId;

    // Return a promise that will resolve when the WebSocket receives the data
    console.log("Websocket URL:", daymonApiServerWebSocket);
    return new Promise((resolve, reject) => {
      const ws = new WebSocket(daymonApiServerWebSocket);
      let heartbeatInterval = null;


      ws.onopen = () => {
        console.log('WebSocket connection established');
        ws.send(JSON.stringify({ action: 'register', taskId: taskId }));

        // Set up a heartbeat interval to keep the connection alive
        heartbeatInterval = setInterval(() => {
          ws.send(JSON.stringify({ action: 'heartbeat', taskId: taskId }));
        }, 25000); // Send a heartbeat every 25 seconds
        
      };

      ws.onmessage = (event) => {
        const message = JSON.parse(event.data);
        console.log("Got CV Result:", JSON.stringify(message));
        if (message.taskId === taskId) {
          resolve(message);
          ws.close();
        }
      };

      ws.onerror = (error) => {
        console.error('WebSocket error:', error);
        reject(error);
        ws.close();
      };

      ws.onclose = () => {
        clearInterval(heartbeatInterval); // Clear the heartbeat interval
        reject(new Error('WebSocket closed without receiving data'));
      };
    });
  } catch (error) {
    console.error('Error uploading CV:', error);
    throw error;
  }
};