import React, { useState, useEffect } from 'react';
import { FormControlLabel, Checkbox, Divider, Typography, Grid, TextField, Button, List, ListItem, ListItemText, ListItemSecondaryAction, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { getFreelancerExperiences, createFreelancerExperience, updateFreelancerExperience, deleteFreelancerExperience } from "../../../services/message.service";
import DashboardCard from '../../../components/shared/DashboardCard';
import { useUserContext } from '../../../contexts/Contexts';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CircularProgress from '@mui/material/CircularProgress';

const Experiences = ({ cvData }) => {
    const { freelancerData, token } = useUserContext();
    const [experiences, setExperiences] = useState([]);
    const [selectedExperience, setSelectedExperience] = useState(null);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [experienceToDelete, setExperienceToDelete] = useState(null);
    const [isSaved, setIsSaved] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [isCurrentJob, setIsCurrentJob] = useState(false);
    const [openFormDialog, setOpenFormDialog] = useState(false);
    const [experienceData, setExperienceData] = useState({
        company_name: '',
        job_title: '',
        start_date: '',
        end_date: '',
        description: '',
        freelancer_id: freelancerData ? freelancerData.id : null,
    });

    useEffect(() => {
        const fetchExperiences = async () => {
            if (freelancerData) {
                setExperienceData({
                    company_name: '',
                    job_title: '',
                    start_date: '',
                    end_date: '',
                    description: '',
                    freelancer_id: freelancerData.id, 
                });
        }
        };
        fetchExperiences();
    }, []);

  useEffect(() => {
      const fetchExperiences = async () => {
          if (freelancerData) {
              const allExperiences = await getFreelancerExperiences(freelancerData.id, token);
              setExperiences(allExperiences.data);
          }
      };
      fetchExperiences();
      if (isSaved) {
        setTimeout(() => {
            setIsSaved(false);
        }, 2000);
      }
  }, [isSaved, freelancerData, token]);

  useEffect(() => {
    const handleCvDataChange = async () => {
      if (cvData && cvData.workExperience) {
        const tmpExperiences = await getFreelancerExperiences(freelancerData.id, token);

        if (tmpExperiences.data) {   
            console.debug("Experiences before delete", tmpExperiences);
            const deletePromises = tmpExperiences.data.map((experience) => 
            deleteFreelancerExperience(experience.id, token)
            );      
            // Wait for all delete operations to complete
            await Promise.all(deletePromises);
        }
  
        // Create new experiences from cvData.workExperience
        const createPromises = cvData.workExperience.map((newExperience) => 
          createFreelancerExperience({
            ...newExperience,
            end_date: newExperience.end_date === '' ? null : newExperience.end_date,
            freelancer_id: freelancerData.id,
          }, token)
        );
  
        // Wait for all create operations to complete
        await Promise.all(createPromises);
  
        // Fetch and set the updated experiences list
        const allExperiences = await getFreelancerExperiences(freelancerData.id, token);
        setExperiences(allExperiences.data);
      }
    };
  
    handleCvDataChange();
  }, [cvData]);


const formatDate = (date) => {
  return date ? new Date(date).toISOString().split('T')[0] : "";
};

const handleCurrentJobChange = (event) => {
    const checked = event.target.checked;
    setIsCurrentJob(checked);
    setExperienceData(prevState => ({
      ...prevState,
      end_date: checked ? null : prevState.end_date // Set end_date to null if checked
    }));
  };

  const handleExperienceChange = (e) => {
      const { name, value } = e.target;
      setExperienceData(prevState => ({
          ...prevState,
          [name]: value
      }));
  };

  const handleSubmit = async () => {
    setIsLoading(true); // Start loading
    const experienceToSubmit = {
        ...experienceData,
        end_date: experienceData.end_date || null,
    };

    try {
        if (selectedExperience) {
            await updateFreelancerExperience(selectedExperience.id, experienceToSubmit, token);
        } else {
            await createFreelancerExperience(experienceToSubmit, token);
        }
        const allExperiences = await getFreelancerExperiences(freelancerData.id, token);
        setExperiences(allExperiences.data);
        setIsSaved(true);
        setShowForm(false);
        setSelectedExperience(null);
        setIsCurrentJob(false);

    } catch (error) {
        console.error('Error updating or creating experience:', error);
    }
    setIsLoading(false); // Stop loading
    setExperienceData({
        company_name: '',
        job_title: '',
        start_date: '',
        end_date: '',
        description: '',
        freelancer_id: freelancerData.id,
    });
    setOpenFormDialog(false); 
  };

  const handleEditExperience = (experience) => {
      setSelectedExperience(experience);
      setExperienceData(experience);
      setShowForm(true);
      setIsCurrentJob(!experience.end_date);
      setOpenFormDialog(true); 
  };

  const handleCancel = () => {
      setExperienceData({
          company_name: '',
          job_title: '',
          start_date: '',
          end_date: '',
          description: '',
          freelancer_id: freelancerData.id,
      });
      setSelectedExperience(null);
      setShowForm(false);
      setIsCurrentJob(false);
      setOpenFormDialog(false);
  };

  const openDeleteModal = (experienceId) => {
      setExperienceToDelete(experienceId);
      setOpenDeleteDialog(true);
  };

  const closeDeleteModal = () => {
      setExperienceToDelete(null);
      setOpenDeleteDialog(false);
  };

  const confirmDelete = async () => {
      if (experienceToDelete) {
          await handleDeleteExperience(experienceToDelete);
          closeDeleteModal();
      }
  };

  const handleDeleteExperience = async (experienceId) => {
      try {
          await deleteFreelancerExperience(experienceId, token);
          const updatedExperiences = experiences.filter(experience => experience.id !== experienceId);
          setExperiences(updatedExperiences);
      } catch (error) {
          console.error('Error deleting experience:', error);
      }
      setSelectedExperience(null);
      setExperienceData({
          company_name: '',
          job_title: '',
          start_date: '',
          end_date: '',
          description: '',
          freelancer_id: freelancerData.id,
      });
  };

  // Render UI for experiences management
  return (
    <>
        <Typography variant="h4">
            My Experiences
        </Typography>
        <Typography variant="body2" marginTop={1}>
            Add your past work experiences.
        </Typography>

        {/* Experience list */}
        <Grid container spacing={3} marginTop={0}>
          <Grid item xs={12}>
            <List sx={{ padding: 0, '& .MuiListItem-root': { padding: 0 } }}>
                {
                  experiences && experiences.length > 0 ? (
                    experiences.map((experience, index) => (
                      <React.Fragment key={experience.id}>
                          <ListItem
                              sx={{ '&:hover': { backgroundColor: 'grey.100' } }}
                              secondaryAction={
                                  <>
                                      <IconButton edge="end" onClick={() => handleEditExperience(experience)}>
                                          <EditIcon />
                                      </IconButton>
                                      <IconButton edge="end" onClick={() => openDeleteModal(experience.id)}>
                                          <DeleteIcon />
                                      </IconButton>
                                  </>
                              }
                          >
                            <ListItemText
                              primary={`${experience.company_name} - ${experience.job_title}`}
                              secondary={`${formatDate(experience.start_date)} - ${experience.end_date ? formatDate(experience.end_date) : "Present"}`}
                            />

                          </ListItem>
                          {index < experiences.length - 1 && <Divider />}
                      </React.Fragment>
                    ))
                  ) : (
                    <Typography variant="body1">No experiences found.</Typography>
                  )
                }
            </List>

            {/* Add/Edit Experience Form */}
            <Dialog
                open={openFormDialog}
                onClose={() => setOpenFormDialog(false)}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">{selectedExperience ? 'Edit Experience' : 'Add New Experience'}</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2} marginTop={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Company Name"
                                    name="company_name"
                                    value={experienceData.company_name}
                                    onChange={handleExperienceChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Job Title"
                                    name="job_title"
                                    value={experienceData.job_title}
                                    onChange={handleExperienceChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Start Date"
                                    name="start_date"
                                    type="date"
                                    InputLabelProps={{ shrink: true }}
                                    value={formatDate(experienceData.start_date)}
                                    onChange={handleExperienceChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="End Date"
                                name="end_date"
                                type="date"
                                InputLabelProps={{ shrink: true }}
                                value={formatDate(experienceData.end_date)}
                                onChange={handleExperienceChange}
                                disabled={isCurrentJob} // Disable if it's the current job
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={isCurrentJob}
                                        onChange={handleCurrentJobChange}
                                        name="currentJob"
                                        color="primary"
                                    />
                                }
                                label="I currently work here"
                            />
                        </Grid>                
                        <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Description"
                                    name="description"
                                    value={experienceData.description}
                                    onChange={handleExperienceChange}
                                    multiline
                                    rows={4}
                                />
                        </Grid>
                    </Grid>
                    </DialogContent>
                <DialogActions>
                    <Button
                        variant="outlined"
                        onClick={handleCancel}
                        disabled={isLoading} // Optionally disable cancel when loading
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color={isSaved ? "success" : "primary"}
                        onClick={handleSubmit}
                        sx={{ marginRight: 1 }}
                        disabled={isLoading} // Disable the button when loading
                    >
                        {isLoading ? (
                            <CircularProgress size={24} color="inherit" />
                        ) : isSaved ? (
                            "Saved ✔"
                        ) : (
                            "Save"
                        )}
                    </Button>
                </DialogActions>
            </Dialog>
            <Button
                variant="contained"
                onClick={() => setOpenFormDialog(true)}
                sx={{ mt: 2 }}
            >
                Add New Experience
            </Button>

        </Grid>
      </Grid>
      {/* Delete Confirmation Dialog */}
      <Dialog
          open={openDeleteDialog}
          onClose={closeDeleteModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
      >
          <DialogTitle id="alert-dialog-title">{"Delete Experience"}</DialogTitle>
          <DialogContent>
              <DialogContentText id="alert-dialog-description">
                  Are you sure you want to delete this experience?
              </DialogContentText>
          </DialogContent>
          <DialogActions>
              <Button onClick={closeDeleteModal} color="primary">
                  Cancel
              </Button>
              <Button onClick={confirmDelete} color="primary" autoFocus>
                  Confirm
              </Button>
          </DialogActions>
      </Dialog>

      </>
  );
};

export default Experiences;