import React, { useState } from 'react';
import {
  Grid,
  Typography,
  Link,
  StepContent,
  Stepper,
  Step,
  StepLabel,
  StepButton,
  Box,
  Button
} from '@mui/material';
import DashboardCard from '../../../components/shared/DashboardCard';

const steps = [
  {
    label: "Kickstart with a Mentoring Session",
    description: "Jumpstart your business journey by booking a mentoring session. We'll help you fine-tune your business model and identify your niche.",
    links: [{ label: "Book a Meeting", url: "https://meetings.hubspot.com/martin-hollstrand/book-me" }]
  },
  {
    label: "Finalize Your Company Essentials",
    description: "Decide on your company name, SNI codes, and a compelling description. You'll need these for registration.",
    links: [{ label: "Visit Website for SNI Codes", url: "https://sni2007.scb.se" }]
  },
  {
    label: "Initiate Your Startup Meeting",
    description: "Book a personalized startup meeting with your accountant. You'll set up your company registration at Verksamt and open a bank account. SEB is our recommended bank.",
    links: [
      { label: "Book a Meeting", url: "https://calendly.com/pernilla-6/meeting" },
      { label: "Verksamt", url: "https://www.verksamt.se" },
      { label: "SEB", url: "https://seb.se/foretag/erbjudande-och-bli-foretagskund/bli-foretagskund" }
    ]
  },
  {
    label: "Set Up Tax Registration",
    description: "Once your company is registered with the bank and Bolagsverket, book a meeting to apply for Moms and F-Skatt with Skatteverket.",
    links: [
      { label: "Book a Meeting", url: "https://calendly.com/pernilla-6/meeting" },
      { label: "Verksamt", url: "https://www.verksamt.se" }
    ]
  },
  {
    label: "Complete Your Onboarding",
    description: "Let's finalize your company setup. You'll be introduced to Fortnox for bookkeeping and Pleo for a company debit card. Book an onboarding meeting to walk through the process.",
    links: [
      { label: "Book a Meeting", url: "https://calendly.com/pernilla-6/meeting" },
      { label: "Fortnox", url: "https://www.fortnox.se" },
      { label: "Pleo", url: "https://www.pleo.io/se" }
    ]
  },
  {
    label: "Register with Because Mondays",
    description: "To leverage Because Mondays services, we need details about your newly created company. Please fill them in on the provided link.",
    links: [{ label: "Visit Profile", url: "https://www.becausemondays.com/plaza/profile" }]
  },
  {
    label: "Secure Your Business",
    description: "Secure your venture with essential business insurance. We recommend Länsförsäkringar for your needs.",
    links: [{ label: "Get Insurance", url: "https://www.lansforsakringar.se/foretag/forsakring/branschforsakring/tjansteforetag/" }]
  },
  {
    label: "Plan Your Financial Future",
    description: "It's wise to set up pension and sick leave insurance plans as you embark on your entrepreneurial journey. We recommend Avanza for these financial services.",
    links: [{ label: "Explore Options", url: "https://www.avanza.se/pension/foretag/tjanstepension/teckna-tjanstepension-egenforetagare.html" }]
  },
  {
    label: "First Monthly Review",
    description: "Conclude your first month in business with a financial review. Book a meeting with your personal accountant to go over the monthly report.",
    links: [{ label: "Book a Meeting", url: "https://calendly.com/pernilla-6/meeting" }]
  },
  {
    label: "Pre-End-of-Year Planning",
    description: "Let's strategize for the year-end. Book a session with your accountant in the last quarter to set a plan.",
    links: [{ label: "Book a Meeting", url: "https://calendly.com/pernilla-6/meeting" }]
  },
  {
    label: "Year-End Financial Statement",
    description: "Your yearly financial statement is crucial and needs to be submitted to authorities. Plan and sign off on this document by booking a meeting with your personal accountant.",
    links: [{ label: "Book a Meeting", url: "https://calendly.com/pernilla-6/meeting" }]
  },
];


const PremiumPlusOnboarding = () => {
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const CustomStepIcon = ({ active, completed, icon }) => {
    return (
      <div
        style={{
          width: '28px',
          height: '28px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '50%',
          backgroundColor: 'blue',
          color: 'white',
        }}
      >
         {completed ? '✔' : icon}
      </div>
    );
  };
  


  return (
    <DashboardCard title="Process to start your company">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="body1">
            To set up your company, just follow these steps and we will help you on the way.
            While you are in setup mode for Premium+, you will be in the Premium mode so you already now can 
            create your freelancer profile and find your next assignment.
          </Typography>
          <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((step, index) => (
            <Step key={step.label}>
              <StepLabel StepIconComponent={CustomStepIcon}>
                <Typography variant="h6">
                {step.label}
                </Typography>
              </StepLabel>
              <StepContent>
                <Typography>{step.description}</Typography>
                <Typography variant="body2">
                  {steps[activeStep].links ? (
                    Array.isArray(steps[activeStep].links) ? (
                      steps[activeStep].links.map((linkObj, index) => (
                        <div key={index} style={{ marginBottom: '8px' }}>
                          <Link href={linkObj.url} target="_blank" rel="noopener">
                            {linkObj.label}
                          </Link>
                        </div>
                      ))
                    ) : (
                      <Link href={steps[activeStep].links.url} target="_blank" rel="noopener">
                        {steps[activeStep].links.label}
                      </Link>
                    )
                  ) : (
                    "" // NO LINKS FOR THIS STEP
                  )}
                </Typography>

                <Box sx={{ mb: 2 }}>
                  <div>
                    <Button
                      variant="contained"
                      onClick={handleNext}
                      disabled={index === steps.length - 1}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      {index === steps.length - 1 ? 'Done ✔' : 'Continue'}
                    </Button>
                    <Button
                      disabled={index === 0}
                      onClick={handleBack}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      Back
                    </Button>
                  </div>
                </Box>
              </StepContent>
            </Step>
          ))}
        </Stepper>
        {activeStep === steps.length && (
          <Paper square elevation={0} sx={{ p: 3 }}>
            <Typography>All steps completed - you&apos;re finished</Typography>
            <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
              Reset
            </Button>
          </Paper>
        )}
        </Grid>
      </Grid>
    </DashboardCard>
  );
};

export default PremiumPlusOnboarding;
