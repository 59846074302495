import React, { useState, useEffect } from 'react';
import { Typography, Grid, Box, Button, Avatar, CircularProgress, Alert } from '@mui/material';
import axios from 'axios';
import { Image } from 'cloudinary-react';
import { createFreelancer, getFreelancerServiceById, updateFreelancer, getUserByAuth0Id, getFreelancerByUserId } from "../../../services/message.service";
import { useAuth0 } from "@auth0/auth0-react";
import { useUserContext } from '../../../contexts/Contexts';

import DashboardCard from '../../../components/shared/DashboardCard';


const ProfileImage = () => {
  const { freelancerData, setFreelancerData, token } = useUserContext();
  const [submitButtonState, setSubmitButtonState] = useState({ text: 'Save', color: 'primary' });
  const [isUploading, setIsUploading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [error, setError] = useState('');

  //TODO Make secure, and only possible from this origin
  const handleImageUpload = async (event) => {
    setIsUploading(true);
    setError("");
    const formData = new FormData();
    formData.append('file', event.target.files[0]);
    formData.append('upload_preset', 'ydr1m7ur'); 

    try {
      const response = await axios.post('https://api.cloudinary.com/v1_1/dldyopnvj/image/upload', formData); 
      const updatedFreelancerData = {
        ...freelancerData,
        profile_image: response.data.secure_url,
      };
      setFreelancerData(updatedFreelancerData);
      const updated = await updateFreelancer(freelancerData.id, updatedFreelancerData, token);
      console.log("updated", updated);
      if (updated) {
        setIsUploading(false);
      }
    } catch (error) {
        setError(`Error uploading image: Is it too big? Use max 10 MB`);
        setIsUploading(false);
        return; 
    }
  };

  return (
    <>
    {freelancerData && (
      <Grid container spacing={0} marginTop={0}>
        <Grid item xs={12}>
          <Box position="relative" display="inline-flex">
            <Avatar
              alt="Freelancer"
              variant="square"
              src={freelancerData.profile_image}
              sx={{ width: '100%', height: 'auto' }}
            />
            {isUploading && (
              <>
                <Box
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Adjust the color and opacity to your preference
                  }}
                />
                <CircularProgress
                  size={68} // Adjust the size to fit your Avatar size
                  sx={{
                    color: 'primary.main',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-34px', // Adjust the value to be half of CircularProgress size
                    marginLeft: '-34px', // Adjust the value to be half of CircularProgress size
                  }}
                />
              </>
            )}
          </Box>
        </Grid>
          <Grid item xs={12} marginTop={2}>
            <Button
              variant="contained"
              component="label"
              size="large"
              fullWidth
            >
              Upload Image
              <input
                type="file"
                hidden
                onChange={handleImageUpload}
              />
            </Button>
            {selectedFile && (
              <Typography variant="caption" display="block" gutterBottom>
                {selectedFile.name}
              </Typography>
            )}
            <Typography variant="body2" display="block" marginTop={2}>
              Max 10 MB
            </Typography>
          </Grid>
        {/* <Grid item xs={12} marginTop={3}>
          <Button 
            variant="contained" 
            color={submitButtonState.color} 
            onClick={handleSubmit}
            disabled={isUploading}>
              {isUploading ? 'Uploading...' : submitButtonState.text}
            </Button>
        </Grid> */}
        {error && (
          <Grid item xs={12}>
            <Alert severity="error">{error}</Alert>
          </Grid>
        )}
      </Grid>
      )}
    </>
  );
};

export default ProfileImage;
