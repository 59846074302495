import { Grid, Box, Typography } from '@mui/material';
import React from 'react';
import PageContainer from 'src/components/container/PageContainer';
import CreateOffer from './components/CreateOffer';
import usercheck from 'src/services/usercheck';
import ClientManager from './components/ClientManager';
import AcceptOffer from './components/AcceptOffer';


const ShowOffer = () => {

  return (
    <>
      <div style={{
          backgroundColor: 'white',
          padding: '50px',
      }} >
          <Typography variant="h1" align='center'>Your Offer</Typography>
          <Typography variant="subtitle1" align='center' marginBottom={3}>This is an offer you have received</Typography>
        <AcceptOffer />
      </div>
    </>
  );
};
export default ShowOffer;