import React, { lazy } from 'react';
import FreelancerList from './components/FreelancerList';


const Freelancers = () => {
  return (
    <>
      
      <div style={{
          backgroundColor: 'white',
          padding: '50px',
      }} >
        {/* <FullSearch/> */}
        <FreelancerList showCategories={true} showSearch={true}/>
      </div>
    </>
    
  );
};

export default Freelancers;
