import React from 'react';
import { Grid, Typography, Card, CardContent, CardMedia, Button, Divider } from '@mui/material';
import BusinessBikeImage from 'src/assets/images/varios/520056.avif';
import PleoImage from 'src/assets/images/varios/pleo.webp';
import FriskvardImage from 'src/assets/images/varios/Snowboarding.jpg';


const Benefits = () => {
  const benefits = [
    {
      title: 'Bike as a Benefit through Business Bike',
      description: 'We have an agreement with Business Bike, so you can buy a bike at your local dealer and pay monthly for it as a benefit before some tax. Read more on the link for how it works, and calculate your cost.',
      link: 'https://www.businessbike.se', // Replace with actual link
      image: BusinessBikeImage // Optional
    },
    {
      title: 'Friskvårdsbidrag',
      description: 'Contribute to your well-being by paying health and fitness costs up to 5000 SEK without tax. You can manage this in your Pleo app',
      link: 'https://www.example.com/friskvardsbidrag', // Replace with actual link
      image: FriskvardImage
    },
    {
      title: 'Pleo',
      description: 'Pay with a corporate debit card all business expenses related to you freelancing. Talk to the support staff to get it set up. Check more on Pleo web site.',
      link: 'https://www.pleo.io/se', // Replace with actual link
      image: PleoImage
    },
    {
      title: 'Xmas gifts with company money',
      description: 'Yearly you can buy a christmas present to yourself of up to 500 SEK from your cost center without paying tax. It has to be a gift and payed with invoice targeted Because Mondays.',
    }
  ];

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card sx={{ p: 2, textAlign: 'left', color: 'text.primary' }}>
          <CardContent>
            <Typography variant='h3'>Benefits</Typography>
            {benefits.map((benefit, index) => (
              <div key={index}>
                <Typography variant="subtitle1" sx={{ fontSize: '1.5rem', mb: 1 }}>
                  {benefit.title}
                </Typography>
                {benefit.image && (
                  <CardMedia
                    sx={{ height: 150 }}
                    image={benefit.image}
                    title={benefit.title}
                  />
                )}
                <Typography variant="body1" sx={{ mb: 1, mt: 1 }}>
                  {benefit.description}
                </Typography>
                {benefit.link && (
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ mt: 1 }}
                  href={benefit.link}
                  target="_blank"
                  rel="noopener"
                >
                  Learn More
                </Button>
                )}
                {index !== benefits.length - 1 && <Divider sx={{ mt: 2, mb: 2 }} />}
              </div>
            ))}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default Benefits;
