import React, { useEffect, useState } from "react";
import { updateUserSubscription, addUserSubscription, getSubscriptions, getUserSubscriptions } from "../../../services/message.service";
import { Typography, Grid, Button } from '@mui/material';
import DashboardCard from '../../../components/shared/DashboardCard';
import { useUserContext } from 'src/contexts/Contexts';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


// TODO make sure that subscriptions only change when cancellation periods end. Add periods to the subscriptions and build business rules for that

const Subscriptions = ( { onNext }) => {
  const [message, setMessage] = useState("");
  const [subscriptions, setSubscriptions] = useState([]);
  const { userData, userSubscriptionData, setUserSubscriptionData, freelancerData, isFreelancer, isClient, token } = useUserContext();
  const [userSubscription, setUserSubscription] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  
  useEffect(() => {
    let isMounted = true;

    const initialize = async () => {
      if (userData) {
        let type = "freelancer";
        if (isClient) {
          type = "client";
        }
        const { data, error } = await getSubscriptions(type, token);
        if (userData && userData.id !== null) {
          const userSubscriptionDataResponse = await getUserSubscriptions(userData.id ,token);
          setUserSubscription(userSubscriptionDataResponse);
          setUserSubscriptionData(userSubscriptionDataResponse.data);
        }
        if (data) {
          setSubscriptions(data);
        }  
        if (error) {
          setMessage(JSON.stringify(error, null, 2));
        }  
      }
      if (!isMounted) {
        return;
      }
    };
    initialize();

    return () => {
      isMounted = false;
    };
  }, [userData]);

  const handleSubscriptionSelection = async () => {
    if (selectedSubscription) {
      const start_date = new Date();
      if (userSubscription.data) {
        if (selectedSubscription.id !== userSubscription.data.subscription_id) {
          try {
            //Ends the old subscription and creates a new
            await updateUserSubscription(userSubscription.data.id, userData.id, userSubscription.data.subscription_id, start_date, start_date, token);
            const userSubscriptionDataResponse = await addUserSubscription(userData.id, selectedSubscription.id, start_date, null, token);
            setUserSubscription(userSubscriptionDataResponse);
            setUserSubscriptionData(userSubscriptionDataResponse.data);
            onNext();
          } catch (error) {
            console.error('Error saving subscription:', error);
          }
        } else {
          // Do nothing
          onNext();
        }
      } else {
        // If the user does not have a current subscription, just add a new one
        try {
          // const userSubscriptionDataResponse = await getUserSubscriptions(userData.id, token);
          const userSubscriptionDataResponse = await addUserSubscription(userData.id, selectedSubscription.id, start_date, null, token);
          setUserSubscription(userSubscriptionDataResponse);
          setUserSubscriptionData(userSubscriptionDataResponse.data);
          onNext();
        } catch (error) {
          console.error('Error saving subscription:', error);
        }
      }
    }
  };

  const handleOpenModal = (subscription) => {
    setSelectedSubscription(subscription);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedSubscription(null);
  };  
  
  if (!userData) {
    return <div>Loading...</div>; 
  }

  return (
    <>
    {subscriptions.map((subscription, index) => (
     <Grid 
      item
      xs={12}
      sm={subscriptions.length === 1 ? 12 : 4}
      md={subscriptions.length === 1 ? 12 : 4}
      key={index}
      sx={{
        display: 'flex',
        justifyContent: 'center' // This will center the card when the screen size is small or medium
      }}
    >
      <DashboardCard title={subscription.name} subtitle={subscription.description} sx={{ maxWidth: subscriptions.length === 1 ? 'none' : '345px' }}>
        {subscription.features.split('\n').map((line, index) => (
          <React.Fragment key={index}>
            {line}
            <br />
          </React.Fragment>
        ))}
          <Typography variant='h6' marginTop={4}>Pricing</Typography>
          ☻ {parseInt(subscription.monthly_fee)} SEK Monthly<br/>
          ☻ {parseInt(subscription.booking_fee)}% of your booking value

          
            {(subscription.cancellation_period === 0) ? (
              <Typography variant="body2" marginTop={3} align="center">
                No cancellation periods
              </Typography>
            ) : 
              <Typography variant="body2" marginTop={3} align="center">
                Cancellation period {parseInt(subscription.cancellation_period)} Months
              </Typography>
            }
            
          

        <Typography align='center' marginTop={5}>
          {(userSubscription.data && subscription.id === userSubscription.data.subscription_id) ? (
            <Button variant="contained" color="secondary"   onClick={() => handleOpenModal(subscription)}>
              You Selected {subscription.name} ✔
            </Button>
          ) : 
          <Button variant="contained" color="primary"   onClick={() => handleOpenModal(subscription)}>
            Choose {subscription.name}
          </Button>}

          </Typography>
      </DashboardCard>
    </Grid>
    ))}

    <Dialog
      open={isModalOpen}
      onClose={handleCloseModal}
    >
      <DialogTitle>{"Confirm Subscription"}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to choose {selectedSubscription?.name} subscription?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseModal} color="primary">
          Cancel
        </Button>
        <Button 
          onClick={() => {
            handleSubscriptionSelection();
            handleCloseModal();
          }} 
          color="primary"
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>

    </>
  );
};

export default Subscriptions;