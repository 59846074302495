import React, { useState, useEffect } from 'react';
import {
  Typography, Grid, TextField, Button, List, ListItem, ListItemText,
  ListItemSecondaryAction, IconButton, Dialog, DialogActions, DialogContent,
  DialogTitle, CircularProgress, Divider
} from '@mui/material';
import {
  getFreelancerEducations, createFreelancerEducation, updateFreelancerEducation,
  deleteFreelancerEducation
} from "../../../services/message.service";
import DashboardCard from '../../../components/shared/DashboardCard';
import { useUserContext } from '../../../contexts/Contexts';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const Educations = ({ cvData }) => {
  const { freelancerData, token } = useUserContext();
  const [educations, setEducations] = useState([]);
  const [selectedEducation, setSelectedEducation] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [educationToDelete, setEducationToDelete] = useState(null);
  const [isSaved, setIsSaved] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openFormDialog, setOpenFormDialog] = useState(false);
  const [educationData, setEducationData] = useState({
    institution_name: '',
    degree: '',
    field_of_study: '',
    start_date: '',
    end_date: '',
    description: '',
    freelancer_id: freelancerData ? freelancerData.id : null,
  });

  useEffect(() => {
    if (freelancerData) {
      setEducationData({
        institution_name: '',
        degree: '',
        field_of_study: '',
        start_date: '',
        end_date: '',
        description: '',
        freelancer_id: freelancerData.id,
      });
    }
  }, []);

  useEffect(() => {
    const fetchEducations = async () => {
      if (freelancerData) {
        const allEducations = await getFreelancerEducations(freelancerData.id, token);
        setEducations(allEducations.data);
      }
    };
    fetchEducations();
    if (isSaved) {
      setTimeout(() => {
        setIsSaved(false);
      }, 2000);
    }
  }, [isSaved, token]);

  useEffect(() => {
    const handleCvDataChange = async () => {
        if (cvData && cvData.educations) {
          const tempEducations = await getFreelancerEducations(freelancerData.id, token);
          if (tempEducations.data) {
              for (const education of tempEducations.data) {
                  await deleteFreelancerEducation(education.id, token);
              }
            }

            // Create new experiences from cvData.workExperience
            for (const newEducation of cvData.educations) {
              await createFreelancerEducation({
                  ...newEducation,
                  end_date: newEducation.end_date === '' ? null : newEducation.end_date,
                  freelancer_id: freelancerData.id,
              }, token);
            }

            // Fetch and set the updated experiences list
            const allEducations = await getFreelancerEducations(freelancerData.id, token);
            setEducations(allEducations.data);
        }
    };

      handleCvDataChange();
  }, [cvData]); 



  const formatDate = (date) => {
    return date ? new Date(date).toISOString().split('T')[0] : "";
  };

  const handleEducationChange = (e) => {
    const { name, value } = e.target;
    setEducationData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    const educationToSubmit = {
      ...educationData,
      end_date: educationData.end_date || null,
    };

    try {
      if (selectedEducation) {
        await updateFreelancerEducation(selectedEducation.id, educationToSubmit, token);
      } else {
        await createFreelancerEducation(educationToSubmit, token);
      }
      const allEducations = await getFreelancerEducations(freelancerData.id, token);
      setEducations(allEducations.data);
      setIsSaved(true);
      setSelectedEducation(null);
    } catch (error) {
      console.error('Error updating or creating education:', error);
    }
    setIsLoading(false);
    setEducationData({
      institution_name: '',
      degree: '',
      field_of_study: '',
      start_date: '',
      end_date: '',
      description: '',
      freelancer_id: freelancerData.id,
    });
    setOpenFormDialog(false);
  };

  const handleEditEducation = (education) => {
    setSelectedEducation(education);
    setEducationData(education);
    setOpenFormDialog(true);
  };

  const handleCancel = () => {
    setEducationData({
      institution_name: '',
      degree: '',
      field_of_study: '',
      start_date: '',
      end_date: '',
      description: '',
      freelancer_id: freelancerData.id,
    });
    setSelectedEducation(null);
    setOpenFormDialog(false);
  };

  const openDeleteModal = (educationId) => {
    setEducationToDelete(educationId);
    setOpenDeleteDialog(true);
  };

  const closeDeleteModal = () => {
    setEducationToDelete(null);
    setOpenDeleteDialog(false);
  };

  const confirmDelete = async () => {
    if (educationToDelete) {
      await handleDeleteEducation(educationToDelete);
      closeDeleteModal();
    }
  };

  const handleDeleteEducation = async (educationId) => {
    try {
      await deleteFreelancerEducation(educationId, token);
      const updatedEducations = educations.filter(education => education.id !== educationId);
      setEducations(updatedEducations);
    } catch (error) {
      console.error('Error deleting education:', error);
    }
    setSelectedEducation(null);
    setEducationData({
      institution_name: '',
      degree: '',
      field_of_study: '',
      start_date: '',
      end_date: '',
      description: '',
      freelancer_id: freelancerData.id,
    });
  };

  // Render UI for educations management
  return (
    <>
      <Typography variant="h4">
        My Educations
      </Typography>
      <Typography variant="body2" marginTop={1}>
        Add your educational background.
      </Typography>

      {/* Education list */}
      <Grid container spacing={3} marginTop={0}>
        <Grid item xs={12}>
          <List>
            {educations && educations.length > 0 ? (
              educations.map((education, index) => (
                <React.Fragment key={education.id}>
                  <ListItem
                    secondaryAction={
                      <>
                        <IconButton edge="end" onClick={() => handleEditEducation(education)}>
                          <EditIcon />
                        </IconButton>
                        <IconButton edge="end" onClick={() => openDeleteModal(education.id)}>
                          <DeleteIcon />
                        </IconButton>
                      </>
                    }
                  >
                    <ListItemText
                      primary={`${education.institution_name} - ${education.degree}`}
                      secondary={`${formatDate(education.start_date)} - ${education.end_date ? formatDate(education.end_date) : "Present"}`}
                    />
                  </ListItem>
                  {index < educations.length - 1 && <Divider />}
                </React.Fragment>
              ))
            ) : (
              <Typography variant="body1">No educations found.</Typography>
            )}
          </List>
        </Grid>
      </Grid>

      {/* Add/Edit Education Form */}
      <Dialog open={openFormDialog} onClose={() => setOpenFormDialog(false)}>
        <DialogTitle>{selectedEducation ? 'Edit Education' : 'Add New Education'}</DialogTitle>
        <DialogContent>
        <Grid container spacing={2} marginTop={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Institution Name"
                name="institution_name"
                value={educationData.institution_name}
                onChange={handleEducationChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Degree"
                name="degree"
                value={educationData.degree}
                onChange={handleEducationChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Field of Study"
                name="field_of_study"
                value={educationData.field_of_study}
                onChange={handleEducationChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Start Date"
                name="start_date"
                type="date"
                InputLabelProps={{ shrink: true }}
                value={formatDate(educationData.start_date)}
                onChange={handleEducationChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="End Date"
                name="end_date"
                type="date"
                InputLabelProps={{ shrink: true }}
                value={formatDate(educationData.end_date)}
                onChange={handleEducationChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Description"
                name="description"
                value={educationData.description}
                onChange={handleEducationChange}
                multiline
                rows={4}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            {isLoading ? <CircularProgress size={24} /> : 'Save'}
          </Button>
        </DialogActions>
      </Dialog>
      
      {/* Add New Education Button */}
      <Button
        variant="contained"
        onClick={() => setOpenFormDialog(true)}
        sx={{ mt: 2 }}
      >
        Add New Education
      </Button>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={openDeleteDialog}
        onClose={closeDeleteModal}
      >
        <DialogTitle>{"Delete Education"}</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete this education?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteModal} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmDelete} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Educations;