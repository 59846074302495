import React from "react";
import DashboardCard from '../../../components/shared/DashboardCard';
import { Typography, Stepper, Step, StepLabel, Box } from '@mui/material';
import { useUserContext } from 'src/contexts/Contexts';
import { Link as RouterLink } from 'react-router-dom'; // Import from react-router-dom




const CustomStepIcon = ({ active, completed, icon }) => {
  return (
    <div
      style={{
        width: '28px',
        height: '28px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50%',
        backgroundColor: completed ? '#22F56E' : active ? '#2051F7' : '#5A6A85',
        color: 'white',
      }}
    >
       {completed ? '✔' : icon}
    </div>
  );
};


const clientSteps = [
  { label: 'Find a Freelancer or Publish a Project', link: '/plaza/create_need' },
  { label: 'Interactive Discussions', link: '/plaza/client_needs' },
  { label: 'Confirm & Set Up the Deal', link: '/plaza/client_needs' },
  { label: 'Contract Signing', link: '/plaza/client_needs' },
  { label: 'Invoicing & Payments', link: '/plaza/client_needs' },
];

const freelancerSteps = [
  { label: 'Publish your profile', link: '/plaza/freelancer' },
  { label: 'Find Leads or Get Clients to request you services', link: '/plaza/needs' },
  { label: 'Interactive Discussions and Make an offer', link: '/plaza/freelancer-bookings' },
  { label: 'Client accepts the Deal', link: '/plaza/freelancer-bookings' },
  { label: 'Contract Signing', link: '/plaza/freelancer-bookings' },
  { label: 'Invoicing & Payments', link: '/plaza/freelancer-bookings' },
];

export const Welcome = () => {
  const { userData, setUserData, isFreelancer, token, userSubscriptionData, isClient } = useUserContext();
  let steps = isFreelancer ? freelancerSteps : clientSteps;

  return (
    <DashboardCard title="👋 Hello Valued Member!">
      <Typography variant="body1" sx={{ mb: 5 }}>
        <p>Welcome to Monday Plaza, where collaboration meets opportunity. Follow these steps to get started:</p>
      </Typography>

      <Box sx={{ width: '100%' }}>
        <Stepper activeStep={-1} alternativeLabel>
          {steps.map((step, index) => (
            <Step key={step.label}>
              {/* Wrap the StepLabel content with RouterLink */}
              <StepLabel StepIconComponent={() => CustomStepIcon({ icon: index + 1 })}>
                <RouterLink to={step.link} style={{ textDecoration: 'none', color: 'inherit' }}>
                  {step.label}
                </RouterLink>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>

      <Typography variant="body1" sx={{ mt: 5 }}>
        <p>Ready to dive in? Explore our platform. We're thrilled to have you on board!</p>
      </Typography>
    </DashboardCard>
  );
};
