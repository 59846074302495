import React, { lazy } from 'react';
import Hero from './components/Hero';
import FreelancerList from './components/FreelancerList';
import { Container } from '@mui/material';
import PageContainer from 'src/components/container/PageContainer';


const StartPage = () => {
  return (
    <PageContainer title="Because Mondays" description="Match your challenge with a unique freelancer" image="/logo512.png">
      <Container>
        <Hero />
      </Container>
        <div style={{
            backgroundColor: 'white',
            padding: '50px',
        }} >
          <FreelancerList showCategories={false}/>
        </div>
    </PageContainer>
  );
};

export default StartPage;
