import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as LogoDark } from 'src/assets/images/logos/bm-logo-wide.svg';
import { ReactComponent as SmallLogoSVG } from 'src/assets/images/logos/bm-logo-short.svg';  // Assuming you have an SVG version for this
import { styled, Box } from '@mui/material';
import { SettingsInputSvideo } from '@mui/icons-material';

const LinkStyled = styled(Link)(({ theme }) => ({
  height: '70px',
  // overflow: 'hidden',
  display: 'block',
}));

const LogoSVG = styled(LogoDark)(({ theme, color }) => ({
  fill: color,
  height: 30,
  width: 'auto',
}));

const SmallLogoStyled = styled(SmallLogoSVG)(({ theme, color }) => ({
  fill: color,
  height: 30,
  width: 'auto',
}));

const Logo = ({initColor, initSize}) => {
  const [color, setColor] = useState(initColor);
  const [size, setSize] = useState(initSize);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setColor('black');
      } else {
        setColor(initColor);
      }
    };
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <LinkStyled to="/">
      <Box marginTop={3} sx={{
        display: { xs: 'none', md: 'block' },
      }}>
        {size === 'small' ? (
          <SmallLogoStyled color={color} />
        ) : (
          <LogoSVG color={color} />
        )}
      </Box>
      <Box marginTop={3} sx={{
        display: { xs: 'block', md: 'none' },
      }}>
        <SmallLogoStyled color={color} />
      </Box>
    </LinkStyled>
  );
};

export default Logo;
