import React, { useState, useEffect } from 'react';
import { Grid, Box, Typography, Divider } from '@mui/material';
import PageContainer from 'src/components/container/PageContainer';
import usercheck from 'src/services/usercheck';
import { useLocation } from 'react-router-dom';

import About from "./components/About";
import Skills from "./components/Skills";
import ProfileImage from './components/ProfileImage';
import UploadCV from './components/UploadCV';
import Services from './components/Services';
import DashboardCard from '../../components/shared/DashboardCard';
import { FreelancerGPT } from '../dashboard/components/FreelancerGPT';
import Experiences from './components/Experiences';
import Educations from './components/Educations';
import Languages from './components/Languages';



const FreelancerAdmin = () => {
  const [cvResponse, setCvResponse] = useState(null);
  const location = useLocation();
  const handleCvResponse = (response) => {
    setCvResponse(response);
  };


  return (
    <PageContainer title="Freelancer Dashboard" description="Manage your freelancer profile">
      <Box>
        <Typography variant='h2' align='center' marginBottom={2}>
            Your Freelancer Profile
        </Typography>
        <Typography variant='subtitle1' align='center' marginBottom={2}>
          Create your public profile and bookable services
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} md={9}>
            <DashboardCard title="">
              <Grid container spacing={3}>
                <Grid item xs={12} md={3}>
                  <ProfileImage />
                </Grid>
                <Grid item xs={12} md={9}>
                  <About cvData={cvResponse} />
                  <Divider sx={{mt:5, mb:5}}/>
                  <Experiences cvData={cvResponse}/>
                  <Divider sx={{mt:5, mb:5}}/>
                  <Skills cvData={cvResponse}/>
                  <Divider sx={{mt:5, mb:5}}/>
                  <Services />
                  <Divider sx={{mt:5, mb:5}}/>
                  <Educations cvData={cvResponse}/>
                  <Divider sx={{mt:5, mb:5}}/>
                  <Languages cvData={cvResponse} />
                </Grid>
              </Grid>
            </DashboardCard>
          </Grid>
          <Grid item xs={12} md={3}>
            <UploadCV onCvAnalyzed={handleCvResponse} />
            <Divider sx={{mt:1, mb:1}}/>
            {/* <FreelancerGPT type={'profile'}/> */}
          </Grid>
        </Grid>
      </Box>
    </PageContainer>
  );
};

export default usercheck(FreelancerAdmin);
