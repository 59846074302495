import React, { useState } from "react";
import { styled, Container, Box } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';


import Header from '../utilities/header/HeadBodyHeader';
import Footer from '../utilities/footer/Footer';
// import Header from '../utilities/header/Header';

const MainWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
  width: '100%',
}));



  // backgroundImage: 'linear-gradient(-45deg, #FFEB00 0%, #FFEB00 25%, #FFEB00 51%, #2051F7 100%)',  // Blå + Gul
  // backgroundImage: 'linear-gradient(-45deg, #74D6FA 0%, #74D6FA 25%, #74D6FA 51%, #F18B00 100%)',  // Turkos + Orange
  // backgroundImage: 'linear-gradient(-45deg, #80EFF2 0%, #80EFF2 25%, #80EFF2 51%, #3C42FF 100%)',  // Blå + Turkos
  // backgroundImage: 'linear-gradient(-45deg, #84EFD2 0%, #84EFD2 25%, #84EFD2 51%, #22F56E 100%)',  // Tuskosgrön + Lime
  // backgroundImage: 'linear-gradient(-45deg, #FEB502 0%, #FEB502 25%, #FEB502 51%, #E93050 100%)',  // Röd + Orange
  // backgroundImage: 'linear-gradient(-70deg, #B11D80 0%, #B11D80 25%, #B11D80 51%, #ff357f 100%)', // BM Purple + Rosa


const FullLayout = () => {
  const theme = useTheme();

  const [isSidebarOpen, setSidebarOpen] = useState(true);
  const [isMobileSidebarOpen, setMobileSidebarOpen] = useState(false);
  // const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));

  const PageWrapper = styled('div')(() => ({
    display: 'flex',
    flexGrow: 1,
    paddingBottom: '60px',
    flexDirection: 'column',
    zIndex: 1,
    backgroundColor: 'transparent',
    backgroundImage: 'url(https://res.cloudinary.com/dldyopnvj/image/upload/v1700158339/Asset38_2x_tyugcs.png)', // Replace with the path to your image
    backgroundSize: '100% auto', // Full width, auto height to maintain aspect ratio
    backgroundRepeat: 'no-repeat', // Prevent the image from repeating
    backgroundPosition: 'top', // Center the image
    animation: 'AnimateBG 20s ease infinite',
    [theme.breakpoints.down('sm')]: {
      backgroundSize: 'auto 100%', // Adjust size for smaller screens
    },
  }));
  
  return (
    
    <MainWrapper
      className='mainwrapper'
    >
      {/* ------------------------------------------- */}
      {/* Main Wrapper */}
      {/* ------------------------------------------- */}
      <PageWrapper
        className="page-wrapper"
      >
        {/* ------------------------------------------- */}
        {/* Header */}
        {/* ------------------------------------------- */}
        <Header toggleSidebar={() => setSidebarOpen(!isSidebarOpen)} toggleMobileSidebar={() => setMobileSidebarOpen(true)} />
        {/* ------------------------------------------- */}
        {/* PageContent */}
        {/* ------------------------------------------- */}
        <PageWrapper sx={{
          paddingTop: "20px",
          maxWidth: '100%',
        }}
        >
          {/* ------------------------------------------- */}
          {/* Page Route */}
          {/* ------------------------------------------- */}
          <Box sx={{ 
            minHeight: 'calc(100vh - 350px)',
            width: '100%',
            }}>
            <Outlet />
          </Box>
          {/* ------------------------------------------- */}
          {/* End Page */}
          {/* ------------------------------------------- */}
          {/* ------------------------------------------- */}
      
        </PageWrapper>
        
      </PageWrapper>
      {/* Footer */}
      {/* ------------------------------------------- */}
      <Footer />
    </MainWrapper>
  );
};

export default FullLayout;