import React from "react";
import DashboardCard from '../../../components/shared/DashboardCard';
import { Typography, Button, Box } from '@mui/material';

export const FreelancerGPT = ({type}) => {
  let text = 'Explore the Freelancer GPT on ChatGPT, where clients can search and get exceptional help in matching with freelancers. Discover your perfect match today! NOTE: requires a payed ChatGPT+ account';
  if (type && type === 'profile') {
    text = "Let the Freelancer GPT help you write your public profile. Just answer simple questions and Freelancer GPT will create your texts. NOTE: requires a payed ChatGPT+ account";
  }

  return (    
      <DashboardCard title="Discover Freelancer GPT on ChatGPT!">
        <Box sx={{ textAlign: 'center', my: 2 }}>
        <img src="https://res.cloudinary.com/dldyopnvj/image/upload/v1700132235/Instagram-profil3_xnf39m.png" alt="OpenAI" style={{ width: 200 }} /><br/>
          <img src="https://res.cloudinary.com/dldyopnvj/image/upload/v1700132054/powered-by-openai-badge-filled-on-light_ikctts.svg" alt="OpenAI" style={{ width: 200 }} />
        </Box>
        <Typography variant="body1" sx={{ mb: 2 }}>
          {text}
        </Typography>
        <Box sx={{ textAlign: 'center' }}>
          <Button 
            variant="contained" 
            color="primary" 
            href="https://chat.openai.com/g/g-1YHFPiNeT-freelancer"
            target="_blank">
            Try Freelancer GPT
          </Button>
        </Box>
      </DashboardCard>
  );
};
