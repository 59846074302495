import React, { useState, useEffect, useRef } from 'react';
import Popover from '@mui/material/Popover';
import { Link, useParams } from 'react-router-dom';
import { CardContent, Typography, Grid, Rating, Tooltip, Fab, Avatar, Box, Button, IconButton } from '@mui/material';
import { Stack } from '@mui/system';
import { IconCalendar, IconFilter } from '@tabler/icons-react';
import BlankCard from '../../../components/shared/BlankCard';
import { getSkills, getFreelancers, getFreelancersBySkill, freeSearch } from "../../../services/message.service";
import SearchBar from './SearchBar';

const FreelancerList = ({ showCategories, showSearch, showTopFreelancers }) => {
    const [freelancerCard, setFreelancerCard] = useState([]);
    const [skills, setSkills] = useState({});
    const scrollContainer = useRef(null);
    const [isScrollable, setIsScrollable] = useState(false);
    const [showFilter, setShowFilter] = useState(false);
    const containerRef = useRef(null);
    const { searchTerm } = useParams();
    const [selectedSkillId, setSelectedSkillId] = useState("all");
    const [selectedSkillName, setSelectedSkillName] = useState("All");

    useEffect(() => {
        getSkills().then(data => {
            const groupedSkills = data.data.reduce((acc, skill) => {
                const category = skill.category_name || 'Other'; // Handle null category names
                if (!acc[category]) {
                    acc[category] = [];
                }
                acc[category].push(skill);
                return acc;
            }, {});
            setSkills(groupedSkills);
        });

        if (searchTerm) {
            if ( selectedSkillId && selectedSkillId !== 'all') {
                freeSearch(searchTerm, selectedSkillName).then(data => {
                    setFreelancerCard(data.data);
                });
            } else {
                freeSearch(searchTerm, "").then(data => {
                    setFreelancerCard(data.data);
                });
            }        
            
        } else if (showTopFreelancers) {
            getFreelancers(true, 10).then(data => {
                setFreelancerCard(data.data);
            });
        } else {
            getFreelancers(true).then(data => {
                setFreelancerCard(data.data);
            });
        }
    }, [searchTerm]);
            

    useEffect(() => {
        if (containerRef.current) {
            setIsScrollable(containerRef.current.scrollWidth > containerRef.current.clientWidth);
        }
    }, [skills]);  // Assuming 'skills' is the data that affects the width of your content

    const handleSkillClick = (skillId,skillName) => {
        setSelectedSkillId(skillId);
        setSelectedSkillName(skillName);
        if(skillId === "all") {
            getFreelancers().then(data => {
                setFreelancerCard(data.data);
            });
        } else {
            getFreelancersBySkill(skillId).then(data => {
                setFreelancerCard(data.data);
            });
        }
        handleClosePopover();
    };

    const [anchorEl, setAnchorEl] = useState(null);

    // Function to open the popover
    const handleOpenPopover = (event) => {
        setAnchorEl(event.currentTarget);
    };

    // Function to close the popover
    const handleClosePopover = () => {
        setAnchorEl(null);
    };



    return (
        <>
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Typography variant='h1'>
                    Meet the Freelancers / {selectedSkillName}
                </Typography>
            </Grid>
            {showSearch && (
            <Grid item xs={12} container alignItems="center">  {/* Containerized for aligning search bar and filter icon */}
                <Grid item xs={10}> {/* Grid for SearchBar */}
                    <SearchBar colorScheme='dark'/>
                </Grid>
                <Grid item sm={2}> {/* Grid for Filter Icon */}
                    <IconButton onClick={handleOpenPopover}> {/* Toggle showFilter state */}
                        <IconFilter /> 
                        <Typography variant="body2">Filter</Typography>
                    </IconButton>
                </Grid>
            </Grid>
            )}
            
            {!freelancerCard.length > 0  && (
                <Grid item xs={12} container alignItems="center"> 
                    <Grid item xs={10}> 
                        <Typography variant="subtitle1" marginTop={3}>I didn't find any freelancer with your search. 
                        try broaden your search or check other categories.</Typography>
                    </Grid>
                </Grid>                    
            )}

            {freelancerCard.map((freelancer, index) => (
                <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={index}>
                    <BlankCard >
                        <Typography component={Link} to={`/freelancer/${freelancer.id}`}>
                            <Avatar
                                src={freelancer.profile_image}
                                alt={freelancer.title_or_role}
                                variant= "square"
                                sx={{
                                    width: 1,
                                    height: 300,
                                }}
                            />
                        </Typography>
                        <CardContent>
                            <Typography variant="h4" style={{ textDecoration: 'none', color: 'inherit' }} component={Link} to={`/freelancer/${freelancer.id}`}>{freelancer.firstname}</Typography>
                            <Typography 
                                variant="subtitle2"
                                sx={{ minHeight: '3em', display: 'flex', alignItems: 'top' }}>
                                    {freelancer.title_or_role}
                            </Typography>
                            <Stack direction="row" alignItems="center" justifyContent="space-between" mt={0}>
                                <Stack direction="row" alignItems="center">
                                    <Typography
                                        variant="body2"
                                        component={Link}
                                        to={`/freelancer/${freelancer.id}`}
                                        style={{ textDecoration: 'none', color: 'inherit' }}  // Removes text decoration and inherits color
                                        >
                                        {freelancer.about_text && (freelancer.about_text.substring(0, 100) + (freelancer.about_text.length > 100 ? "...learn more" : ""))}
                                    </Typography>
                                    {/* <Typography variant="h6">{freelancer.standard_hourly_rate} SEK / hr</Typography> */}
                                    {/* <Typography color="textSecondary" ml={1} sx={{ textDecoration: 'line-through' }}>
                                        ${product.salesPrice}
                                    </Typography> */}
                                </Stack>
                                {/* <Rating name="read-only" size="small" value="5" readOnly /> */}
                            </Stack>
                        </CardContent>
                    </BlankCard>
                </Grid>
            ))}
        </Grid>
        <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleClosePopover}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
                }}
                transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
                }}>
                <Box sx={{
                        width: '95%', 
                        border: '1px solid', 
                        borderColor: (theme) => theme.palette.grey[300],
                        padding: 3,
                        
                    }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant="h3">
                                Categories
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{
                                display: 'grid',
                                gridTemplateColumns: 'repeat(3, 1fr)', // Three equal columns
                                gap: '10px',
                                overflowX: 'auto', // Allow horizontal scrolling if necessary
                                maxHeight: '400px', // Set a maximum height to handle vertical overflow
                            }}>                        
                        <Button 
                            variant="text" 
                            onClick={() => handleSkillClick("all", "All")} 
                            key="all"
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                color: selectedSkillId === "all" ? 'black' : 'grey.400',  // Change color based on selected skill ID
                                '&:hover': {
                                    backgroundColor: 'transparent',
                                    color: 'navlink.main'
                                },
                                minWidth: '100px'
                            }}>
                            <Typography variant='body1'>
                                All
                            </Typography>
                        </Button>
                        {Object.entries(skills).map(([categoryName, skillsInCategory]) => (
                            <Box key={categoryName} sx={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography variant="h6">{categoryName}</Typography>
                                {skillsInCategory.map(skill => (
                                    <Button 
                                        variant="text" 
                                        onClick={() => handleSkillClick(skill.id, skill.skill_name)} 
                                        key={skill.id}
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'flex-start',
                                            color: selectedSkillId === skill.id ? 'black' : 'grey.400',  // Change color based on selected skill ID
                                            '&:hover': {
                                                backgroundColor: 'transparent',
                                                color: 'navlink.main'
                                            },
                                            padding: "3px 0px",
                                            minWidth: '130px'
                                        }}>
                                        <Typography variant='body1'>
                                            {skill.skill_name}
                                        </Typography>
                                    </Button>
                                ))}
                            </Box>
                        ))}
                        </Grid>
                    </Grid>
                </Box>
            </Popover>
        </>
    );
};

export default FreelancerList;
