// BookingRequestDetails.js

import React, { useState, useEffect } from 'react';
import { Card, CardHeader, Box, CardContent, Typography, Avatar, Grid } from '@mui/material';
import { getBookingById } from '../../../services/message.service';
import sanitizeHtml from 'sanitize-html';
import { useParams } from 'react-router-dom';
import DashboardCard from '../../../components/shared/DashboardCard';
import { useUserContext } from '../../../contexts/Contexts';


const BookingRequestDetails = () => {
  const { token } = useUserContext();
  const [booking, setBooking] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };
  const renderProjectDescription = (description) => {
    if (description.length <= 500 || isExpanded) {
      return description;
    } else {
      return description.slice(0, 500) + '...';
    }
  };

  
  const { booking_request_id } = useParams(); // Get booking_request_id from URL

  useEffect(() => {
    const fetchData = async () => {
      try {
        //TODO MAKE CHECK THAT ONLY FREELANCER AND CLIENT CAN SEE THIS BOOKING
        const fetchedBooking = await getBookingById(booking_request_id, token);
        setBooking(fetchedBooking.data);
      } catch (error) {
        console.error('Error fetching booking:', error);
      }
    };

    fetchData();
  }, [booking_request_id]);

  function NewlineText({ text }) {
    const newText = text.split('\n').map((str, index, array) =>
      index === array.length - 1 ? str : <>
        {str}
        <br />
      </>
    );
    return <>{newText}</>;
  }

  if (!booking) {
    return <div>Loading...</div>;
  }

  return (

    <DashboardCard title={booking.title}
      headsubtitle={`Customer: ${booking.firstname} ${booking.lastname}`}>
        <Typography variant="body1" align='right'>
          {`Client: ${booking.company_name} ${booking.firstname}`}
        </Typography>
        <Typography variant='h6'>Description:</Typography>
        <NewlineText text={sanitizeHtml(renderProjectDescription(booking.project_description))} />
        {booking.project_description.length > 500 && (
          <Typography variant="body1" align="right" style={{cursor: "pointer"}} onClick={toggleExpand}>
            {isExpanded ? '...read less' : '...read more'}
          </Typography>
        )}
    </DashboardCard>
  );
};

export default BookingRequestDetails;
