import SupportPerson from 'src/assets/images/varios/SupportPerson.png';
import { Grid, Box, Typography, Button, Card, CardContent, CardActions, CardMedia } from '@mui/material';
import DashboardCard from 'src/components/shared/DashboardCard';
import { Link, useNavigate } from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';



const Welcome = () => {
  const navigate = useNavigate();

  return (
  <Grid container spacing={3}>
    <Grid item xs={12} md={6}>
      <Card variant="outlined" sx={{  mb: 2 }}>
          <CardContent>
            <Typography variant="h5" color="text.primary" gutterBottom>
              Trim your profile?
            </Typography>
            <Typography variant="body1">
              Improve your freelancer profile to be matched by clients.
            </Typography>
          </CardContent>
          <CardActions>
            <Button 
              color="primary" 
              endIcon={<ArrowForwardIcon />} 
              component={Link} 
              to="/plaza/freelancer"
            >
              Set Up Profile
            </Button>
          </CardActions>
        </Card>
    </Grid>
    <Grid item xs={12} md={6}>
      <Card variant="outlined" sx={{  mb: 2 }}>
        <CardContent>
          <Typography variant="h5" color="text.primary" gutterBottom>
            Looking for gigs?
          </Typography>
          <Typography variant="body1">
            Check out projects and client leads to see if there are open positions available for you
          </Typography>
        </CardContent>
        <CardActions>
          <Button 
            color="primary" 
            endIcon={<ArrowForwardIcon />} 
            component={Link} 
            to="/plaza/needs"
          >
            See available gigs
          </Button>
        </CardActions>
      </Card>
    </Grid>

    <Grid item xs={12}>
      <Card variant='outlined' sx={{ display: 'flex', mb: 2, alignItems: 'center', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <CardContent>
            <Typography variant='h5' color="text.primary" gutterBottom>
              Need a hand getting started?
            </Typography>
            <Typography variant='body1'>
              Our team is here to help you set up your profile and start connecting with clients.
            </Typography>
          </CardContent>
          <CardActions>
            <Button 
              color="primary"             
              href='https://meetings.hubspot.com/martin-hollstrand/book-me' 
              target='_blank' 
              endIcon={<ArrowForwardIcon />} 
            >
              Schedule Introduction Call
            </Button>
          </CardActions>
        </Box>
        <CardMedia
          component="img"
          sx={{ width: 200, alignSelf: 'center' }}
          image={SupportPerson}
          alt="Support Person"
        />
      </Card>
    </Grid>
  </Grid>
  );
};
  
export default Welcome;
