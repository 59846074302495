import React, { useState, useEffect } from 'react';
import { getDealsByFreelancerId } from 'src/services/message.service';
import { useUserContext } from 'src/contexts/Contexts';
import DashboardCard from 'src/components/shared/DashboardCard';
import { Link as RouterLink } from 'react-router-dom';
import { Grid, Button, List, ListItem, ListItemText, Paper, Typography, Link } from '@mui/material';

const ListDeals = () => {
  const [offers, setOffers] = useState([]);
  const { freelancerData, token } = useUserContext();
  
  useEffect(() => {
    const fetchOffers = async () => {
      if (freelancerData?.id && token) {
        try {
          const response = await getDealsByFreelancerId(freelancerData.id, token);
          console.log("Deals: " + JSON.stringify(response));
          setOffers(response.data);
        } catch (error) {
          console.error('Error fetching offers:', error);
        }
      }
    };
    fetchOffers();
  }, [freelancerData, token]);

  return (
    <DashboardCard title="Deals">
      <List>
      {offers.length > 0 ? (
          offers.map((offer, index) => (
            <ListItem key={index} divider>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={9}>
                  <ListItemText
                    primary={
                      <Typography variant="h5" component="h2">
                        #{offer.id} {offer.company_name}
                      </Typography>
                    }
                    secondary={
                      <>
                        <Typography component="span" variant="body2" color="text.primary">
                          Status: {offer.status}
                        </Typography>
                        {' - Start Date: ' + new Date(offer.start_date).toISOString().split('T')[0]}
                        {' - End Date: ' + new Date(offer.end_date).toISOString().split('T')[0]}
                        <Typography display="block" variant="body2" color="text.secondary">
                          {offer.message.slice(0, 200)}
                        </Typography>
                      </>
                    }
                  />
                </Grid>
                <Grid item xs={3}>
                  <Button
                    component={RouterLink}
                    to={`/plaza/freelancer-invoice/${offer.id}`}
                    variant="outlined"
                    color="primary"
                    style={{ whiteSpace: 'nowrap' }}
                  >
                    Create Invoice
                  </Button>
                </Grid>
              </Grid>
            </ListItem>
          ))
        ) : (
          <ListItem>
            <ListItemText primary="No offers available." />
          </ListItem>
        )}      
      </List>
    </DashboardCard>
  );
};

export default ListDeals;