import {
  IconAperture, IconArrowBack, IconBrain, IconBrandMonday, IconBrandProducthunt, IconCopy, IconFileInvoice, IconWritingSign, IconHotelService, IconLayoutDashboard, IconLogin, IconMan, IconMoneybag, IconMoodHappy, IconReportMoney, IconServicemark, IconSignLeft, IconTag, IconTypography, IconUser, IconUserPlus
} from '@tabler/icons-react';
import { useUserContext } from '../../../contexts/Contexts';

import { uniqueId } from 'lodash';

const getMenuItems = () => {
  const { isFreelancer, isClient } = useUserContext();

  let menuItems = [
    {
      id: uniqueId(),
      title: 'All Freelancers',
      icon: IconArrowBack,
      href: '/freelancers',
    },
    {
      id: uniqueId(),
      title: 'Dashboard',
      icon: IconLayoutDashboard,
      href: '/plaza/dashboard',
    },
    ...(isFreelancer ? [
      {
        id: uniqueId(),
        title: 'All Leads',
        icon: IconFileInvoice,
        href: '/plaza/needs',
      },
    ] : []
    ),
    ...(isFreelancer ? [
        {
          navlabel: true,
          subheader: 'Freelancer Profile',
        },
        {
          id: uniqueId(),
          title: 'My Public Profile',
          icon: IconUser,
          href: '/plaza/freelancer',
        },
        {
          id: uniqueId(),
          title: 'My Leads & Offers',
          icon: IconFileInvoice,
          href: '/plaza/freelancer-bookings',
        },
        {
          id: uniqueId(),
          title: 'My Signed Deals',
          icon: IconWritingSign,
          href: '/plaza/freelancer-deals',
        },
        {
          id: uniqueId(),
          title: 'My Invoices',
          icon: IconFileInvoice,
          href: '/plaza/freelancer-invoices',
        },
        {
          id: uniqueId(),
          title: 'Create External Offer',
          icon: IconFileInvoice,
          href: '/plaza/freelancer-createoffer',
        },
        {
          navlabel: true,
          subheader: 'My Account',
        },
        {
          id: uniqueId(),
          title: 'Account Information',
          icon: IconUser,
          href: '/plaza/profile',
        },
        {
          id: uniqueId(),
          title: 'Subscription',
          icon: IconBrandMonday,
          href: '/plaza/subscription',
        },
      ] : []
    ),
    ...(isClient ? [
        {
          navlabel: true,
          subheader: 'Client Profile',
        },
        {
          id: uniqueId(),
          title: 'Publish Projects',
          icon: IconFileInvoice,
          href: '/plaza/create_need',
        },
        {
          id: uniqueId(),
          title: 'My Projects',
          icon: IconFileInvoice,
          href: '/plaza/client_needs',
        },
        {
          id: uniqueId(),
          title: 'My Freelancer Bookings',
          icon: IconFileInvoice,
          href: '/plaza/client-bookings',
        },
        {
          navlabel: true,
          subheader: 'My Account',
        },
        {
          id: uniqueId(),
          title: 'Account Information',
          icon: IconUser,
          href: '/plaza/profile',
        },
      ] : []
    ),

    // {
    //   navlabel: true,
    //   subheader: 'Admin (NOT RELEASED)',
    // },
    // {
    //   id: uniqueId(),
    //   title: 'Salary ',
    //   icon: IconMoneybag,
    //   href: '/plaza/salary',
    // },
    // {
    //   id: uniqueId(),
    //   title: 'Invoices',
    //   icon: IconFileInvoice,
    //   href: '/plaza/invoices',
    // },
    // {
    //   id: uniqueId(),
    //   title: 'Economy',
    //   icon: IconReportMoney,
    //   href: '/plaza/economy',
    // },
  ];
  menuItems = menuItems.flat();

  return menuItems;
}

export default getMenuItems;
