// Your imports here
import React, { useState, useEffect } from 'react';
import DashboardCard from '../../../components/shared/DashboardCard';
import { Box, Typography, Table, TableBody, TableRow, TableCell, TableHead, Snackbar, TextField, Button } from '@mui/material';
import { isAfter, format, parseISO } from 'date-fns';
import { useUserContext } from '../../../contexts/Contexts';
import MakeDealModal from '../../client/components/MakeDealModal';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import useFetchData from 'src/components/fetchdata/useFetchData';
import PriceRow from './PriceRow';
import CircularProgress from '@mui/material/CircularProgress';


import {
  createOffer,
  createBookingMessage,
  updateOffer,
  getOfferByBookingRequestId,
  getBookingById,
  getDealById,
  getPricesByOfferId,
  createPrice,
  updatePrice,
  deletePrice,
  getClientNeedById,
  getClientNeedByOfferId,
  getOfferByClientNeedId,
  getOfferByClientNeedAndFreelancerId,
  getOfferById,
} from '../../../services/message.service';

const priceTypes = [
  { value: 'FIXED', label: 'Fixed' },
  { value: 'HOURLY', label: 'Hourly' },
];

const BookingOfferAndDeals = ({ offerType, id }) => {
  const { userData, freelancerData, isFreelancer, isClient, token } = useUserContext();
  const [offerData, setOfferData] = useState(null);
  const [bookingRequestData, setBookingRequestData] = useState(null);
  const [messageData, setMessageData] = useState("");
  const [amountQuote, setAmountQuote] = useState("");
  const [freelancerId, setFreelancerId] = useState("");
  const [clientId, setClientId] = useState("");
  const [status, setStatus] = useState("new");
  const [formMode, setFormMode] = useState(''); 
  const [showUpdateForm, setShowUpdateForm] = useState(false); 
  const [refreshTrigger, setRefreshTrigger] = useState(0);
  const [isDealModalOpen, setIsDealModalOpen] = useState(false);
  const [dealDetails, setDealDetails] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);


  const [priceRows, setPriceRows] = useState([
    { price: '', quantity: '', currency: 'SEK', price_type: 'FIXED', price_description: '' },
  ]);
  
  /**
   * FIRST WE NEED TO BASE THE INITIAL FETCH BASED ON 
   * WHICH TYPE OF ENTRY THIS OFFER IS COMING FROM
   * WE NEED TO GET PROJECT DATA FROM EITHER A BOOKING 
   * OR A CLIENT NEED
   */
  let fetchFunction;
  switch (offerType) {
    case 'booking':
      fetchFunction = getBookingById;
      break;
    case 'need':
      fetchFunction = getClientNeedById;
      break;
    case 'offer':
      fetchFunction = getClientNeedByOfferId;
      break;
    default:
      fetchFunction = null; 
      break;
  }
  const fetchParams = [id, token];
  const { data: bookingOrClientData, loading, error } = useFetchData(
    fetchFunction,
    fetchParams,
    [id, freelancerData, token]
  );

  useEffect(() => {
    if (bookingOrClientData) {
      setClientId(bookingOrClientData.client_id);
      if (isFreelancer) {
        setFreelancerId(freelancerData.id);
      }
    }
  }, [bookingOrClientData, freelancerData, isFreelancer]);


  /**
   * THEN WE NEED TO FETCH THE CURRENT OFFER
   * DEPENDING ON HOW THIS COMPONENT IS MOUNTED
   * IT FETCHES WITH DIFFERENT IDs
   */

  const fetchOfferData = async () => {
    let offerDataResponse;
    try {
      if (offerType === 'booking') {
        offerDataResponse = await getOfferByBookingRequestId(id, token);
      } else if (offerType === 'need') {
        offerDataResponse = await getOfferByClientNeedAndFreelancerId(id, freelancerData.id, token);
      } else if (offerType === 'offer') {
        offerDataResponse = await getOfferById(id, token);
      }
      if (offerDataResponse.data) {
        processOfferData(offerDataResponse.data);
      } else {
        setFormMode('create');
      }
    } catch (error) {
      console.error('Error fetching offer:', error);
    }
  };
  
  const processOfferData = (data) => {
    setOfferData(data);
    setFormMode('update');
    setMessageData(data.message);
    if (data.start_date) {
      const formattedStartDate = format(parseISO(data.start_date), 'yyyy-MM-dd');
      const formattedEndDate = format(parseISO(data.end_date), 'yyyy-MM-dd');
      setStartDate(formattedStartDate);
      setEndDate(formattedEndDate);
    }
    setAmountQuote(data.amount);
    if (data.deal_id) {
      fetchDealDetails(data.deal_id);
    }
  };
  
  useEffect(() => {
    fetchOfferData();
  }, [id, refreshTrigger, token]);


   /**
   * IF THERE IS A DEAL, WE FETCH THAT DATA
   */
  const fetchDealDetails = async (dealId) => {
    try {
      const dealDetailsResponse = await getDealById(dealId, token);
      setDealDetails(dealDetailsResponse.data);
    } catch (error) {
      console.error('Error fetching deal details:', error);
    }
  };


  /**
   * WE FETCH PRICES ON THIS CURRENT OFFER
   */
  const fetchPrices = async () => {
    if (offerData && offerData.id) {
      try {
        const response = await getPricesByOfferId(offerData.id, token);
        const pricesFromDb = response.data.map((price) => ({
          id: price.id,
          offer_id: price.offer_id,
          quantity: price.quantity,
          price: price.price,
          currency: price.currency,
          price_type: price.price_type,
          price_description: price.price_description,
        }));
        setPriceRows(pricesFromDb);
      } catch (error) {
        console.error('Error fetching prices:', error);
      }
    }
  };
  
  useEffect(() => {
    fetchPrices();
  }, [offerData, token]);
  


  /**
   * Function to toggle the form or showing data
   * Only freelancers can se the form
   */
  const toggleUpdateForm = () => {
    setShowUpdateForm(!showUpdateForm);
  };


  /**
   * Function to handle adding, changing and removing prices
   */
  const addPriceRow = () => {
    setPriceRows((currentRows) => [
      ...currentRows,
      { price: '', quantity: '', currency: 'SEK', price_type: 'FIXED', price_description: '' },
    ]);
  };

const removePriceRow = (priceId, index) => {
  // If the row has an id, it means it's stored in the database, so call the API to delete it
  if (priceId) {
    deletePrice(priceId, token)
      .then(() => {
        // Remove the row from the local state after successful deletion
        setPriceRows(currentRows => currentRows.filter(row => row.id !== priceId));
      })
      .catch((error) => {
        console.error(`Failed to delete price with ID ${priceId}`, error);
      });
  } else {
    // If the row doesn't have an id, it's not in the database yet, so just remove it from the local state
    setPriceRows(currentRows => currentRows.filter((_, i) => i !== index));
  }
};

  const handlePriceRowChange = (index, field, value) => {
    setPriceRows((currentRows) =>
      currentRows.map((row, i) => (i === index ? { ...row, [field]: value } : row))
    );
  };


  /**
   * FORM VALIDATIONS
   */
  const validateForm = () => {
    const errors = [];
  
    // Date Validation
    if (!startDate || !endDate) {
      errors.push("Both start and end dates must be selected.");
    } else if (!isAfter(new Date(endDate), new Date(startDate))) {
      errors.push("End date must be after start date.");
    } else if (isAfter(new Date(), new Date(startDate))) {
      errors.push("Start date must be in the future.");
    }
  
    // Price Validation
    const allPricesAreValid = priceRows.every(row => {
      const price = row.price.trim();
      return price && !isNaN(price) && isFinite(price);
    });
  
    if (!allPricesAreValid) {
      errors.push("All prices must be valid numbers.");
    }
  
    // General Form Validation
    if (!messageData.trim()) {
      errors.push("Offer details are required.");
    }
  
    return errors;
  };
  


  /**
   * THIS SUBMITS THE OFFER FORM, BOTH CREATE AND UPDATE
   */
  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      const formErrors = validateForm();
      if (formErrors.length > 0) {
        setSnackbarMessage(formErrors.join("\n"));
        return;
      }
      
      let currentOfferId = offerData ? offerData.id : null; // Get the current offer ID if it exists
  
      const sharedOfferData = {
        message: messageData,
        freelancer_id: freelancerId,
        client_id: clientId,
        start_date: startDate,
        end_date: endDate,
        ...(offerType === 'booking' ? { booking_request_id: id } : { client_need_id: id }),
      };
  
      // If updating, we update the offer first
      if (formMode === 'update') {
        await updateOffer(currentOfferId, { ...sharedOfferData, status: offerData.status }, token);
        setSnackbarMessage("Offer updated successfully and a message has been sent to the client.");
      } else {
        // If creating, we create the offer and get the new offer ID
        const createResponse = await createOffer({ ...sharedOfferData, status }, token);
        currentOfferId = createResponse.data.id; // Update the currentOfferId with the new ID from the response
        setOfferData((prevData) => ({ ...prevData, id: currentOfferId })); // Update the offerData state with the new offer_id
        setSnackbarMessage("Offer created successfully and a message has been sent to the client.");
      }
  
      // Prepare the price rows with the correct offer_id
      const newPriceRows = priceRows
        .filter((row) => !row.id)
        .map((row) => ({ ...row, offer_id: currentOfferId }));
        
      // Create new prices and update existing prices
      const createdPrices = await Promise.all(
        newPriceRows.map((row) => createPrice(row, token))
      );

      const updatedPrices = await Promise.all(
        priceRows
          .filter((row) => row.id)
          .map((row) => updatePrice(row.id, row, token))
      );

      // Update state with new, updated, and existing price rows
      setPriceRows((currentRows) => [
        ...currentRows.filter(row => !row.id && !updatedPrices.some(updRow => updRow.id === row.id)), // Keep rows without an ID and not updated
        ...createdPrices.map(response => response.data), // Add new rows with IDs
        ...updatedPrices.map(response => response.data) // Update existing rows
      ]);
        
      // Handle the messaging part of the update
      const messageText = `I have ${formMode === 'update' ? 'updated the offer' : 'created an offer'} `;
      const newMessageData = {
        text: messageText,
        sender_id: userData.id,
        sender_type: freelancerData ? "freelancer" : "client",
        // Add the ID based on the offerType
        ...(offerType === 'booking' ? { booking_request_id: id } : { client_need_id: id }),
        freelancer_id: freelancerData.id,
        client_id: clientId,
      };
      
      await createBookingMessage(newMessageData, token);
      setRefreshTrigger((prev) => prev + 1); // Using a function to ensure the latest state is used
      setShowUpdateForm(false);// Toggle the form to show the updated data
    } catch (error) {
      // Handle any errors here
      setShowUpdateForm(false);
      console.error('Error in handleSubmit:', error);
      setSnackbarMessage("An error occurred while submitting the offer.");
      // You may want to set an error state and display an error message to the user
    } finally {
      setShowUpdateForm(false);
      setIsSubmitting(false); // Stop loading regardless of outcome
    }
  };
  

  const handleMakeDealClick = (offerData) => {
    setIsDealModalOpen(true);
  };

  const handleMakeDealClose = () => {
    setIsDealModalOpen(false);
    setRefreshTrigger((prev) => prev + 1);
  };


  if (loading) {
    return <div>Loading...</div>; // Or use a more sophisticated loader
  }
  
  if (error) {
    return <div>Error: {error.message}</div>; // Display the error message
  }

  const renderOfferDetails = () => {
    if (!offerData) return null;
  
    const formattedStartDate = offerData.start_date ? format(parseISO(offerData.start_date), 'yyyy-MM-dd') : 'N/A';
    const formattedEndDate = offerData.end_date ? format(parseISO(offerData.end_date), 'yyyy-MM-dd') : 'N/A';
  
    return (
      <Box marginBottom={3}>
        <Typography variant="body1" marginBottom={2}>
          {offerData.message}
        </Typography>
        <Typography variant="body2" component="div">
          <strong>Start Date: </strong>{formattedStartDate} <br /><br />
          <strong>End Date: </strong>{formattedEndDate} <br />
        </Typography>
        <Typography variant='h5' marginTop={3}>Prices:</Typography>
        <Table size="small"  style={{ marginBottom: 10 }}>
          <TableHead>
            <TableRow>
              <TableCell align="left" style={{ paddingLeft: 0 }}><strong>Description</strong></TableCell>
              <TableCell align="right"><strong>Quantity</strong></TableCell>
              <TableCell align="right"><strong>Price</strong></TableCell>
              <TableCell><strong>Type</strong></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {priceRows.map((price, index) => (
              <TableRow key={index} style={{ backgroundColor: index % 2 === 0 ? '#f5f5f5' : 'transparent' }}>
                <TableCell component="th" scope="row"  style={{ paddingLeft: 0 }}>
                  {price.price_description}
                </TableCell>
                <TableCell align="right">
                  {`${price.quantity}`}
                </TableCell>
                <TableCell align="right">
                  {`${price.price} ${price.currency}`}
                </TableCell>
                <TableCell component="th" scope="row">
                  {price.price_type}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    );
  };
  
  
  
  // Function to render price rows
  const renderPriceRows = () => (
    <Box>
      {priceRows.map((row, index) => (
        <PriceRow
          key={row.id || index}
          row={row}
          index={index}
          handlePriceRowChange={handlePriceRowChange}
          removePriceRow={removePriceRow}
          priceTypes={priceTypes}
        />
      ))}
      <Button onClick={addPriceRow} variant='outlined' sx={{ mt: 2 }} >Add Price</Button>
    </Box>
  );
  
  return (
    <DashboardCard title={isFreelancer ? "My Offer" : "Offers and Deals"}>
      <Typography variant="body2" marginBottom={3}>
        Freelancer needs to first make an offer with details. Take a discussion first using the messaging system
        to sort out questions. Once the freelancer has made an offer, the client can validate it and make a Deal. 
        When the deal is made, a contract will be sent out to both parties within 24 hours to your email addresses.
      </Typography>
      {dealDetails && (
        <Box marginBottom={3}>
          <Typography variant="h5">
             Deal is created <CheckCircleIcon />
          </Typography>
          <Typography variant="body2">
            <strong>Start Date: </strong>{format(parseISO(dealDetails.start_date), 'yyyy-MM-dd')} <br/>
            <strong>End Date: </strong>{format(parseISO(dealDetails.end_date), 'yyyy-MM-dd')} <br/>
            <strong>Agreement text: </strong>{dealDetails.agreement_text} <br/>
            <strong>Deal Status: </strong>{dealDetails.status}
          </Typography>
        </Box>
      )}

      {/* Show 'Deal Created' button for both Freelancer and Client
      {(isFreelancer || isClient) && offerData && offerData.deal_id !== null && (
       
        
      )} */}

      { offerData && !showUpdateForm ? (
        <>
          <Typography variant="h5">
            Offer details  
          </Typography>
          {renderOfferDetails()}
          {isClient && (
            <Box display="flex" justifyContent="flex-end" mt={2}>
              {
                offerData.deal_id === null ? (
                  <Button size="small" variant="contained" color="primary" onClick={() => handleMakeDealClick(offerData)}>
                    Make Deal
                  </Button>
                ) : null }
              
              <MakeDealModal 
                  isOpen={isDealModalOpen} 
                  onClose={() => handleMakeDealClose()} 
                  offerData={offerData} 
                  priceRows={priceRows}
              />
            </Box>
             
          )}
        </>
      ) : null }

      { isFreelancer && (!offerData || showUpdateForm) ? (
        <>
          <TextField
            label="Offer Details"
            multiline
            rows={8}
            fullWidth
            value={messageData}
            onChange={(e) => setMessageData(e.target.value)}
            margin="normal"
          />
          <TextField
            label="Start Date"
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              pattern: "\\d{4}-\\d{2}-\\d{2}"
            }}          
            sx={{ mt: 2 }} 
          />
          <TextField
            label="End Date"
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              pattern: "\\d{4}-\\d{2}-\\d{2}"
            }}          
            sx={{ mt: 2, ml: 2 }} 
          />
          {renderPriceRows()}
        </>
      ) : null }


      { isClient && !offerData ? (
        <Typography variant="body1">
          No offers created yet.
        </Typography>
      ) : null }

      {isFreelancer && (
        <Box display="flex" justifyContent="flex-end" mt={2}>
          {offerData && (
            <Button 
              onClick={toggleUpdateForm} 
              style={{ marginRight: '8px' }}
              disabled={dealDetails}
              >
              {showUpdateForm ? 'Cancel' : 'Update this offer'}
            </Button>
          )}
          {showUpdateForm || !offerData ? (
            <Button onClick={handleSubmit} variant="contained" disabled={isSubmitting}>
              {isSubmitting ? (
                <CircularProgress size={24} />
              ) : (
                formMode === 'update' ? 'Update Offer' : 'Create Offer'
              )}
            </Button>          
          ) : null}
        </Box>
      )}
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={!!snackbarMessage}
        autoHideDuration={6000}
        onClose={() => setSnackbarMessage('')}
        message={snackbarMessage}
      />
    </DashboardCard>
  );
};



export default BookingOfferAndDeals;
