import React, { useState, useEffect } from 'react';
import { List, ListItem, ListItemText, Divider, CircularProgress, Snackbar, Alert, Grid, Button, TextField, Box, IconButton, Typography } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import DashboardCard from 'src/components/shared/DashboardCard';
import { useUserContext } from 'src/contexts/Contexts';
import { formatISO, format, parseISO, addDays } from 'date-fns';
import { getUserSubscriptions, createInvoice, getInvoiceById, updateInvoice, getOfferById, getInvoicesByOfferId } from 'src/services/message.service'; // Adjust the import path as needed
import SuccessModal from 'src/components/shared/SuccessModal';

const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;


const InvoiceForm = ({ offerId, invoiceId }) => {
  const { userData, token, isFreelancer, isClient } = useUserContext();
  const [offerData, setOfferData] = useState({});
  const [priceData, setPriceData] = useState([]);
  const [discountData, setDiscountData] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [budget, setBudget] = useState(0);
  const [budgetInvoiced, setBudgetInvoiced] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('error'); // default to 'error'
  const [subscriptionData, setSubscriptionData] = useState({});
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const today = new Date();
  const thirtyDaysLater = new Date(today);
  thirtyDaysLater.setDate(thirtyDaysLater.getDate() + 30);

  // Format the dates to 'YYYY-MM-DD' which is the expected format for input[type="date"]
  const formattedToday = formatISO(today, { representation: 'date' });
  const formattedThirtyDaysLater = formatISO(thirtyDaysLater, { representation: 'date' });

  const formattedOfferStartDate = offerData.start_date ? format(parseISO(offerData.start_date), 'yyyy-MM-dd') : '';
  const formattedOfferEndDate = offerData.end_date ? format(parseISO(offerData.end_date), 'yyyy-MM-dd') : '';

  const [invoiceData, setInvoiceData] = useState({
    offer_id: offerId,
    deal_id: offerData.deal_id,
    invoice_date: formattedToday,
    due_date: formattedThirtyDaysLater,
    currency: 'SEK',
    status: 'new',
    invoiceItems: [{ description: '', quantity: '', unit_price: '' }],
  });

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (message, severity = 'error') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };



  useEffect(() => {
    fetchInvoiceData();
  }, [invoiceId, offerId, userData, token]);

  const fetchInvoiceData = async () => {
    setIsLoading(true);
    try {
      if (invoiceId) {
        const fetchedInvoiceData = await getInvoiceById(invoiceId, token);
        setInvoiceData(fetchedInvoiceData.data);
      }
      if (offerId) {
        const fetchedOfferData = await getOfferById(offerId, token);
        console.log("OfferData: " + JSON.stringify(fetchedOfferData.data));
        setOfferData(fetchedOfferData.data);
        setPriceData(fetchedOfferData.data.prices);
        setDiscountData(fetchedOfferData.data.discounts);
        const fetchedInvoices = await getInvoicesByOfferId(offerId, token);
        if (fetchedInvoices && fetchedInvoices.data) {
          setInvoices(fetchedInvoices.data);
          const sum = fetchedInvoices.data.reduce((total, invoice) => {
            const invoiceTotal = invoice.subTotal;
            return total + invoiceTotal;
          }, 0);
          setBudgetInvoiced(sum);
        } else {
          setInvoices([]); 
        }
      }
      const subscription = await getUserSubscriptions(userData.id, token);
      if (subscription && subscription.data) {
        setSubscriptionData(subscription.data);
        console.log("Subscription Data: " + JSON.stringify(subscription));
      }

    } catch(error) {
      console.log("Error", error);
    } finally {
      setIsLoading(false);  
    }
  };



  useEffect(() => {
    if (priceData) {
      const totalBudget = calculateTotalBudget();
      setBudget(totalBudget.toFixed(0));
    }
  }, [priceData]); 
    
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    if (name.startsWith('item_')) {
      const items = [...invoiceData.invoiceItems];
      items[index][name.replace('item_', '')] = value;
      setInvoiceData({ ...invoiceData, invoiceItems: items });
    } else {
      setInvoiceData({ ...invoiceData, [name]: value });
    }
  };

  const handleAddItem = () => {
    setInvoiceData({
      ...invoiceData,
      invoiceItems: [...invoiceData.invoiceItems, { description: '', quantity: '', unit_price: '' }],
    });
  };

  const handleRemoveItem = (index) => {
    const items = [...invoiceData.invoiceItems];
    items.splice(index, 1);
    setInvoiceData({ ...invoiceData, invoiceItems: items });
  };

  const handleSave = async () => {
    try {
      if (invoiceData.id) {
        // Update existing invoice
        await updateInvoice(invoiceData.offer_id, invoiceData, token);
        showSnackbar('Invoice saved successfully and is being sent to client', 'success');
      } else {
        // Create new invoice
        await createInvoice(invoiceData ,token);
        showSnackbar('Invoice saved successfully and is being sent to client', 'success');
      }
      setInvoiceData({
        offer_id: offerId,
        deal_id: offerData.deal_id,
        invoice_date: formattedToday,
        due_date: formattedThirtyDaysLater,
        currency: 'SEK',
        status: 'new',
        invoiceItems: [{ description: '', quantity: '', unit_price: '' }],
      });
      setShowSuccessModal(true);
      setTimeout(() => {
        setShowSuccessModal(false);
      }, 10000); 
      await fetchInvoiceData();
    } catch (error) {
      showSnackbar('Error saving invoice: ' + error.message);
      console.error('Error saving invoice:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true); 

    const hasValidItems = invoiceData.invoiceItems.every(item => 
      item.description.trim() && item.quantity > 0 && item.unit_price > 0
    );

    if (!hasValidItems) {
      showSnackbar('Please add at least one item with a description, quantity, and unit price.', 'error');
      setIsLoading(false);
      return;
    }

    if (isBudgetExceeded()) {
      showSnackbar('The total amount of the new invoice exceeds the remaining budget.');
      setIsLoading(false);
      return;
    }
    await handleSave();
    setIsLoading(false); 
  };

  const calculateInvoiceItemsTotal = () => {
    return invoiceData.invoiceItems.reduce((total, item) => {
      const quantity = parseFloat(item.quantity) || 0;
      const unitPrice = parseFloat(item.unit_price) || 0;
      return total + (quantity * unitPrice);
    }, 0);
  };
  
  const isBudgetExceeded = () => {
    const newInvoiceTotal = calculateInvoiceItemsTotal();
    return (budgetInvoiced + newInvoiceTotal) > budget;
  };

  // Function to calculate total budget
  const calculateTotalBudget = () => {
    return priceData.reduce((total, priceItem) => {
      const price = parseFloat(priceItem.price) || 0;
      const quantity = parseFloat(priceItem.quantity) || 0;
      return total + (price * quantity);
    }, 0);
  };

  
  // Find the freelancer discount in the discountData array
  const freelancerDiscount = discountData.find(discount => discount.discount_type === "FREELANCER");
  // Get the discount percentage or default to 0 if not found
  const discountPercent = freelancerDiscount ? parseFloat(freelancerDiscount.discount_percentage) : 0;

  const invoiceItemsTotal = calculateInvoiceItemsTotal();
  const bookingFeePercent = parseFloat(subscriptionData.booking_fee) || 10; // Default to 0 if not a number
  const bookingFeeAmount = invoiceItemsTotal * ((bookingFeePercent + discountPercent) / 100);
  const estimatedPayout = invoiceItemsTotal - bookingFeeAmount;
  const payoutDate = addDays(parseISO(formattedThirtyDaysLater), 5);
  const formattedPayoutDate = format(payoutDate, 'yyyy-MM-dd');  


  return (
    <>
    <DashboardCard title={`Invoicing for offer #${offerId}`}>
      <Typography variant="h5">
        Contract data
      </Typography>
      <Typography variant="body2">Start Date: {formattedOfferStartDate}</Typography>
      <Typography variant="body2">End Date: {formattedOfferEndDate}</Typography>
        <Grid container alignItems="flex-end" spacing={1} marginTop={1}>
          <Grid item xs={6}>
            <Typography variant="body1"><strong>Contract Prices:</strong></Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="body1" align="center"><strong>Type:</strong></Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="body1" align="center"><strong>Quantity:</strong></Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="body1" align="right"><strong>Unit Price:</strong></Typography>
          </Grid>
        </Grid>
      {!isLoading && priceData && (
        priceData?.map((price, index) => (
          <Grid container alignItems="flex-end" spacing={2} key={index}>
            <Grid item xs={6}>
              <Typography variant="body2">{`${price.price_description}`}</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="body2" align="center">{`${price.price_type}`}</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="body2" align="center">{`${price.quantity}`}</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="body2" align="right">{`${parseFloat(price.price)} ${price.currency}`}</Typography>
            </Grid>
          </Grid>
        ))
      )}
      <Grid container direction="column" alignItems="flex-end" marginTop={2}>
        <Grid item>
          <Typography variant="h5" sx={{ mt: 3, mb: 2 }} align="right">
            Total Budget: {budget} SEK
          </Typography>
          <Typography variant="h6" align="right" sx={{ mb: 1 }} >
            Previously Invoiced amount: {budgetInvoiced} SEK
          </Typography>
          <Typography variant="h6" align="right">
            Available budget: {budget - budgetInvoiced} SEK
          </Typography>
        </Grid>
      </Grid>

      <Divider sx={{ my: 2 }} /> {/* Add some margin around the divider */}

      <Typography variant="h5">
        Create New Invoice
      </Typography>

      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        {invoiceData.invoiceItems.map((item, index) => (
          <Box key={index} sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id={`item_description_${index}`}
              label="Description"
              name="item_description"
              value={item.description}
              onChange={(e) => handleInputChange(e, index)}
              sx={{ mr: 1 }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id={`item_quantity_${index}`}
              label="Quantity"
              name="item_quantity"
              value={item.quantity}
              onChange={(e) => handleInputChange(e, index)}
              sx={{ mx: 1 }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id={`item_unit_price_${index}`}
              label="Unit Price"
              name="item_unit_price"
              value={item.unit_price}
              onChange={(e) => handleInputChange(e, index)}
              sx={{ ml: 1 }}
            />
            <IconButton onClick={() => handleRemoveItem(index)} color="error">
              <RemoveCircleOutlineIcon />
            </IconButton>
          </Box>
        ))}
        {budgetInvoiced < budget && (
          <IconButton 
            onClick={handleAddItem} 
            color="primary"
            disabled={isBudgetExceeded()}
          >
            <Typography variant="body1">
              Add Price Row 
            </Typography>
            <AddCircleOutlineIcon />
          </IconButton>
        )}

        <Grid container direction="column" alignItems="flex-end" marginTop={2}>
          <Grid item>
            <Typography variant="h5" sx={{ mt: 3, mb: 1 }} align="right">
              Invoice Total: {invoiceItemsTotal.toFixed(0)} SEK
            </Typography>
            <Typography variant="h6" align="right" sx={{ mt: 1 }}>
              Payout: {estimatedPayout.toFixed(0)} SEK
            </Typography>
            <Typography variant="body2" align="right" sx={{ mb: 1 }}>
              (after {bookingFeePercent + discountPercent}% deduction)
            </Typography>
            <Typography variant="body2" align="right">
              Invoice Date: {formattedToday}
            </Typography>
            <Typography variant="body2" align="right">
              Client Due Date: {formattedThirtyDaysLater}
            </Typography>
            <Typography variant="body2" align="right">
              Estimated Payout Date: {formattedPayoutDate}
            </Typography>

          </Grid>
        </Grid>

        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
        {isLoading ? (
            <CircularProgress />
          ) : (
            <Button 
              type="submit" 
              variant="contained" 
              sx={{ mt: 3, mb: 2 }}
              disabled={budgetInvoiced >= budget || isBudgetExceeded()}
            >
              Send Invoice To Client
            </Button>
          )}
        </Box>
      </Box>
    </DashboardCard>
    <DashboardCard title="Sent Invoices for this Contract" sx={{ mt: 3 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <List>
            {invoices.map((invoice, index) => (
              <ListItem key={index} sx={{ pl: 0 }}>
                <ListItemText
                  primary={
                    <>
                      <Typography variant="h6">
                        Invoice #${invoice.id}
                      </Typography>
                    </>
                  }
                  secondary={
                    <>
                    Date: {format(parseISO(invoice.invoice_date), 'yyyy-MM-dd')} - 
                    Total: {invoice.subTotal} ${invoice.currency} - 
                    Status: {invoice.status} -
                    <br />
                    <a href={`${apiServerUrl}/api/downloads/invoice/${invoice.freelancerPdfUuid}`} >
                      <Button variant="text" sx={{ pl: 0 }}>
                        Freelancer Self Invoice
                      </Button>
                    </a>
                    <a href={`${apiServerUrl}/api/downloads/invoice/${invoice.clientPdfUuid}`}>
                      <Button variant="text">
                        Client Invoice
                      </Button>
                    </a>
                    </>}
                />
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
    </DashboardCard>
    <Snackbar 
      open={snackbarOpen} 
      autoHideDuration={6000} 
      onClose={handleSnackbarClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
      <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
        {snackbarMessage}
      </Alert>
    </Snackbar>
    <SuccessModal
        open={showSuccessModal}
        handleClose={() => setShowSuccessModal(false)}
        heading="Your invoice is sent!"
        text="Your invoice has been sent to the client. A self invoice has been sent to you. Thank you for all your great work!"
      />
    </>
  );
};

export default InvoiceForm;