import React, { useState, useEffect } from 'react';
import { Grid, Box } from '@mui/material';
import PageContainer from 'src/components/container/PageContainer';
import { useUserContext } from 'src/contexts/Contexts';


// components
import PremiumPlusOnboarding from '../profile/components/PremiumPlusOnboarding';
import Benefits from '../profile/components/Benefits';

import { Welcome } from './components/Welcome';
import { SlackInvitation } from './components/SlackInvitation';
import { FreelancerGPT } from './components/FreelancerGPT';

const Dashboard = () => {
  const { userData, setUserData, isFreelancer, freelancerData, token, userSubscriptionData, isClient } = useUserContext();

    useEffect(() => {
    const fetchData = async () => {
      if (!userSubscriptionData) {
        return "Loading...";
      }
    };

    fetchData();
  }, [userSubscriptionData]);

  if (!userSubscriptionData) {
    return "Loading...";
  }


  return (
    <PageContainer title="Dashboard" description="this is Dashboard">
      <Box>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={8}>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={12}>
                <Welcome />
              </Grid>
              {userSubscriptionData.subscription_id === 2 && (
                <Grid item xs={12} lg={12}>
                  <Benefits />
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Grid container spacing={3}>
            {isFreelancer && (
              <Grid item xs={12}>
                  <SlackInvitation />
              </Grid>
            )}
              <Grid item xs={12}>
                  <FreelancerGPT />
              </Grid>
              <Grid item xs={12}>
              {userSubscriptionData.subscription_id === 3 && (
                <PremiumPlusOnboarding />
              )}
              </Grid>
              {/* <Grid item xs={12}>
                <Kickstarter />
              </Grid> */}
            </Grid>
          </Grid>
          {/* <Grid item xs={12} lg={4}>
            <Kickstarter />
          </Grid> */}
          {/* <Grid item xs={12} lg={8}>
            <ProductPerformance />
          </Grid> */}
        </Grid>
      </Box>
    </PageContainer>
  );
};

export default Dashboard;
