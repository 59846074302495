import React, { useState } from 'react';
import { Grid, Box, Typography } from '@mui/material';
import PageContainer from 'src/components/container/PageContainer';
import usercheck from 'src/services/usercheck';
import CreateNeed from './components/CreateNeed'; 


const PublishNeed = () => {

  return (
    <PageContainer title="Publish Needs" description="Manage client needs">
      <Box>
        <Typography variant='h2' align='center' marginBottom={2}>
            Create a project and publish your needs.
        </Typography>
        <Typography variant='subtitle1' align='center' marginBottom={2}>
          When you publish a project here we will automatically match with freelancers qualified so they can make you an 
          offer. You can see all offers and select the one which best fulfills your need.
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12}>
             <CreateNeed />
          </Grid>
        </Grid>
      </Box>
    </PageContainer>
  );
};

export default usercheck(PublishNeed);
