import React, { useState, useEffect } from 'react';
import { Typography, Grid, TextField, Button, Tooltip, IconButton, Box, CircularProgress } from '@mui/material';
import { createFreelancer, getFreelancerServiceById, updateFreelancer, getUserByAuth0Id, getFreelancerByUserId } from "../../../services/message.service";
import { format, parseISO } from 'date-fns';
import DashboardCard from '../../../components/shared/DashboardCard';
import { useUserContext } from '../../../contexts/Contexts';
import ProfileImage from './ProfileImage';
import Collapse from '@mui/material/Collapse';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';




const About = ({ cvData })  => {
  const { userData, freelancerData, setFreelancerData, token } = useUserContext();
  const [submitButtonState, setSubmitButtonState] = useState({ text: 'Save your profile', color: 'primary' });
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (cvData) {
      setFreelancerData((prevFreelancerData) => ({
        ...prevFreelancerData,
        title_or_role: cvData.title_or_role || prevFreelancerData.title_or_role,
        about_text: cvData.about_text || prevFreelancerData.about_text,
        about_drivers: cvData.about_drivers || prevFreelancerData.about_drivers,
        about_unique: cvData.about_unique || prevFreelancerData.about_unique,
      }));
      handleSubmit();
    }
  }, [cvData]);


  const handleInputChange = (event) => {
    const { name, value } = event.target;
      setFreelancerData({
        ...freelancerData,
        [name]: value,
      });
  };

  const renderTooltip = (texts) => (
    <Tooltip
      title={
        <React.Fragment>
          {texts.map((text, index) => (
            <Typography key={index} variant="body2">{text}</Typography>
          ))}
        </React.Fragment>
      }
    >
      <IconButton>
        <HelpOutlineIcon />
      </IconButton>
    </Tooltip>
  );


  const handleSubmit = async () => {
    setIsSaving(true);
    try {
      if (freelancerData.id) {
        const response = await updateFreelancer(freelancerData.id, freelancerData, token);
      } else {
        const newFreelancerData = {
          ...freelancerData,
          user_id: userData.id,
        };
        const response = await createFreelancer(newFreelancerData, token);
      }
      setSubmitButtonState({ text: 'Profile Saved ✔', color: 'secondary' });
      
      setTimeout(() => {
        setSubmitButtonState({ text: 'Save your profile', color: 'primary' });
      }, 2000); // Reset after 2 seconds  
    } catch (error) {
      setSubmitButtonState({ text: 'Error: '+ error, color: 'error' });
      console.error('Error updating data:', error);
    }
    setIsSaving(false);
  };

  return (
    <>
      <Typography variant="h4">
        About Me
      </Typography>
      <Typography variant="body2" marginTop={1}>
        Mandatory to add what you work with and an About Me text to be published.
      </Typography>
      <Grid container spacing={3} marginTop={0}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="What I Work With *"
            name="title_or_role"
            value={cvData && cvData.title_or_role ? cvData.title_or_role : (freelancerData && freelancerData.title_or_role)}
            onChange={handleInputChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={4}>
          {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="I am available from"
              inputFormat="yyyy-MM-dd"
              value={freelancerData && freelancerData.availability_date ? parseISO(freelancerData.availability_date) : null}
              onChange={(date) => handleInputChange({
                target: {
                  name: 'availability_date',
                  value: date ? format(date, 'yyyy-MM-dd') : null,
                },
              })}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          </LocalizationProvider> */}

          <TextField
            fullWidth
            label="I am available from"
            type="date"
            name="availability_date"
            value={freelancerData && freelancerData.availability_date ? format(parseISO(freelancerData.availability_date), 'yyyy-MM-dd') : ""}
            onChange={handleInputChange}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              pattern: "\\d{4}-\\d{2}-\\d{2}"
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="flex-end" alignItems="center" marginBottom={0}>
            {renderTooltip([
              `Be Concise, Be Clear: Aim for a brief, compelling summary. Think of it as your professional elevator pitch`
            ])}
          </Box>
          <TextField
            fullWidth
            label="About Me *"
            name="about_text"
            value={cvData && cvData.about_text ? cvData.about_text : (freelancerData && freelancerData.about_text)}
            onChange={handleInputChange}
            multiline
            rows={8}
            placeholder="Be Concise, Be Clear: Aim for a brief, compelling summary. Think of it as your professional elevator pitch."
            InputLabelProps={{
              shrink: true,
            }}
          />

        </Grid>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="flex-end" alignItems="center" marginBottom={0}>
            {renderTooltip([
              "Describe what motivates you, your core values, and how these influence your professional choices.",
              "- What gets you out of bed every morning excited to work?",
              "- Is there a mission or cause you are deeply passionate about in your professional life?",
              "- How does your work contribute to this personal mission?"
            ])}
          </Box>
          <TextField
            fullWidth
            label="What Drives Me"
            name="about_drivers"
            value={cvData && cvData.about_drivers ? cvData.about_drivers : (freelancerData && freelancerData.about_drivers)}
            onChange={handleInputChange}
            multiline
            rows={5}
            placeholder="Describe what motivates you, your core values, and how these influence your professional choices.  |
            What gets you out of bed every morning excited to work? | 
            Is there a mission or cause you are deeply passionate about in your professional life? | 
            How does your work contribute to this personal mission?"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="flex-end" alignItems="center" marginBottom={0}>
            {renderTooltip([
              "Explain your distinctive work strategy and how it leads to effective and innovative results.",
              "- Can you share a situation where your unique approach to a problem made a difference?",
              "- How does your personality influence the way you work or interact with clients?",
              "- What are some principles or practices you follow that set you apart from others in your field?",
            ])}
          </Box>
          <TextField
            fullWidth
            label="My Unique Approach"
            name="about_unique"
            value={cvData && cvData.about_unique ? cvData.about_unique : (freelancerData && freelancerData.about_unique)}
            onChange={handleInputChange}
            multiline
            rows={5}
            placeholder="Explain your distinctive work strategy and how it leads to effective and innovative results.
              Can you share a situation where your unique approach to a problem made a difference?
              How does your personality influence the way you work or interact with clients?
              What are some principles or practices you follow that set you apart from others in your field?"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <Button 
            variant="contained" 
            color={submitButtonState.color} 
            onClick={handleSubmit}
            disabled={isSaving} // Disable the button when saving
          >
            {isSaving ? (
              <CircularProgress 
                size={24} 
                color="inherit" 
                style={{ marginRight: 8 }} // Add some spacing between the spinner and the text
              />
            ) : null}
            {submitButtonState.text}
          </Button>
        </Grid>    
      </Grid>
    </>
  );
};

export default About;
