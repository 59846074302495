import { Grid, Box, Typography, Button, CardMedia } from '@mui/material';
import React, { useState, useEffect } from 'react';
import PageContainer from 'src/components/container/PageContainer';
import { ProfileInfo } from './components/ProfileInfo';
import usercheck from 'src/services/usercheck';
import { useUserContext } from 'src/contexts/Contexts';


const Profile = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const { userData, freelancerData, setIsFreelancer, token } = useUserContext();

  const goToNextStep = () => {
    // DO NOTHING
    // if (currentStep === 2) {
    //   setCurrentStep(1);
    // }
    setCurrentStep(1);
  };

  return (
    <PageContainer title="Dashboard" description="this is Dashboard">
        <Box>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant='h2' align='center' marginBottom={2}>
                 Your Information
              </Typography>
              <Typography variant='subtitle1' align='center' marginBottom={2}>
                Update your information
              </Typography>
              <ProfileInfo onNext={goToNextStep} />
              </Grid>
          </Grid>
        </Box>
      </PageContainer>
  );
};
export default usercheck(Profile);