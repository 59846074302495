import { Grid, Box, Typography } from '@mui/material';
import React from 'react';
import PageContainer from 'src/components/container/PageContainer';
import CreateOffer from './components/CreateOffer';
import usercheck from 'src/services/usercheck';
import ClientManager from './components/ClientManager';
import OffersStandalone from '../freelancer/components/OffersStandalone';



const CreateOfferStandAlone = () => {

  return (
    <PageContainer title="Dashboard" description="this is Dashboard">
        <Box>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant='h2' align='center' marginBottom={2}>
                 Standalone Offers
              </Typography>
              <Typography variant='subtitle1' align='center' marginBottom={2}>
                When you have your own customer and wants to send them an offer outside of the Because Mondays plaza
                you can create a stand alone offer here. 
              </Typography>
              <ClientManager />
              </Grid>
          </Grid>
        </Box>
      </PageContainer>
  );
};
export default usercheck(CreateOfferStandAlone);