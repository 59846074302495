import React, { useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from 'react-router-dom';
import { getUserByAuth0Id } from './message.service';

const usercheck = (WrappedComponent) => {
  return (props) => {
    const { user, getAccessTokenSilently } = useAuth0();
    const navigate = useNavigate();

    useEffect(() => {
      const checkUserExistence = async () => {
        if (user) {
          const token = await getAccessTokenSilently();
          const { data, error } = await getUserByAuth0Id(user.sub, token);
          if (error) {
            navigate('/plaza/profile-setup');
          }
        }
      };

      checkUserExistence();
    }, [user, getAccessTokenSilently, navigate]);

    return <WrappedComponent {...props} />;
  };
};

export default usercheck;
