import React, { useState } from 'react';
import { Grid, Box, Typography } from '@mui/material';
import PageContainer from 'src/components/container/PageContainer';
import usercheck from 'src/services/usercheck';

import Bookings from '../freelancer/components/Bookings';

const FreelancerBookings = () => {
  return (
    <PageContainer title="Freelancer Booking requests" description="Manage your bookings">
      <Box>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Bookings fetchBy="client" />
          </Grid>
        </Grid>
      </Box>
    </PageContainer>
  );
};

export default usercheck(FreelancerBookings);
