// ClientNeedDetails.js
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Modal, Box, Grid, Button, Typography } from '@mui/material';
import DashboardCard from '../../../components/shared/DashboardCard';
import { useUserContext } from '../../../contexts/Contexts';
import { getClientNeedById, createOffer } from '../../../services/message.service'; // Replace with your actual service
import MakeOffer from './MakeOffer'; // Import the MakeOffer component
import BookingOfferAndDeals from 'src/views/freelancer/components/BookingOfferAndDeals';
import ShareIcon from '@mui/icons-material/Share';


const ClientNeedDetails = () => {
  const { needId } = useParams();
  const { freelancerData, token } = useUserContext();
  const [needDetails, setNeedDetails] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchedDetails = await getClientNeedById(needId);
        setNeedDetails(fetchedDetails.data);
      } catch (error) {
        console.error('Error fetching need details:', error);
      }
    };

    fetchData();
  }, [needId, token]);

  const handleShare = () => {
    const link = `${window.location.origin}/project/${needId}`;
    window.open(link, '_blank');
  };  

  const renderModalBody = (
    <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 'auto', bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
      <BookingOfferAndDeals offerType={"need"} id={needId} />
    </Box>
  );


  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <DashboardCard title="Project Details">
          {needDetails ? (
            <>
              <Box display="flex" justifyContent="flex-end" marginBottom={1}>
                <Button
                  startIcon={<ShareIcon />}
                  onClick={handleShare}
                >
                  Share link
                </Button>
              </Box>
              <Typography variant="h5">{needDetails.title}</Typography>
                <div dangerouslySetInnerHTML={{ __html: needDetails.description.replace(/\n/g, '<br />') }} />
            </>
          ) : (
            <p>Loading...</p>
          )}
        </DashboardCard>
      </Grid>
    </Grid>
  );
};

export default ClientNeedDetails;
