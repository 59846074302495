import { Typography } from '@mui/material';
import React from 'react';
import InvoiceForm from './components/InvoiceForm';
import CompanyDetails from './components/CompanyDetails';
import { useParams } from 'react-router-dom';
import Grid from '@mui/material/Grid'; // Import Grid from Material UI


const CreateAndUpdateInvoice = () => {
  const { offerId, invoiceId } = useParams();


  return (
    <>
      <Typography variant="h1" align='center'>Create an Invoice</Typography>
      <Typography variant="subtitle1" align='center' marginBottom={3}>Type in the details and send the invoice. 
        When you click "Send Invoice" it will be sent to the customer and a self invoice will 
        be sent to you.</Typography>
      <Grid container spacing={2}> {/* Use Grid container to create a flex container */}
        <Grid item xs={12} md={8}> {/* First column */}
          <InvoiceForm offerId={offerId} invoiceId={invoiceId}/>
        </Grid>
        <Grid item xs={12} md={4}> {/* First column */}
          <CompanyDetails offerId={offerId}/>
        </Grid>
      </Grid>          
    </>
  );
};
export default CreateAndUpdateInvoice;