import React, { useState, useEffect } from 'react';
import { Button, TextField, CircularProgress, Snackbar, Alert, IconButton, Grid, Typography, Box } from '@mui/material';
import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline';
import { createOffer, getUserSubscriptions } from '../../../services/message.service';
import { useUserContext } from 'src/contexts/Contexts';
import DashboardCard from '../../../components/shared/DashboardCard';

const CreateOffer = ({clientData}) => {
  const {freelancerData, userData, token } = useUserContext();
  const [offerData, setOfferData] = useState({ message: '', prices: [] });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({ type: '', text: '' });
  const [isSubmitted, setIsSubmitted] = useState(false); 
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [subscriptionData, setSubscriptionData] = useState({});


  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await getUserSubscriptions(userData.id, token);
        if (response && response.data) {
          setSubscriptionData(response.data);
        }
      } catch (error) {
        setError('Failed to load data');
        console.error('Error fetching offer data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [userData]);


    // Function to calculate total budget
  const calculateTotalBudget = () => {
    return offerData.prices.reduce((total, priceItem) => {
      const price = parseFloat(priceItem.price) || 0;
      const quantity = parseFloat(priceItem.quantity) || 0;
      return total + (price * quantity);
    }, 0);
  };

  const validateDates = () => {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Reset time to start of the day
    const start = new Date(startDate);
    const end = new Date(endDate);
  
    if (!startDate || !endDate) {
      setMessage({ type: 'error', text: 'Both start date and end date must be set.' });
      return false;
    } else if (start < today) {
      setMessage({ type: 'error', text: 'Start date must be today or later.' });
      return false;
    } else if (end <= start) {
      setMessage({ type: 'error', text: 'End date must be after the start date.' });
      return false;
    }
    return true;
  };
  
  

  const handleChange = (e, index) => {
    if (index !== undefined) {
      // Handle price changes
      const newPrices = [...offerData.prices];
      newPrices[index][e.target.name] = e.target.value;
      setOfferData({ ...offerData, prices: newPrices });
    } else {
      // Handle message changes
      setOfferData({ ...offerData, [e.target.name]: e.target.value });
    }
  };

  const addPrice = () => {
    setOfferData({
      ...offerData,
      prices: [...offerData.prices, { price: '', currency: 'SEK', price_type: 'FIXED', price_description: '', quantity: '' }]
    });
  };

  const removePrice = (index) => {
    const newPrices = offerData.prices.filter((_, i) => i !== index);
    setOfferData({ ...offerData, prices: newPrices });
  };

  const handleSubmit = async () => {
    if (!offerData.message || offerData.prices.length === 0) {
      setMessage({ type: 'error', text: 'Please fill all fields and add at least one price.' });
      return;
    }

    if (!validateDates()) {
      return;
    }
  

    const offerSubmissionData = {
      ...offerData,
      client_id: clientData.id,
      freelancer_id: freelancerData.id,
      start_date: startDate,
      end_date: endDate,
    }; 

    setLoading(true);
    const response = await createOffer(offerSubmissionData, token);

    if (response.error) {
      setMessage({ type: 'error', text: 'Error creating the offer: ' + response.error });
    } else {
      setMessage({ type: 'success', text: 'Offer created successfully!' });
      setOfferData({ message: '', prices: [] });
      setIsSubmitted(true);
    }
    setLoading(false);
  };

  const handleCloseMessage = () => {
    setMessage({ type: '', text: '' });
  };

  if (isSubmitted) {
    // Display success screen
    return (
      <Grid container>
        <Grid item>
          <DashboardCard>
            <CheckCircleOutline style={{ fontSize: 60, color: 'green' }} />
            <Typography variant="h5">Offer Submitted Successfully!</Typography>
            <Typography variant="body1">An Email is sent to your client with a link to this offer. 
            The client will need to approve the offer before contracts are sent.</Typography>
          </DashboardCard>
        </Grid>
      </Grid>
    );
  }


  return (
    <Grid item xs={12}>
        <DashboardCard title="Offer">
          <Typography variant="body1">
            Describe your offer and clarify what is included. Add rows of prices for fixed and/or hourly price rows.
          </Typography>
          <Grid container spacing={3} marginTop={0}>
            <Grid item xs={12} md={12}>
              <TextField
                label="Description"
                variant="outlined"
                name="message"
                multiline
                rows={15}
                value={offerData.message}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Start Date"
                type="date"
                value={offerData.start_date}
                onChange={(e) => setStartDate(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  pattern: "\\d{4}-\\d{2}-\\d{2}"
                }}          
                sx={{ mt: 2 }} 
              />
              <TextField
                label="End Date"
                type="date"
                value={offerData.end_date}
                onChange={(e) => setEndDate(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  pattern: "\\d{4}-\\d{2}-\\d{2}"
                }}          
                sx={{ mt: 2, ml: 2 }} 
              />
              {offerData.prices.map((price, index) => (
                <Box key={index} sx={{ mt: 3, display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                  <TextField
                    label="Description"
                    variant="outlined"
                    name="price_description"
                    value={price.price_description}
                    onChange={(e) => handleChange(e, index)}
                    sx={{ mr: 2, width: '25%' }} 
                  />
                  <TextField
                    select
                    label="Type"
                    value={price.price_type}
                    name="price_type"
                    onChange={(e) => handleChange(e, index)}
                    SelectProps={{
                      native: true,
                    }}
                    variant="outlined"
                    sx={{ mr: 2, width: '12%' }} 
                  >
                    <option value="FIXED">Fixed</option>
                    <option value="HOURLY">Hourly</option>
                  </TextField>
                  <TextField
                    label="Quantity"
                    variant="outlined"
                    name="quantity"
                    type="number"
                    value={price.quantity}
                    onChange={(e) => handleChange(e, index)}
                    sx={{ mr: 2, width: '13%' }} 
                  />
                  <TextField
                    label="Price"
                    variant="outlined"
                    name="price"
                    type="number"
                    value={price.price}
                    onChange={(e) => handleChange(e, index)}
                    sx={{ mr: 2, width: '15%' }} 
                  />
                  <TextField
                    select
                    label="Currency"
                    value={price.currency}
                    name="currency"
                    onChange={(e) => handleChange(e, index)}
                    SelectProps={{
                      native: true,
                    }}
                    variant="outlined"
                    sx={{ mr: 2, width: '12%' }} 
                  > 
                    <option value="SEK">SEK</option>
                  </TextField>
                  <IconButton onClick={() => removePrice(index)}>
                    <DeleteIcon />
                  </IconButton>
                </Box>
              ))}
              <br />
              <IconButton onClick={addPrice}>
                <AddCircleOutline /> 
                <Typography variant="body2">Add Price Row</Typography>
              </IconButton>
              <Box sx={{ mt: 3 }}>
                <Typography variant="h4" sx={{ mt: 3, mb: 3 }}>
                  Total Budget: {calculateTotalBudget().toFixed(0)} SEK
                </Typography>
                <Typography variant="h5" sx={{ mt: 3}}>
                  Fees: 
                </Typography>
                <Typography variant="body1">
                  Standard Fee: {parseFloat(subscriptionData.booking_fee).toFixed(0)}%
                </Typography>
                <Typography variant="body1">
                  Discount: -5%
                </Typography>
                <Typography variant="body1">
                  <strong>Your Fee: {`${parseFloat(subscriptionData.booking_fee).toFixed(0) - 5}%`}</strong>
                </Typography>
              </Box>
              <br/><br/>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                disabled={loading}
              >
                {loading ? <CircularProgress size={24} /> : 'Publish and send Offer to customer'}
              </Button>
              <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={message.text !== ''}
                autoHideDuration={6000}
                onClose={handleCloseMessage}
              >
                <Alert onClose={handleCloseMessage} severity={message.type}>
                  {message.text}
                </Alert>
              </Snackbar>
            </Grid>
          </Grid>
        </DashboardCard>
      </Grid>
  );
};

export default CreateOffer;
