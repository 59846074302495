import React, { useCallback, useState, useEffect } from 'react';
import { Backdrop, Button, Grid, Typography, Paper, CircularProgress } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { analyseCV } from 'src/services/cv.service';
import { useUserContext } from 'src/contexts/Contexts';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DashboardCard from 'src/components/shared/DashboardCard';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';


const messages = [
  "🔍 Analyzing your CV... Let's see what you've got!",
  "🧠 Extracting the skills... You're quite the talent!",
  "📊 Building your profile... You're going to be famous!",
  "✨ Almost done... Just sprinkling some magic dust!",
  "🚀 Preparing to launch your career... To infinity and beyond!",
  "🎨 Adding some flair to your credentials... Picasso would be proud!",
  "🕵️‍♂️ Uncovering hidden talents... Ah, there's the secret agent experience!",
  "🎉 Almost there... Get ready for the grand reveal!"
];
const PDFUploadComponent = ({ onCvAnalyzed })  => {
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [currentMessage, setCurrentMessage] = useState('');
  const [messageIndex, setMessageIndex] = useState(0);
  const [uploadSuccess, setUploadSuccess] = useState(false); // New state for tracking upload success
  const [uploadError, setUploadError] = useState(false); // New state for tracking upload success

  const { freelancerData } = useUserContext();

  useEffect(() => {
    if (loading) {
      const intervalId = setInterval(() => {
        setMessageIndex((prevIndex) => (prevIndex + 1) % messages.length);
      }, 15000);

      return () => clearInterval(intervalId);
    }
  }, [loading]);

  useEffect(() => {
    if (loading) {
      let charIndex = 0;
      const typingInterval = setInterval(() => {
        setCurrentMessage(messages[messageIndex].slice(0, charIndex));
        charIndex++;
        if (charIndex > messages[messageIndex].length) {
          clearInterval(typingInterval);
        }
      }, 120);

      return () => clearInterval(typingInterval);
    }
  }, [messageIndex, loading]);

  const onDrop = useCallback(async (acceptedFiles) => {
    setUploadError(false);
    setUploadSuccess(false);
    setLoading(true);
    setCurrentMessage(messages[0]);
    setMessageIndex(0);
    const file = acceptedFiles[0];
    try {
      const result = await analyseCV(file);
      if (result) {
        const data = JSON.parse(result.analysisResult);
        onCvAnalyzed(data); // Pass the response up to the parent component
        setUploadSuccess(true); // Set success state to true
        setUploadError(false);

      } else {
        setUploadError(true);
        setUploadSuccess(false);
        onCvAnalyzed(null);
        setLoading(false);
        console.log("Getting null CV Data:", result);
      }
    } catch (error) {
      console.error("Error getting CV Data:", error);
      onCvAnalyzed(null); // Pass the error up if needed
      setUploadSuccess(false); // Set success state to false
      setUploadError(true);
      setLoading(false);
    }
    setLoading(false);
  }, [onCvAnalyzed]);


  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: 'application/pdf',
    maxFiles: 1
  });

  return (
    <DashboardCard title="Upload CV">
      <Grid container spacing={2} alignItems="center" justifyContent="center">
        {uploadSuccess ? (
          <Grid item xs={12}>
            <Alert severity="success" icon={<EmojiEventsIcon fontSize="inherit" />}>
              <AlertTitle variant="h5">Upload Successful! 🎉</AlertTitle>
              Your CV has been analyzed and fields have been pre-filled. Please review the updates and save to publish your information.
            </Alert>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <Typography variant="body1">
              Upload your CV in PDF format and we will type in all information for you.
              You can easily download a PDF from your LinkedIn Profile and upload it here. <i>NOTE! 
                This will replace any profile information you have been adding previously.</i>
            </Typography>
          </Grid>
        )}
        {uploadError && (
          <Grid item xs={12}>
           <Alert severity="error">
             <AlertTitle>An Error occured</AlertTitle>
             Please try again
           </Alert>
         </Grid>
        )}

        {loading ? (
          <Grid item xs={12} style={{ textAlign: 'center', height: '300px'  }}>
            <Typography variant="h5" marginTop={3}>Analysing in progress, do not close this window...</Typography>
            <Typography variant="body1" marginBottom={3}>This can take a few minutes</Typography>
            <CircularProgress />
            <Typography variant="body1" marginTop={3}>{currentMessage}</Typography>
          </Grid>
        ) : (
        <Grid item xs={12}>
          <div {...getRootProps()} style={{ border: '2px dashed gray', padding: '50px', textAlign: 'center' }}>
            <input {...getInputProps()} />
            {
              isDragActive ?
                <Typography variant="body2"><CloudUploadIcon /> <br />Drop the files here ...</Typography> :
                <Typography variant="body2"><CloudUploadIcon /> <br />Drag 'n' drop a PDF file here, or click to select the file</Typography>
            }
          </div>
        </Grid>
        )}
        {/* <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            disabled={!response}
            onClick={() => setResponse(null)}
          >
            Clear
          </Button>
        </Grid> */}
        {response && (
          <Grid item xs={12}>
            <Paper variant="outlined" style={{ padding: '16px' }}>
              <Typography variant="subtitle1">Response:</Typography>
              <pre>{JSON.stringify(response, null, 2)}</pre>
            </Paper>
          </Grid>
        )}
      </Grid>
    </DashboardCard>
  );
};

export default PDFUploadComponent;