import React, { useEffect, useState } from 'react';
import { Typography, Box } from '@mui/material';
import { CircularProgress } from '@mui/material';

import PageContainer from 'src/components/container/PageContainer';

import { useParams } from 'react-router-dom';
import { updateFreelancerAvailabilityByEmail } from '../../services/message.service';

const UpdateFreelancerAvailability = () => {
  
  const { email } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    const updateAvailability = async () => {
      try {
        const { data, error } = await updateFreelancerAvailabilityByEmail(email);

        if (error) {
          setError(error.message);
        } else {
          setSuccess(true);
        }
      } catch (err) {
        setError('An error occurred while updating availability.');
      } finally {
        setLoading(false);
      }
    };

    if (email) {
      updateAvailability();
    } else {
      setError('No email provided.');
      setLoading(false);
    }
  }, [email]);

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <PageContainer title="Freelancer Update Availability" description="Manage your bookings">
      <Box textAlign="center" mt={4}>
        {error ? (
          <Typography variant="h6" color="error">
            {error}
          </Typography>
        ) : success ? (
          <Typography variant="h1" color="white">
            Your availability updated successfully!
          </Typography>
        ) : null}
      </Box>
    </ PageContainer>
  );
};

export default UpdateFreelancerAvailability;