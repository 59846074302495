import React from 'react';
import { Grid, Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import PageContainer from 'src/components/container/PageContainer';
import ClientNeedDetails from './components/ClientNeedDetails';
import LoginCreateAccount from 'src/layouts/utilities/shared/LoginCreateAccount';
import BookingOfferAndDeals from 'src/views/freelancer/components/BookingOfferAndDeals';
import { useAuth0 } from '@auth0/auth0-react';
import { useUserContext } from '../../contexts/Contexts';
import BookingChat from '../freelancer/components/BookingChat';






const NeedLandingPage = () => {
  const { needId } = useParams();
  const { freelancerData, isFreelancer } = useUserContext();

  return (
    <PageContainer title="Customer Project" description="Customer Project">
      <Box sx={{ width: '100%', bgcolor: "white" }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={7}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <ClientNeedDetails />
              </Grid>
              {isFreelancer && (
              <Grid item xs={12}>
                <BookingOfferAndDeals offerType={'need'} id={needId}/>
              </Grid>
              )}
            </Grid>
          </Grid>
          {isFreelancer ? (
          <Grid item xs={12} md={5}>
            <BookingChat chatType="need" client_need_id={needId} freelancer_id={freelancerData.id}/>
          </Grid>
          ) : (
            <Grid item xs={12} md={5}>
              < LoginCreateAccount />
            </Grid>
          )}
        </Grid>
      </Box>
    </PageContainer> 
  );
};

export default NeedLandingPage;