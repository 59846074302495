import React from 'react';
import {
  Box,
  Container,
  Typography,
  Button,
  Grid,
  CardMedia
} from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';


const AboutLandingPage = () => {
  const { loginWithRedirect } = useAuth0();
  const handleLogin = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: "/plaza/dashboard",
      },
    });
  };
  const handleSignUp = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: "/plaza/profile-setup",
      },
      authorizationParams: {
        screen_hint: "signup",
      },
    });
  };


  return (
    <>
    <div style={{
      backgroundColor: 'white',
      }} >
      <Box
        sx={{
          backgroundSize: 'cover',
          py: 6,
          px: 3,
          textAlign: 'center',
        }}
      >

        <Container maxWidth="lg">
          <Typography variant="h2" component="h1" gutterBottom>
            Welcome to Because Mondays.
          </Typography>
          <Typography variant="h4" sx={{ mb: 4 }}>
            Redefining Freelancing and Client Connections
          </Typography>
          <Button variant="contained" color="primary" sx={{ mt: 2}} href={"/freelancer-subscription"}>
            Learn more about Freelancing
          </Button><br/>
          <Button variant="text" color="primary" sx={{ mt: 2 }} href={"/client-subscription"}>
             Learn more about finding freelancers
          </Button>
        </Container>

      </Box>  

      <Container maxWidth="lg" sx={{ py: 4 }}>
        <Typography variant="h3" component="h2" gutterBottom marginTop={3}>
          Why Choose Because Mondays?
        </Typography>
        <Typography variant="body1" sx={{ mt: 2 }}>
          Because Mondays is where freelancers and clients find each other. Whether you're building your freelance career or seeking the perfect talent for your project, we are here to help your project succeed.
        </Typography>
        <Typography variant="body1" sx={{ mt: 2 }}>
          Life's too short to dread Mondays. At Because Mondays, we redefine what it means to work. Whether you're a freelancer seeking control over your career or a client looking for top-notch talent, we're here to make every connection count, and every project a journey towards personal and professional fulfillment.
        </Typography>
      </Container>

      <Container maxWidth="lg" sx={{ py: 4 }}>
        <Typography variant="h3" component="h2" gutterBottom marginTop={3}>
          Empowering Your Work Style
        </Typography>
        <Typography variant="body1" sx={{ mt: 2 }}>
          Do you crave the freedom to work at your own pace? Dream of starting your own company? Or prefer the stability of freelancing within an established business? At Because Mondays, you have the freedom to choose. Our platform adapts to your needs, offering the safety and community of traditional employment combined with the autonomy and flexibility of freelancing.
        </Typography>
      </Container>

      <Box
        sx={{
          bgcolor: '#FFEB00',
          py: 6,
          px: 3,
          textAlign: 'center',
        }}
      >
        <Container maxWidth="lg">
          <Typography variant="h3" component="h2" gutterBottom>
            Meet Our Founders: Anna-Karin Westerberg & Martin Hollstrand
          </Typography>

          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12} sm={6}>
              <CardMedia
                component="img"
                image="https://res.cloudinary.com/dldyopnvj/image/upload/v1700121542/Anna-karin-G_lfumlh.jpg"
                alt="Anna-Karin Westerberg"
                sx={{ width: '100%', height: 'auto', borderRadius: '8px' }}
              />
              <Typography variant="body1" sx={{ mt: 2, textAlign: 'left' }}>
                Anna-Karin, a visionary leader, advocates for authenticity and self-realization.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <CardMedia
                component="img"
                image="https://res.cloudinary.com/dldyopnvj/image/upload/v1700121539/Martin-G_ltagx6.jpg"
                alt="Martin Hollstrand"
                sx={{ width: '100%', height: 'auto', borderRadius: '8px' }}
              />
              <Typography variant="body1" sx={{ mt: 2, textAlign: 'left' }}>
                Martin champions diverse solutions and personal journeys in the entrepreneurial landscape.
              </Typography>
            </Grid>
          </Grid>

          <Typography variant="body1" sx={{ mt: 4 }}>
            Together, they have created a space where embracing your true self is not just encouraged but celebrated.
          </Typography>
        </Container>

      </Box>

      <Container maxWidth="lg" sx={{ py: 4 }}>
        <Typography variant="h3" component="h2" gutterBottom marginTop={3}>
          Our Core Values
        </Typography>
        <Typography variant="body1" sx={{ mt: 2 }}>
          <strong>Walk Your Path:</strong> We champion individuality, encouraging you to explore and express your unique talents.
        </Typography>
        <Typography variant="body1" sx={{ mt: 2 }}>
          <strong>Transparency:</strong> Trust is our cornerstone. We advocate open communication and honest actions.
        </Typography>
        <Typography variant="body1" sx={{ mt: 2 }}>
          <strong>Pay it Forward:</strong> We believe in the ripple effect of good deeds, fostering a community of mutual support and innovation.
        </Typography>
      </Container>

      <Container maxWidth="lg" sx={{ py: 4 }}>
        <Typography variant="h3" component="h2" gutterBottom marginTop={3}>
          For Freelancers and Clients Alike
        </Typography>
        <Typography variant="body1" sx={{ mt: 2 }}>
          Because Mondays is a movement, not just a platform. Freelancers can choose to operate their own company, receive assistance in starting one, or enjoy the flexibility of freelancing within our supportive ecosystem. For clients, this means access to a diverse pool of passionate professionals committed to delivering exceptional results.
        </Typography>
      </Container>

      <Container maxWidth="lg" sx={{ py: 4 }}>
        <Typography variant="h3" component="h2" gutterBottom marginTop={3}>
          A People-First Philosophy
        </Typography>
        <Typography variant="body1" sx={{ mt: 2 }}>
          Our ethos is built on a people-first approach. Freelancers in our community can enjoy the independence of running their own business or the support of freelancing under the Because Mondays umbrella. This freedom leads to unparalleled creativity and results that exceed expectations.
        </Typography>
      </Container>

      <Box
        sx={{
          bgcolor: '#FFEB00',
          py: 6,
          px: 3,
          textAlign: 'center',
        }}
      >
        <Container maxWidth="lg">
          <Typography variant="h3" component="h2" gutterBottom>
            Join Us and Redefine Your Work Life
          </Typography>
          <Typography variant="body1" sx={{ mt: 2 }}>
            Whether you aim to carve your own path, help others find theirs, or seek a balance between independence and support, Because Mondays is where your journey begins. Here, work is more than a job; it's a lifestyle choice, a platform for growth, and a community of like-minded individuals.
          </Typography>
          <Button variant="contained" color="primary" size="large" sx={{ mt: 2 }} onClick={handleSignUp}>
            Start Your Journey
          </Button>
        </Container>
      </Box>
      </div>    
    </>
  );
};

export default AboutLandingPage;
