import React, { useState } from 'react';
import { Grid, Box, Button } from '@mui/material';
import PageContainer from 'src/components/container/PageContainer';
import usercheck from 'src/services/usercheck';
import OffersView from './components/OffersView';

const Offers = () => {
  return (
    <PageContainer title="Client Leads" description="Manage client leads">
      <Box>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <OffersView />
          </Grid>
        </Grid>
      </Box>
    </PageContainer>
  );
};

export default usercheck(Offers);
