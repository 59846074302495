import React, { useState } from 'react';
import { Box, AppBar, Toolbar, styled, Stack, IconButton, Badge, Button, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import Logo from '../shared/logo/Logo';
import { useAuth0 } from '@auth0/auth0-react';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';




// components
import Profile from './Profile';
import Search from 'src/components/search/Search';

const Header = (props) => {

  const [scrolled, setScrolled] = React.useState(false);
  const { loginWithRedirect } = useAuth0();
  const { isAuthenticated } = useAuth0();
  const { logout } = useAuth0();
  const { user } = useAuth0();
  const [mobileOpen, setMobileOpen] = useState(false);
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const isMdDown = useMediaQuery(theme.breakpoints.down('sm'));




  React.useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll);
    
    return () => {
      // Clean up the listener when the component unmounts
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  const handleLogout = () => {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };
  const handleLogin = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: "/plaza/dashboard",
      },
    });
  };
  const handleSignUp = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: "/plaza/profile-setup",
      },
      authorizationParams: {
        screen_hint: "signup",
      },
    });
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };


  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }} margin={5}>
      <Typography component={Link} to={`/freelancers/`} variant="h6" sx={{ color: 'primary', textDecoration: 'none' }}>
        All Freelancers
      </Typography>
      <Typography> &nbsp; </Typography>
      <Typography component={Link} to={`/client-subscription/`} variant="h6" sx={{ color: 'primary', textDecoration: 'none' }}>
        For Clients
      </Typography>
      <Typography> &nbsp; </Typography>
      <Typography component={Link} to={`/freelancer-subscription/`} variant="h6" sx={{ color: 'primary', textDecoration: 'none' }}>
        For Freelancers
      </Typography>
      {!isAuthenticated && (
        <>
          <Typography> &nbsp; </Typography>
          <Typography onClick={handleLogin} variant="h6" 
            sx={{ color: scrolled ? 'primary' : 'inherit',
              textDecoration: 'none', cursor: 'pointer'  }}>
                Sign In 
          </Typography>
          <Typography> &nbsp; </Typography>
          {/* <Button 
            sx={{ whiteSpace: 'nowrap', color: scrolled ? 'primary' : 'white' }} 
            variant="none" 
            target="_blank" 
            onClick={handleLogin}
          >
            Log In
          </Button> */}
          <Button sx={{ whiteSpace: 'nowrap' }} variant="contained" color="secondary"  target="_blank" onClick={handleSignUp}>
            Create Account
          </Button>
          
        </>
      )}
    </Box>
  );


  // const AppBarStyled = styled(AppBar)(({ theme }) => ({
  //   boxShadow: 'none',
  //   background: theme.palette.background.paper,
  //   justifyContent: 'center',
  //   backdropFilter: 'blur(4px)',
  //   marginTop: 5,
  //   [theme.breakpoints.up('lg')]: {
  //     minHeight: '70px',
  //   },
  // }));

  const AppBarStyled = styled(AppBar)(({ theme }) => ({
    boxShadow: 'none',
    background: scrolled ? theme.palette.background.paper : 'transparent',
    justifyContent: 'center',
    backdropFilter: 'none',
    marginTop: 5,
    [theme.breakpoints.up('lg')]: {
      minHeight: '70px',
    },
  }));

  const ToolbarStyled = styled(Toolbar)(({ theme }) => ({
    width: '100%',
    color: theme.palette.text.secondary,
    position: 'relative', 
  }));

  return (
    <AppBarStyled position="sticky" color="default">
      <ToolbarStyled >
        <Box sx={{
              display: {
                xs: "flex",
                sm: "flex",
                md: "flex",
              },
            }}>
          <Logo initColor="white"/>
        </Box>
        <Box
          sx={{
            position: { xs: 'absolute' }, // static position for xs
            left: { xs: '50%' }, // left aligned at xs
            transform: { xs: 'translateX(-50%)' }, // no transformation at xs
            ml: { xs: '0px' }, // ensure no margin at xs
            display: { xs: 'none', sm: 'block' } // hide on xs and show on sm and above
          }}
        >
          {/* <Search /> */}
        </Box>
        {!isMdUp && (
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ ml: 'auto', display: { sm: 'none' }, color: 'white' }}
          >
            <MenuIcon />
          </IconButton>
        )}

        {!isMdDown && (
        <Stack spacing={1} direction="row" alignItems="center" width="250" ml="auto">
          <Typography  component={Link} to={`/client-subscription`} variant="h6" 
            sx={{ color: scrolled ? 'primary' : 'white',
              textDecoration: 'none'  }}>
                For Clients
          </Typography>
          <Typography> &nbsp; </Typography>
          <Typography  component={Link} to={`/freelancer-subscription`} variant="h6" 
            sx={{ color: scrolled ? 'primary' : 'white',
              textDecoration: 'none'  }}>
                For Freelancers
          </Typography>
          <Typography color="white" variant="h5">&nbsp;|&nbsp; </Typography>
          {!isAuthenticated && (
            <>
              <Typography onClick={handleLogin} variant="h6" 
                sx={{ color: scrolled ? 'primary' : 'white',
                  textDecoration: 'none', cursor: 'pointer'  }}>
                    Sign In 
              </Typography>
              <Typography> &nbsp; </Typography>
              <Button sx={{ whiteSpace: 'nowrap' }} variant="contained" color="secondary"  target="_blank" onClick={handleSignUp}>
                Create Account
              </Button>
            </>
          )}
          
          {/* <Button variant="none" color="primary"  target="_blank" href="/auth/login">
            Log in
          </Button> */}
          {/* <Profile /> */}
        </Stack>
        )}
        {isAuthenticated && (
          <>
            <Profile />
          </>
        )}
        <Drawer
          variant="temporary"
          anchor="right" // Optional: Align drawer to the right side
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'block', md: 'none' },
            '& .MuiDrawer-paper': { 
              boxSizing: 'border-box', 
              width: 320, // Adjust width as needed
              paddingTop: '16px', // Add padding at the top
              // Add more padding if needed, for example:
              paddingRight: '16px',
              paddingBottom: '16px',
              paddingLeft: '16px',
            },
          }}
        >
          <IconButton
            onClick={handleDrawerToggle}
            sx={{
              position: 'absolute', // Position the close button absolutely
              right: 8, // Right align
              top: 8, // Top align
            }}
          >
            <CloseIcon /> 
          </IconButton>
          <Box sx={{ color: 'black', width: '100%', height: '100%' }}> 
            {/* All other drawer contents should go here, retaining their color as black */}
            {drawer}
          </Box>
        </Drawer>

      </ToolbarStyled>
    </AppBarStyled>
  );
};

Header.propTypes = {
  sx: PropTypes.object,
};

export default Header;
